import { Box } from '@mui/material';
import React, { useState } from 'react';
import viewMore from 'src/assets/newTemplateIcons/viewMore.svg';
const TemplateButtons = ({ data, bgColor, renderButtonIcon }) => {
  const [showAll, setShowAll] = useState(false); // State to track if all buttons should be shown

  const handleSeeAllClick = () => {
    console.log('lion', showAll);
    setShowAll(true); // Set showAll to true when "See all options" is clicked
  };
  const handleViewLessClick = () => {
    setShowAll(false); // Show only the first 3 buttons
  };
  return (
    <>
      <Box>
      {data?.template_buttons?.map((button, buttonIndex) => {
  // If there are 3 or fewer buttons, display them all without "View More" or "View Less"
  if (data.template_buttons.length <= 3) {
    return (
      <Box
        key={buttonIndex}
        padding="10px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        cursor="pointer"
        backgroundColor={bgColor ? bgColor : '#FFFFFF'}
        color="#168edf"
        fontSize="14px"
        fontWeight="500"
        borderTop="1px solid #cbcbcb8c"
        sx={{
          borderBottomLeftRadius: '10px',
          borderBottomRightRadius: '10px'
        }}
      >
        {renderButtonIcon(button.type)} {/* Render button icon */}
        {button.text}
      </Box>
    );
  }

  // If more than 3 buttons exist, show only the first 2 and the "View More" button
  if (!showAll && buttonIndex < 2) {
    return (
      <Box
        key={buttonIndex}
        padding="10px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        cursor="pointer"
        backgroundColor={bgColor ? bgColor : '#FFFFFF'}
        color="#168edf"
        fontSize="14px"
        fontWeight="500"
        borderTop="1px solid #cbcbcb8c"
        sx={{
          borderBottomLeftRadius: '10px',
          borderBottomRightRadius: '10px'
        }}
      >
        {renderButtonIcon(button.type)}
        {button.text}
      </Box>
    );
  }

  // Show the "View More" button if not all buttons are shown
  if (!showAll && buttonIndex === 2) {
    return (
      <Box
        key="view-more"
        padding="10px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        cursor="pointer"
        backgroundColor={bgColor ? bgColor : '#FFFFFF'}
        color="#168edf"
        fontSize="14px"
        fontWeight="500"
        borderTop="1px solid #cbcbcb8c"
        sx={{
          borderBottomLeftRadius: '10px',
          borderBottomRightRadius: '10px'
        }}
        onClick={() => setShowAll(true)} // Set showAll to true when clicked
      >
        <img
            src={viewMore}
            alt={`view more Icon`}
            style={{ marginRight: '5px', height: '15px' }}
          />
          <span> See all options</span>
      </Box>
    );
  }

  // Show all buttons when "View More" is clicked
  if (showAll) {
    return (
      <Box
        key={buttonIndex}
        padding="10px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        cursor="pointer"
        backgroundColor={bgColor ? bgColor : '#FFFFFF'}
        color="#168edf"
        fontSize="14px"
        fontWeight="500"
        borderTop="1px solid #cbcbcb8c"
        sx={{
          borderBottomLeftRadius: '10px',
          borderBottomRightRadius: '10px'
        }}
      >
        {renderButtonIcon(button.type)}
        {button.text}
      </Box>
    );
  }
  return null;
})}


      </Box>

  
      {data?.template_buttons?.length > 3 && showAll && (
        <Box
          padding="10px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          cursor="pointer"
          backgroundColor={bgColor ? bgColor : '#FFFFFF'}
          color="#168edf"
          fontSize="14px"
          fontWeight="500"
          borderTop="1px solid #cbcbcb8c"
          sx={{
            borderBottomLeftRadius: '10px',
            borderBottomRightRadius: '10px'
          }}
          onClick={handleViewLessClick} // Trigger to show fewer options
        >
          <img
            src={viewMore}
            alt={`view more Icon`}
            style={{ marginRight: '5px', height: '15px' }}
          />
          <span>View less</span>
        </Box>
      )}
    </>
  );
};

export default TemplateButtons;
