// import React, { useMemo, useCallback } from 'react';
// import { Box, Typography } from '@mui/material';

// const VideoComponent = React.memo(({ data, selectedMedia, apiKey, handleZoomVideo, mediaAvailablity, selectMedia, opacityStyle, overlayStyle }) => {
//   const isVideo = useMemo(() => data?.template_header?.type === 'video', [data?.template_header?.type]);

//   const videoSrc = useMemo(() => {
//     return `${apiKey}/${(selectedMedia ? selectedMedia.media_url : data?.template_header?.value)?.replace(/^\.\//, '')}`;
//   }, [apiKey, selectedMedia, data?.template_header?.value]);

//   const onVideoClick = useCallback(() => {
//     if (data?.template_header?.value) {
//       handleZoomVideo(data?.template_header?.value.replace(/^\.\//, ''));
//     }
//   }, [handleZoomVideo, data?.template_header?.value]);

//   if (!isVideo) return null; // Avoid unnecessary renders when not a video

//   return (
//     <Box onClick={onVideoClick}>
//       <video controls style={{ maxWidth: '100%', height: 'auto', ...opacityStyle }}>
//         <track kind="captions" src="" label="English" />
//         <source src={videoSrc} type="video/mp4" />
//         Your browser does not support the video tag.
//       </video>
//       {mediaAvailablity && (
//         <StyledOverlayBox sx={overlayStyle} onClick={selectMedia}>
//           <Typography sx={{ color: '#FFFFFF', fontWeight: 500, fontSize: '15px' }}>
//             Choose media
//           </Typography>
//         </StyledOverlayBox>
//       )}
//     </Box>
//   );
// });

// export default VideoComponent;
import React, { useMemo, useCallback } from 'react';
import { Box, Typography } from '@mui/material';

const VideoComponent = React.memo(({ 
  data, 
  selectedMedia, 
  apiKey, 
  handleZoomVideo, 
  mediaAvailablity, 
  selectMedia, 
  opacityStyle, 
  overlayStyle 
}) => {
  console.log('Rendering VideoComponent'); // Debugging to check rerenders

  const isVideo = useMemo(() => data?.template_header?.type === 'video', [data?.template_header?.type]);

  const videoSrc = useMemo(() => {
    return `${apiKey}/${(selectedMedia ? selectedMedia.media_url : data?.template_header?.value)?.replace(/^\.\//, '')}`;
  }, [apiKey, selectedMedia, data?.template_header?.value]);

  const onVideoClick = useCallback(() => {
    if (data?.template_header?.value) {
      handleZoomVideo(data?.template_header?.value.replace(/^\.\//, ''));
    }
  }, [handleZoomVideo, data?.template_header?.value]);

  if (!isVideo) return null; // Avoid unnecessary renders when not a video

  return (
    <Box onClick={onVideoClick}>
    {/* <Box > */}
      <video controls style={{ maxWidth: '100%', height: 'auto' }}>
        <track kind="captions" src="" label="English" />
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {mediaAvailablity && (
        <StyledOverlayBox sx={overlayStyle} onClick={selectMedia}>
          <Typography sx={{ color: '#FFFFFF', fontWeight: 500, fontSize: '15px' }}>
            Choose media
          </Typography>
        </StyledOverlayBox>
      )}
    </Box>
  );
});

export default VideoComponent;
