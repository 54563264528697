import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isRegister: 0,
  unReadOrder: 0,
  wappBusiness: { wallet_balance: 0 }, //  To store the walet balance
  // unreadChatCounts: 0, //  To show te unread message counts in sidebar mesage icon
  unreadChatCounts: [],
  unreadCustomerCount: {},
  unreadArchivedChatCounts: [],
  connectedUsers: {},
  campaignDetails: [],
  unreadNotification: 0,
  businessProfileData: null,
  usedMessageQuota: 0,
  incomingMessage: {},
  campaignDetailsUpdates: {},
  freeTierConversations: 0,
  subscriptionsCounts: 0, // For store the subscription count
  newCustomer: {},
  messageStatusUpdate: {},
  updateMediaMessageIdToChatscreen: {},
  automationAttributes: [],
  tags: [],
  managepage: 'Team',
  publishedForms:[]
  // notifications: [],
  // newMessages: []
  // permissions: {},
};

const wappSlice = createSlice({
  name: 'wappbiz',
  initialState,
  reducers: {
    addAction: (state, action) => {
      const { payload, type } = action.payload;
      switch (type) {
        case 'business':
          state.wappBusiness = payload;
          state.isRegister = 1;
          break;
        case 'updateWalletAmount':
          state.wappBusiness.wallet_balance =
            state.wappBusiness.wallet_balance + payload;
          break;
        case 'setUnreadChatCount': // Notification count for archived customers
          state.unreadChatCounts = payload;
          break;
        case 'unReadOrder': // Notification count for archived customers
          if (payload === 'number') {
            state.unReadOrder += 1; // Increment the count by 1
          } else {
            state.unReadOrder = payload; // Initialize to 1 if it's not already a number
          }
          break;

        case 'addUnreadChatCount':
          const updatedSet = new Set([...state.unreadChatCounts, payload]);
          state.unreadChatCounts = Array.from(updatedSet); // Convert Set to Array for storage
          break;

        case 'unreadCustomerCounts':
          const { phone_number_id, customer_id } = payload;

          if (!state.unreadCustomerCount[phone_number_id]) {
            state.unreadCustomerCount[phone_number_id] = {};
          }

          // Add customer_id under the correct phone_number_id
          state.unreadCustomerCount[phone_number_id] = {
            ...state.unreadCustomerCount[phone_number_id], // Keep existing customers
            [customer_id]: true // Add new customer_id
          };
          break;

        case 'setUnreadCustomerCount':
          state.unreadCustomerCount = payload || {};
          break;

        case 'setUnreadArchivedChatCounts': // Notification count for archived customers
          state.unreadArchivedChatCounts = payload;
          break;
        case 'addUnreadArchivedChatCount':
          const updatedArchivedSet = new Set([
            ...state.unreadArchivedChatCounts,
            payload
          ]);
          state.unreadArchivedChatCounts = Array.from(updatedArchivedSet); // Convert Set to Array for storage
          break;
        case 'unreadNotification':
          state.unreadNotification = state.unreadNotification + payload;
          break;
        case 'connectedUsers':
          state.connectedUsers = payload;
          break;
        case 'addAutomationAttributes':
          state.automationAttributes = payload;
          break;
        case 'updateAutomationAttributes':
          state.automationAttributes.push(payload);

        case 'campaignDetails':
          state.campaignDetails = payload;
          break;
        case 'campaignDetailsUpdate':
          const index = state.campaignDetails.findIndex(
            (detail) =>
              detail.name.toLowerCase() === payload.status.toLowerCase()
          );
          if (index !== -1) {
            state.campaignDetails[index].value += 1;
          }
          state.campaignDetailsUpdates = payload;
          break;
        case 'businessProfileData':
          state.businessProfileData = payload;
          break;
        case 'usedMessageQuota':
          state.usedMessageQuota = payload;
          break;
        case 'freeTierConversations':
          state.freeTierConversations = payload;
          break;
        case 'incomingMessage':
          state.incomingMessage = payload;
          break;
        case 'clearMessage':
          state.incomingMessage = {};
          break;
        case 'subscriptionsCounts':
          state.subscriptionsCounts = payload;
          break;
        case 'newCustomer':
          state.newCustomer = payload;
          break;
        case 'updateMediaMessageIdToChatscreen':
          state.updateMediaMessageIdToChatscreen = payload;
          break;
        case 'messageStatusUpdate':
          state.messageStatusUpdate = payload;
          break;
        case 'clearNewCustomer':
          state.newCustomer = {};
          break;
        case 'fetchTags':
          state.tags = payload;
          break;
        case 'addTags':
          state.tags = payload;
          break;
        case 'managePage':
          state.managepage = payload;
          break;
        case 'addPublishedFlowForms':
          state.publishedForms = payload;
          break;
        // case 'permission':
        //   state.permissions = payload;
        //   break;

        default:
          break;
      }
    },
    removeAction: (state, action) => {
      //state.chats = state.chats.filter((chat) => chat.id !== action.payload);

      const { payload, type } = action.payload;
      switch (type) {
        case 'unreadChatCount':
          state.unreadChatCounts = state.unreadChatCounts - payload;
          break;
        case 'unreadNotification':
          state.unreadNotification = payload;
          break;
        case 'connectedUsers':
          state.connectedUsers = payload;
          break;
        case 'campaignDetails':
          state.campaignDetails = payload;
          break;
        case 'removeUnreadChatCount':
          state.unreadChatCounts = state.unreadChatCounts?.filter(
            (id) => id !== payload
          );
          break;
        case 'removeUnreadArchivedChatCount':
          state.unreadArchivedChatCounts =
            state.unreadArchivedChatCounts?.filter((id) => id !== payload);
          break;

        case 'removeCustomerChatCount':
          const { phone_number_id, customer_id } = payload;

          if (state.unreadCustomerCount[phone_number_id]) {
            delete state.unreadCustomerCount[phone_number_id][customer_id];

            // If no unread customers remain, remove the phone_number_id key
            if (
              Object.keys(state.unreadCustomerCount[phone_number_id]).length ===
              0
            ) {
              delete state.unreadCustomerCount[phone_number_id];
            }
          }
          break;

        default:
          break;
      }
    },
    resetState: () => initialState
  }
});

export const { addAction, removeAction, resetState } = wappSlice.actions;
export default wappSlice.reducer;
