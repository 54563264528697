import React, { useState } from 'react';
import EditTemplateLib from '../../NewTemplatesCarosel/EditTemplateLib';
import TemplateContentLibrary from '../TemplateContentLibrary';

const TemplateLibContainer = ({
  topic,
  industry,
  setIndustry,
  setTopic,
  hasNextPage,
  libTemplate,
  handleScroll,
  loading
}) => {
  const [getData, setGetData] = useState();
  const [showCreate, setShowCreate] = useState(false);
  const [showCustom, setShowCustom] = useState(false);
  const handleCreateTemplate = (data) => {
    console.log('libTemplate', data);

    setGetData(data);
    setShowCreate(true);
  };
  const performCancel = () => {
    setShowCreate(false);
    setShowCustom(false);

    setGetData();
  };
  const handleFullCustomisation = () => {
    console.log('Full Customisation');
    setShowCustom(true);
  };
  return (
    <>
      <TemplateContentLibrary
        insideLib={true}
        handleCreateTemplate={handleCreateTemplate}
        handleScroll={handleScroll}
        allTemplates={libTemplate}
        loading={loading}
        hasNextPage={hasNextPage}
        industry={industry}
        topic={topic}
        setTopic={setTopic}
        setIndustry={setIndustry}
        // height={"90vh"}
      />
      {showCreate && (
        <EditTemplateLib
          custom={showCustom}
          handleFullCustomisation={handleFullCustomisation}
          initialData={getData}
          show={showCreate}
          performCancel={performCancel}
        />
      )}
    </>
  );
};

export default TemplateLibContainer;
