import React, { useEffect, useState } from 'react';
import CustomDialogie from 'src/components/dialogue/CustomDialogie';
import TemplateContent from '../TemplateContent';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import TemplateApiCalls from 'src/utility/TemplateApiCalls';
import TemplateLibContainer from './TemplateLibContainer';

const TemplateLibrary = () => {
  const scrollToTop = () => {
    const container = document.getElementById("scrollable-container"); // Replace with your container's ID
    if (container) {
      container.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };
  const [loading, setLoading] = useState(false);

  const [industry, setIndustry] = useState('all');
  const [topic, setTopic] = useState('all');
  const fetchData = async ({ pageParam }) => {
    try {
      console.log('pageParams', pageParam);
      let page=""
      let industryInstance=''
      let topicInstance=''
      if (pageParam) {
        page = `&after=${pageParam}`;
      }else{
        setLoading(true)
        scrollToTop()
      }
      if(industry!=='all'){
        industryInstance=`&industry=${industry}`
      }
      if(topic!=='all'){
        topicInstance=`&topic=${topic}`

      }
     
      const response = await TemplateApiCalls.listAllTemplatesLibrary(page,`${industryInstance}${topicInstance}`);
      // const response = await TemplateApiCalls.listAllTemplatesLibrary();
      return response?.data;
    } catch (error) {
      setLoading(false)

      console.error('Error fetching data:', error);
      return [];
    }
  };


  //   const {
  //     data: allTemplates,
  //     isLoading,
  //     isError
  //   } = useQuery({
  //     queryKey: ['templateslib'],
  //     queryFn: fetchData,
  //     staleTime: 10000,
  //     cacheTime: 20000,pageParams
  //   });

  const { data, fetchNextPage,isLoading, hasNextPage, isFetchingNextPage, refetch } =
    useInfiniteQuery({
      queryKey: ['templateslib',industry,topic],
      queryFn: fetchData,
      getNextPageParam: (lastPage) => {
          if (lastPage?.pagination?.nextPageUrl) {
            console.log("lastPage?.pagination?.nextPageUrl",lastPage?.pagination?.nextPageUrl);
          return lastPage?.pagination?.nextPageUrl;
        } else {
          return null;
        }
      }
    });

  const handleScroll = (event) => {
    // console.log("event.target.scrollHeight -50",event.target.scrollHeight -50)
    // console.log("event.target.scrollTop + event.target.clientHeight",event.target.scrollTop + event.target.clientHeight)
    if (
      event.target.scrollHeight -50  <=
      event.target.scrollTop + event.target.clientHeight
    ) {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    }
  };
  const [libTemplate, setLibTemplate] = useState([]);
  useEffect(() => {
    if (data) {
      // Rebuild uniqueOrders completely when filters change or new data arrives
      const filteredOrders = [];

      data?.pages?.forEach((page) => {
        page?.templates?.forEach((order) => {
          if (
            !filteredOrders.some(
              (existingOrder) => existingOrder.template_id === order.template_id
            )
          ) {
            filteredOrders.push(order);
          }
        });
      });

      setLibTemplate(filteredOrders); // Replace old data with filtered data
    }
    if(!isLoading){
      setLoading(false);
    }
  }, [data]);

    
  return (
    // <CustomDialogie
    //   width={'90vw'}
    //   openTemplateDialog={showLib}
    //   performCancel={performCancel}
    // >
    <TemplateLibContainer
    handleScroll={handleScroll}
    libTemplate={libTemplate}
    loading={loading}
    hasNextPage={hasNextPage}
           
    industry={industry}
    topic={topic}
    setTopic={setTopic}
    setIndustry={setIndustry}
    />
   
    // </CustomDialogie>
  );
};

export default TemplateLibrary;
