export const features = [
  {
    title: 'Shopify',
    endDate: '2025-4-15'
  },
  {
    title: 'Wordpress',
    endDate: '2025-4-15'
  },
  {
    title: 'Pabbly Connect',
    endDate: '2025-4-15'
  },
  {
    title: 'Zapier',
    endDate: '2025-4-15'
  },
  {
    title: 'Razorpay',
    endDate: '2025-4-15'
  },
  {
    title: 'Custom Attributes',
    endDate: '2025-4-15'
  }
];
