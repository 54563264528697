import { Box, CircularProgress } from '@mui/material';
import React from 'react';

const Loading = ({color}) => {
  return (
    <Box sx={{ color: color?color:'grey.500' }} spacing={2} direction="row">
      {/* <CircularProgress color="secondary" /> */}
      <CircularProgress
        sx={{
          color: color?color:'#27ab69' // Custom color
        }}
        size={26}
      />
      {/* <CircularProgress color="inherit" /> */}
    </Box>
  );
};

export default Loading;
