
import React, { useEffect, useState } from 'react';
import { Box, InputAdornment, Button, TextField, Typography,Select, MenuItem, FormControl, InputLabel, FormHelperText, Grid } from '@mui/material';
import { styled } from '@mui/system';
import TemplateApiCalls from 'src/utility/TemplateApiCalls';
import { arrayOfAttributes } from 'src/utility/static/static';

const InnerButtonTextField = styled(TextField)`
  // Add your custom styles here if needed
`;

const ButtonTextMAxCount = styled(Typography)`
  font-size: 12px;
  color: #888;
`;

const ErrorText = styled(Typography)`
  font-size: 12px;
  color: red;
`;
const DropdownComponent = ({ options, action, handleChange, index }) => {
    return (
      <FormControl fullWidth style={{ marginBottom: '10px' }} error={Boolean(action.urlError)}>
        <InputLabel>Select Variable</InputLabel>
        <Select
          value={action.value || ''}
          onChange={(e) => handleChange(index, action.key, e.target.value)}
          label="Select Variable"
        >
          {options.map((option) => (
            <MenuItem key={option._id} value={option.name}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
        {action.urlError && <FormHelperText>Please select a Variable</FormHelperText>}
      </FormControl>
    );
  };
  
const AttributeMatch = ({dynamicArray,setDynamicArray,onChangeAtt}) => {
const [attribute, setAttribute] = useState([]);
const fetchAttribute = async () => {
        const response = await TemplateApiCalls.getTemplateAttributes();
        
        if (response.status === 200 && !response.error) {
            const publishedFlows = response.data
           
          const newAtt=[...arrayOfAttributes,...publishedFlows];
          setAttribute(newAtt);
        } else {
          // Handle the error scenario
          console.error("Failed to fetch flows:", response.message);
        }
    };
    
    useEffect(()=>{
        fetchAttribute()
    },[])
    
    const handleChange2 = (index, field, value) => {
        // Handle your state update logic here
        if(onChangeAtt){

          onChangeAtt(field,value)
        }
        console.log(`Changed field ${field} at index ${index} to ${value}`);

        const updatedCallToActions = [...dynamicArray];
        updatedCallToActions[index]['value'] = value;
    
    
    
        setDynamicArray(updatedCallToActions);
      };
  const handleChange = (index, field, value) => {
    const updatedCallToActions = [...dynamicArray];
    updatedCallToActions[index][field] = value;


    setDynamicArray(updatedCallToActions);
  };
  console.log("dynamicArray",dynamicArray)
  return (
    <Grid container spacing={2}>
  {dynamicArray.map((action, index) => (
    <Grid item xs={12} sm={6} key={index}>
      <Box
        bgcolor="#F6F6F6"
        padding="10px"
        borderRadius="10px"
        marginBottom="10px"
      >
        <Typography variant="h6" gutterBottom>
          Variables {index + 1}
        </Typography>

        {/* Button Text Field */}
        <InnerButtonTextField
          placeholder="Button Text"
          variant="standard"
          fullWidth
          value={action.key}
          onChange={(e) => handleChange(index, 'key', e.target.value)}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment
                position="end"
                style={{
                  position: 'absolute',
                  bottom: 10,
                  right: 5,
                }}
              >
                <ButtonTextMAxCount>
                  {action.text?.length}/25
                </ButtonTextMAxCount>
              </InputAdornment>
            ),
          }}
          error={Boolean(action.textError)}
          helperText={action.textError ? 'Maximum Limit Reached' : ''}
          style={{ marginBottom: '10px' }}
        />

        {/* Dropdown Component */}
        <DropdownComponent
          options={attribute}
          action={action}
          handleChange={handleChange2}
          index={index}
        />
      </Box>
    </Grid>
  ))}
</Grid>

  );
};

export default AttributeMatch;
