import { useContext, useEffect, useRef, useState } from 'react';

import {
  Box,
  alpha,
  Stack,
  // Divider,
  IconButton,
  Tooltip,
  styled,
  Typography,
  useMediaQuery
} from '@mui/material';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { SidebarContext } from 'src/contexts/SidebarContext';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { useDispatch } from 'react-redux';
import {
  getRegisteredBusiness,
  getUnreadChatCounts,
  getBusinessProfileData,
  getUsedMessageQuota,
  getFreeTierConversations,
  getSubscriptionExists,
  getUnreadOrderCountFun,
  unreadCustomers
} from 'src/redux/actions/wappAction';
import io from 'socket.io-client';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { addAction, removeAction } from 'src/redux/features/wappSlice';

import HeaderUserbox from './Userbox';
import Buttons from './Buttons';

import HeaderMenu from './Menu';
import Logo from '../../../assets/WappbizTextLogo.svg';
import { getDecryptedData } from 'src/utility/LocalStorageManager';
import Toast from 'src/utility/Toast';
import { backend_url } from 'src/utility/static/static';
import { useSelector } from 'react-redux';
import ApiCalls from 'src/utility/ApiCalls';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.wappBizHeader.height};
        color: ${theme.wappBizColor.black};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 6;
        background-color: ${alpha(theme.header.background, 0.95)};
        backdrop-filter: blur(1px);
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: ${theme.sidebar.width};
            width: auto;
        }
        margin: {
          xs: '0vh 0vw 5vh 0vw',
          lg: '2vh 1vw 5vh 2vw'
        };
        borderRadius: '10px';
        box-shadow: 0px 0px 10px 0px #0000000D;
`
);

function Header({ selectedPage }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const location = useLocation();
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [profileData, setProfileData] = useState(null);

  const { managepage } = useSelector((state) => state.wapp);

  const pathNameRef = useRef(location.pathname.split('/')[1]); // Use ref to store the current path
  const socketRef = useRef(null); // This will hold the socket instance

  useEffect(() => {
    pathNameRef.current = location.pathname.split('/')[1]; // Update path on location change
  }, [location]);

  useEffect(() => {
    getSubscriptionExists(dispatch);
    getRegisteredBusiness(dispatch); // Dispatch the action when the component mounts
    getUnreadChatCounts(dispatch);
    getUnreadOrderCountFun(dispatch);
    getBusinessProfileData(dispatch);
    getUsedMessageQuota(dispatch);
    getFreeTierConversations(dispatch);
    unreadCustomers(dispatch);
  }, [dispatch]);

  let phoneNumberId;

  const fetchProfile = async () => {
    try {
      const response = await ApiCalls.getRegisteredBusiness();
      const data = response?.data?.find((number) => number.status === 'active');

      if (data) {
        setProfileData(data);
        phoneNumberId = data?.phone_number_id.toString();
      }
    } catch (error) {
      console.error('Error fetching profile data:', error);
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  // Update pathname based on location changes

  useEffect(() => {
    const isLoggedIn = () => {
      // const userInfo = sessionStorage.getItem('userInfo');
      const userInfo = getDecryptedData('userInfo');
      return Boolean(userInfo);
    };

    const getUserInfo = async () => {
      // Only connect if user info is present and not on the login page
      if (isLoggedIn()) {
        if (!socketRef.current) {
          // const userInfo = sessionStorage.getItem('userInfo');
          const parsedUserInfo = getDecryptedData('userInfo');
          // const parsedUserInfo = userInfo ? JSON.parse(userInfo) : null;

          // const socket = io('https://api.wapp.biz/wapp', {
          const socket = io(`${backend_url}/wapp`, {
            query: {
              userId: parsedUserInfo.id // Assuming userId is present in the userInfo object
            }
          });

          socket.on('connect', () => {
            //

            socket.emit('getConnectedUsers', { userId: parsedUserInfo.id });
          });

          //  To disconnect
          socket.on('disconnect', () => {});
          //  To get all connected Users
          socket.on('connectedUsers', (data) => {
            dispatch(addAction({ payload: data, type: 'connectedUsers' }));
          });

          socket.on('orderCreate', (data) => {
            // Show a success toast notification
            if (pathNameRef.current !== 'orders') {
              Toast({
                type: 'success',
                message: 'Order Created in handler hey'
              });
              dispatch(addAction({ payload: 'number', type: 'unReadOrder' }));
            }

            // Trigger refetch of orders
          });

          //Update Business Details
          socket.on('business', (data) => {
            dispatch(addAction({ payload: data, type: 'business' }));
          });

          //Update Business Details
          socket.on('newCustomer', (data) => {
            if (pathNameRef.current === 'chats') {
              dispatch(addAction({ payload: data, type: 'newCustomer' }));
            }
          });

          //Update Business Details
          socket.on('updateMediaMessageIdToChatscreen', (data) => {
            if (pathNameRef.current === 'chats') {
              dispatch(
                addAction({
                  payload: data,
                  type: 'updateMediaMessageIdToChatscreen'
                })
              );
            }
          });

          //messageStatusUpdate
          socket.on('messageStatusUpdate', (data) => {
            if (pathNameRef.current === 'chats') {
              dispatch(
                addAction({ payload: data, type: 'messageStatusUpdate' })
              );
            }
          });

          //otp window open while register
          socket.on('otpWindowAction', (data) => {
            if (pathNameRef.current === 'register') {
              if (data === true) {
                dispatch(
                  addAction({ type: 'otpWindow', payload: true }) // ✅ Updates Redux store
                );
              }
            }
          });

          //  To get new messages
          socket.on('newMessage', async (data) => {
            dispatch(
              addAction({
                payload: {
                  phone_number_id: data?.phone_number_id,
                  customer_id: data?.customer_id
                },
                type: 'unreadCustomerCounts'
              })
            );

            if (phoneNumberId) {
              if (phoneNumberId === data?.phone_number_id) {
                console.log(
                  'phoneNumberId === data?.phone_number_id : ',
                  phoneNumberId,
                  data?.phone_number_id
                );

                dispatch(addAction({ payload: data, type: 'incomingMessage' }));
              }

              // dispatch(addAction({ payload: data, type: 'incomingMessage' }));

              if (pathNameRef.current === 'chats') {
                const selectedPerson = JSON.parse(
                  sessionStorage.getItem('selectedPerson') || '{}'
                );

                if (
                  !(selectedPerson && selectedPerson._id === data.customer_id)
                ) {
                  // Dispatch an action to increment the unread chat count in Redux only if the chat is not open
                  // dispatch(
                  //   addAction({
                  //     payload: data?.customer_id,
                  //     type: 'addUnreadChatCount'
                  //   })
                  // );

                  // Check if the phone_number_id matches before dispatching the action
                  if (phoneNumberId === data?.phone_number_id) {
                    console.log(
                      'phoneNumberId === data?.phone_number_id 1: ',
                      phoneNumberId,
                      data?.phone_number_id
                    );
                    dispatch(
                      addAction({
                        payload: data?.customer_id,
                        type: 'addUnreadChatCount'
                      })
                    );
                  }
                }
              } else {
                // dispatch(
                //   addAction({
                //     payload: data?.customer_id,
                //     type: 'addUnreadChatCount'
                //   })
                // );

                if (phoneNumberId === data?.phone_number_id) {
                  dispatch(
                    addAction({
                      payload: data?.customer_id,
                      type: 'addUnreadChatCount'
                    })
                  );
                }
              }
            }
          });

          //  To get campaign updates
          socket.on('campaignDetailsUpdates', async (data) => {
            //

            const selectedCampaignJSON =
              sessionStorage.getItem('selectedCampaign');
            const selectedCampaign = selectedCampaignJSON
              ? JSON.parse(selectedCampaignJSON)
              : null;

            if (selectedCampaign && selectedCampaign === data.campaignId) {
              //
              dispatch(
                addAction({
                  payload: data,
                  type: 'campaignDetailsUpdate'
                })
              );
            } else {
            }
          });

          socket.on('disconnect', () =>
            console.log('Disconnected from the socket server')
          );

          socketRef.current = socket;
        }
      } else {
        // Disconnect the socket if the user is not logged in
        if (socketRef.current) {
          socketRef.current.disconnect();
          // console.log(
          //   'Socket disconnected because the user is not authenticated.'
          // );
          socketRef.current = null; // Clear the socket reference
        }
      }

      // Clean up function
      // return () => {
      //   // Disconnect from the socket server when the component unmounts
      //   socket.disconnect();
      // };
    };
    getUserInfo(); // Call the async function immediately
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        //
        socketRef.current = null;
      }
    };
  }, [location.pathname]);

  return (
    <HeaderWrapper
      display="flex"
      alignItems="center"
      sx={{
        margin: {
          xs: '0vh 0vw 5vh 0vw',
          lg: '1.2vh .8vw 5vh 1.4vw'
        },
        borderRadius: '10px',
        boxShadow: ' 0px 0px 10px 0px #0000000D;'
      }}
    >
      <Stack
        direction="row"
        // divider={<Divider orientation="vertical" flexItem />}
        alignItems="center"
        spacing={2}
      >
        {!isMobile && (
          <>
            <img
              src={Logo}
              alt="Augment Logo"
              style={{ height: '35px', alignItems: 'center' }}
            />
            <Typography
              sx={{
                marginLeft: '10px !important',
                fontSize: { xs: '20px', md: '20px', lg: '23px' },
                fontWeight: { xs: 500, md: 500 },
                color: '#111111'
              }}
            >
              |
            </Typography>
          </>
        )}
        <Typography
          sx={{
            marginLeft: '10px !important',
            fontSize: { xs: '15px', md: '18px', lg: '20px' },
            fontWeight: { xs: 500, md: 500 },
            color: '#111111'
          }}
        >
          {managepage && location.pathname === '/manage'
            ? managepage
            : selectedPage.replace(/([A-Z])/g, ' $1').trim()}
        </Typography>
        <HeaderMenu />
      </Stack>

      <Box display="flex" alignItems="center">
        <Buttons />
        <HeaderUserbox />
        <Box
          component="span"
          sx={{
            ml: 1,
            display: { lg: 'none', xs: 'inline-block' }
          }}
        >
          <Tooltip arrow title="Toggle Menu">
            <IconButton color="primary" onClick={toggleSidebar}>
              {!sidebarToggle ? (
                <MenuTwoToneIcon fontSize="small" />
              ) : (
                <CloseTwoToneIcon fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
