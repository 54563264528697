import { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  List,
  ListItemButton,
  ListItemText,
  Popover,
  Typography,
  styled
} from '@mui/material';

import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import profileAvatar from 'src/assets/CustomerPageMedias/cutomerTopbarDropdown/profileUser.svg';
import changePassword from 'src/assets/CustomerPageMedias/cutomerTopbarDropdown/changePassword.svg';
import signOutAvatar from 'src/assets/CustomerPageMedias/cutomerTopbarDropdown/signOut.svg';
import notificationIcon from 'src/assets/CustomerPageMedias/cutomerTopbarDropdown/notification.svg';
import quickGuide from 'src/assets/CustomerPageMedias/cutomerTopbarDropdown/quickGuide.svg';
import support from 'src/assets/CustomerPageMedias/cutomerTopbarDropdown/support.svg';
import calendar from 'src/assets/CustomerPageMedias/cutomerTopbarDropdown/calendar.svg';
import planIcon from 'src/assets/ManagePageIcons/plansIcon.svg';
import { useAuth } from 'src/Authentication/AuthContext';
import { useDispatch } from 'react-redux';
import { addAction, resetState } from 'src/redux/features/wappSlice';
import { getDecryptedData } from 'src/utility/LocalStorageManager';
import { useTheme } from '@emotion/react';
import addIcon from 'src/assets/svgIcons/addIconWhite.svg';
import { useQueryClient } from '@tanstack/react-query';
import ApiCalls from 'src/utility/ApiCalls';
import BusinessApiCalls from 'src/utility/BusinessApiCalls';
import { motion } from 'framer-motion';
import Toast from 'src/utility/Toast';
import AddBusinessIcon from 'src/assets/svgIcons/addBusiness.svg';

const MotionDiv = motion(Typography);

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(1)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)`
  font-weight: 500;
  color: #111111;
  display: block;
  font-size: 15px;
`;

const UserBoxDescription = styled(Typography)`
  font-weight: 400;
  color: #a3a3a3;
  display: block;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 5px;
`;

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#F6F6F6'
  }
}));

const apiKey = process.env.REACT_APP_API_URL;

function HeaderUserbox() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const queryClient = useQueryClient();

  // Clear the cache (all queries and mutations)
  const { logout, profile } = useAuth();
  const profileRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);

  const getUserInfo = () => {
    return getDecryptedData('userInfo');
  };

  const userInfo = getUserInfo();

  useEffect(() => {
    getUserInfo();
  }, [profile]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMyProfileClick = () => {
    navigate('/profile');
    handleClose();
    setOpenProfile(false);
  };

  const handleChangePassword = () => {
    navigate('/profile');
    handleClose();
  };

  const handleOpenNotifications = () => {
    navigate('/notifications');
    handleClose();
  };

  const handleLogout = () => {
    logout();
    dispatch(resetState());
    queryClient.clear();

    // sessionStorage.removeItem('token')
    navigate('/login');
  };

  const handleOpenDocs = () => {
    window.open('https://docs.wapp.biz/', '_blank', 'noopener,noreferrer');
    handleClose();
  };
  const handleCalendar = async () => {
    navigate('/calendar');
    handleClose();
  };

  const handleSupport = () => {
    navigate('/support');
    handleClose();
  };

  return (
    <>
      {/* <Box sx={{alignContent:'center',mr:'5px'}}>
      <img src={notification} alt="notification icon"/>
    </Box> */}
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          alignItems: 'center'
        }}
      >
        {/* <UserBoxButton
          color="secondary"
          ref={ref}
          onClick={() => setOpen(!isOpen)}
        >
          <Avatar
            variant="circular"
            src={`${apiKey}${numberInfo?.media_url}`}
            alt={numberInfo?.verified_name}
          />
          <Hidden mdDown>
            <UserBoxText>
              <UserBoxLabel>{numberInfo?.phone}</UserBoxLabel>
              <UserBoxDescription>
                <img src={AddBusinessIcon} width={'16px'} height={'16px'} />
                <Typography>{numberInfo?.verified_name}</Typography>
              </UserBoxDescription>
            </UserBoxText>
          </Hidden>
          <Hidden>
            <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
          </Hidden>

          <Popover
            anchorEl={ref.current}
            onClose={() => setOpen(false)}
            open={isOpen}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            sx={{
              marginTop: '4.4%',
              [theme.breakpoints.down('xs')]: {
                marginLeft: '40px', // Specific for extra-small devices
                marginTop: '50px'
              },
              [theme.breakpoints.between('xs', 'sm')]: {
                marginLeft: '40px', // Adjusted for small devices
                marginTop: '60px'
              },
              [theme.breakpoints.up('sm')]: {
                marginLeft: '40px', // Adjusted for medium devices
                marginTop: '60px'
              },
              [theme.breakpoints.up('md')]: {
                marginLeft: '40px', // Adjusted for medium devices
                marginTop: '60px'
              },
              [theme.breakpoints.up('lg')]: {
                marginLeft: '5px', // Adjusted for large devices
                marginTop: '65px'
              }
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <Divider sx={{ mb: 0 }} />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'start',
                gap: 0.6,
                p: 1,
                borderRadius: '12px',
                width: '250px',
                bgcolor: '#FEFEFE'
              }}
            >
              {registeredNumbers?.map((number) => (
                // <List>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 1,
                    py: 0.5,
                    px: 1,
                    borderRadius: '8px',
                    cursor: 'pointer'
                  }}
                  onClick={() => handleNumberChange(number)}
                >
                  <Avatar
                    variant="circular"
                    src={`${apiKey}${number.media_url}`}
                    alt={number.verified_name}
                  />
                  <Box>
                    <Typography sx={{ fontSize: '14px', color: '#050505' }}>
                      {number?.phone}
                    </Typography>
                    <Typography sx={{ fontSize: '12px', color: '#9F9F9F' }}>
                      {number?.verified_name}
                    </Typography>
                  </Box>
                </Box>
                // </List>
              ))}
              <MotionDiv
                whileTap={{
                  scale: 0.8,
                  transition: {
                    type: 'spring',
                    stiffness: 300,
                    damping: 10
                  }
                }}
                sx={{
                  mt: '8px',
                  mb: '4px',
                  display: 'flex',
                  justifyContent: 'center',
                  textAlign: 'center',
                  width: '100%',
                  bgcolor: '#27AB69',
                  color: '#fff',
                  p: '8px',
                  borderRadius: '8px',
                  cursor: 'pointer'
                }}
                onClick={() => navigate('/addNewNumber')}
              >
                Add New Number
                <img src={addIcon} style={{ marginLeft: '10px' }} />
              </MotionDiv>
            </Box>
          </Popover>
        </UserBoxButton> */}
        <Box
          ref={profileRef}
          onClick={() => setOpenProfile(!openProfile)}
          sx={{ cursor: 'pointer', ml: '8px' }}
        >
          <Avatar
            variant="square"
            sx={{
              borderRadius: '6px',
              bgcolor: '#27AB69',
              fontWeight: 500,
              height: '43px',
              width: '43px'
            }}
            src={`${apiKey}${userInfo.profilePhoto}`}
            alt={userInfo.name}
          />
          <Popover
            anchorEl={profileRef.current}
            onClose={() => setOpenProfile(false)}
            open={openProfile}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            sx={{
              marginTop: '4.4%',
              [theme.breakpoints.down('xs')]: {
                marginLeft: '40px',
                marginTop: '50px'
              },
              [theme.breakpoints.between('xs', 'sm')]: {
                marginLeft: '40px',
                marginTop: '60px'
              },
              [theme.breakpoints.up('sm')]: {
                marginLeft: '40px',
                marginTop: '60px'
              },
              [theme.breakpoints.up('md')]: {
                marginLeft: '40px',
                marginTop: '60px'
              },
              [theme.breakpoints.up('lg')]: {
                marginLeft: '5px',
                marginTop: '60px'
              }
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <Divider sx={{ mb: 0 }} />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                p: 1,
                width: '250px'
              }}
            >
              <Avatar
                variant="circular"
                src={`${apiKey}${userInfo?.profilePhoto}`}
                alt={userInfo?.name}
                sx={{ bgcolor: '#27AB69' }}
              />
              <Hidden mdDown>
                <UserBoxText
                  onClick={() => handleMyProfileClick()}
                  sx={{ cursor: 'pointer' }}
                >
                  <UserBoxLabel>
                    {userInfo?.name?.length > 25
                      ? userInfo?.name?.slice(0, 22) + '...'
                      : userInfo?.name}
                  </UserBoxLabel>
                  <UserBoxDescription>
                    {userInfo.email?.length > 25
                      ? userInfo.email?.slice(0, 22) + '...'
                      : userInfo.email}
                  </UserBoxDescription>
                </UserBoxText>
              </Hidden>
            </Box>
            <Divider />
            <List sx={{ p: 1 }} component="nav">
              {/* <StyledListItemButton onClick={handleMyProfileClick}>
                <img
                  src={profileAvatar}
                  style={{ height: '18px' }}
                  alt={userInfo.name}
                />
                <ListItemText sx={{ ml: 1 }} primary="My Profile" />
              </StyledListItemButton> */}
              <StyledListItemButton onClick={handleOpenNotifications}>
                <img
                  src={notificationIcon}
                  style={{ height: '18px' }}
                  alt="NotificationIcon"
                />
                <ListItemText sx={{ ml: 1 }} primary="Notifications" />
              </StyledListItemButton>
              <StyledListItemButton onClick={handleChangePassword}>
                <img
                  src={changePassword}
                  style={{ height: '18px' }}
                  alt="ChangePasswordIcon"
                />
                <ListItemText sx={{ ml: 1 }} primary="Change Password" />
              </StyledListItemButton>

              <StyledListItemButton onClick={handleOpenDocs}>
                <img
                  src={quickGuide}
                  style={{ height: '18px' }}
                  alt="QuickGuideIcon"
                />
                <ListItemText sx={{ ml: 1 }} primary="Quick Guide" />
              </StyledListItemButton>

              {(userInfo?.planName || userInfo.planName !== null) && (
                <StyledListItemButton onClick={() => navigate('/plans')}>
                  <img
                    src={planIcon}
                    style={{ height: '17.5px', marginLeft: '1px' }}
                    alt="PlanIcon"
                  />
                  <ListItemText sx={{ ml: 1 }} primary={userInfo?.planName} />
                </StyledListItemButton>
              )}

              <Hidden smUp>
                <StyledListItemButton onClick={handleCalendar}>
                  <img
                    src={calendar}
                    style={{ height: '18px' }}
                    alt="calendarIcon"
                  />
                  <ListItemText sx={{ ml: 1 }} primary="Calendar" />
                </StyledListItemButton>
                <StyledListItemButton onClick={handleSupport}>
                  <img
                    src={support}
                    style={{ height: '18px' }}
                    alt="supportIcon"
                  />
                  <ListItemText sx={{ ml: 1 }} primary="Support" />
                </StyledListItemButton>
              </Hidden>
            </List>
            <Divider />
            <List sx={{ p: 1 }} component="nav">
              <StyledListItemButton onClick={handleLogout}>
                <img
                  src={signOutAvatar}
                  style={{ height: '18px' }}
                  alt={userInfo.name}
                />
                <ListItemText
                  color="#dc2626"
                  sx={{ color: '#dc2626', fontWeight: 600, ml: 1 }}
                  primary="Sign out"
                />
              </StyledListItemButton>
            </List>
          </Popover>
        </Box>
      </Box>
    </>
  );
}

export default HeaderUserbox;
