import {
  Box,
  Dialog,
  Button,
  Card,
  CardContent,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Typography,
  Tooltip,
  useMediaQuery
} from '@mui/material';
import React, { useState } from 'react';
import pending from '../../../assets/TemplateIcons/pending.svg';

import carouselIcon from 'src/assets/TemplateIcons/carouselIcon.svg';
import { AnimatePresence, motion } from 'framer-motion';
import editIcon from 'src/assets/svgIcons/Editsvg.svg';
import eyeIcon from '../../../assets/TemplateIcons/eyeIcon.svg';

import documentButton from 'src/assets/MediaIcons/documents.svg';
import imageButton from 'src/assets/MediaIcons/images.svg';
import videoButton from 'src/assets/MediaIcons/videos.svg';
import textButton from 'src/assets/CustomerPageMedias/buildChatbotIcons/text.svg';
import Toast from 'src/utility/Toast';
import CommonCofirmation from '../Components/CommonConfirmation';
import { useNavigate } from 'react-router';
import closeIcon from '../../../assets/svgIcons/closeIcon.svg';
import TemplateBg from 'src/atom/components/TemplateBg';
import SendIcon from '../../../assets/TemplateSendIcon.svg';
import deleteIcon from 'src/assets/TemplateIcons/deleteIcons.svg';
import CreateNewCampaign from '../Components/CreateNewCampaign';
import { useSelector } from 'react-redux';
import TemplateApiCalls from 'src/utility/TemplateApiCalls';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const TemplateBox = ({handleSendTemplate,setShowTemplates,insideChat,filteredTemplates,setDeleted,deleted, insideLib ,setSelectedToIcon,selectedToIcon,handleCreateTemplate}) => {
  const navigate = useNavigate();
  const { wappBusiness } = useSelector((state) => state.wapp);

  const [openTemplateDialog, setOpenTemplateDialog] = useState(false);

  const [isDialogOpen, setDialogOpen] = useState(false);

  const [templateId, setTemplateId] = useState(null);
  const handleTemplateDialogOpen = (templateId) => {
    handleView(templateId);
    setOpenTemplateDialog(true);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  const [selectedViewTemplate, setSelectedViewTemplate] = useState('');
  const handleTemplateDialogClose = () => {
    setOpenTemplateDialog(false);
    setSelectedViewTemplate('');
  };

  const handleView = async (templateId) => {
    const response = await TemplateApiCalls.getTemplatesById(templateId);
    setSelectedViewTemplate(response?.data);
  };
  const handleEdit = (data) => {
    navigate(`/templates/editTemplate/${data}`);
  };
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    open: false,
    templateName: '',
    loading: false
  });
  const handleDelete = async (name) => {
    setDeleteConfirmation({ open: true, templateName: name, loading: false });
  };

  const handleConfirmDelete = async () => {
    try {
      setDeleteConfirmation({ ...deleteConfirmation, loading: true });
      const response = await TemplateApiCalls.deleteTemplateByName(
        deleteConfirmation.templateName
      );
      Toast({ type: 'success', message: response?.message });
      setDeleted(true);
      setDeleteConfirmation({ open: false, templateName: '', loading: false });
    } catch (error) {
      Toast({ type: 'error', message: error?.response?.message });
      console.error('Error deleting template:', error);
      setDeleteConfirmation({ ...deleteConfirmation, loading: false });
    }
  };

  const handleSendButtonClick = () => {
    setDialogOpen(true);
  };
  return (
    <>{ 
      filteredTemplates?.map((template, index) => (
    
      <Grid key={template._id} item xs={12} sm={6} md={4} lg={3}>
        <motion.div
          initial={{ opacity: 0, scale: 0.6 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.6 }}
          transition={{
            duration: 0.3,
            delay: insideLib ? (index % 12) * 0.1 : index * 0.1
          }}
        >
          <Card
            variant="outlined"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
              boxShadow: 'none',
              borderColor: 'white',
              // maxWidth: '380px',
              minWidth: '260px',
              width: '100%',
              position: 'relative',
              // opacity: template.status === 'PENDING_DELETION' ? 0.5 : 1 // Set opacity based on status
              opacity:
                template.status === 'PENDING_DELETION' ||
                template.status === 'PAUSED' ||
                template.status === 'REJECTED'
                  ? 0.5
                  : 1
            }}
          >
            {template.category && (
              <Box
                sx={{
                  position: 'absolute',
                  top: '10px',
                  left: '10px',
                  backgroundColor: '#FFFBEB',
                  color: '#E4C005',
                  padding: '3px 10px',
                  borderRadius: '5px',
                  zIndex: 1
                }}
              >
                <Typography
                  sx={{
                    fontSize: '13px',
                    fontWeight: 400
                  }}
                >
                  {template.category.toLowerCase()}
                </Typography>
              </Box>
            )}
            <CardContent sx={{ paddingTop: '50px' }}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                // marginBottom="5px"
                textAlign="center"
              >
                <Typography variant="h5" sx={{ textAlign: 'center' }}>
                  {/* Template name */}
                  {template.template_name.length > 25
                    ? `${template.template_name.slice(0, 23)}...`
                    : template.template_name}
                </Typography>

                {/* template type starts here */}

                {template.template_header && (
                  <Box
                    sx={{
                      mt: '5px',
                      backgroundColor: '#F6F6F6',
                      borderRadius: '5px',
                      padding: '3px 10px'
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#343434',
                        fontSize: '13px',
                        fontWeight: 400,
                        display: 'inline-flex',
                        alignItems: 'center'
                      }}
                    >
                      {template.template_header.type === 'image' && (
                        <img
                          src={imageButton}
                          alt="img"
                          style={{
                            width: '16px',
                            height: '16px',
                            marginRight: '5px'
                          }}
                        />
                      )}

                      {template.template_header.type === 'video' && (
                        <img
                          src={videoButton}
                          alt="video"
                          style={{
                            width: '16px',
                            height: '16px',
                            marginRight: '5px'
                          }}
                        />
                      )}

                      {template.template_header.type === 'text' && (
                        <img
                          src={textButton}
                          alt="text"
                          style={{
                            width: '16px',
                            height: '16px',
                            marginRight: '5px'
                          }}
                        />
                      )}

                      {!template.template_header && (
                        <img
                          src={textButton}
                          alt="text"
                          style={{
                            width: '16px',
                            height: '16px',
                            marginRight: '5px'
                          }}
                        />
                      )}

                      {template.template_header.type === 'document' && (
                        <img
                          src={documentButton}
                          alt="document"
                          style={{
                            width: '16px',
                            height: '16px',
                            marginRight: '5px'
                          }}
                        />
                      )}

                      {template.template_header.type
                        ? template.template_header.type
                        : 'text'}
                    </Typography>
                  </Box>
                )}
                {template?.carousel?.length > 0 && (
                  <Box
                    sx={{
                      mt: '5px',
                      backgroundColor: '#F6F6F6',
                      borderRadius: '5px',
                      padding: '3px 10px'
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#343434',
                        fontSize: '13px',
                        fontWeight: 400,
                        display: 'inline-flex',
                        alignItems: 'center'
                      }}
                    >
                      <img
                        src={carouselIcon}
                        alt="text"
                        style={{
                          width: '16px',
                          height: '16px',
                          marginRight: '5px'
                        }}
                      />
                      carousel
                    </Typography>
                  </Box>
                )}
                {!template?.carousel?.length > 0 &&
                  !template.template_header && <Box sx={{ height: '34px' }} />}

                {/* template text starts here  */}
                {template?.template_body && (
                  <Box
                    sx={{
                      mt: '10px',
                      padding: '3px 10px',
                      // maxWidth: '250px',
                      // minWidth: '300px',
                      minHeight: '65px',
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 3,
                      overflow: 'hidden'
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#848484',
                        fontSize: '13px',
                        fontWeight: 400,
                        wordWrap: 'break-word',
                        wordBreak: 'break-all'
                      }}
                    >
                      {template?.template_body}
                    </Typography>
                  </Box>
                )}

                {template?.category === 'AUTHENTICATION' && (
                  <Box
                    sx={{
                      mt: '10px',
                      padding: '3px 10px',
                      // maxWidth: '250px',
                      // minWidth: '300px',
                      minHeight: '65px',
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 3,
                      overflow: 'hidden'
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#848484',
                        fontSize: '13px',
                        fontWeight: 400,
                        wordWrap: 'break-word',
                        wordBreak: 'break-all'
                      }}
                    >
                      xxxx is your verification code.
                    </Typography>
                  </Box>
                )}
                {/* selection starts here  */}
                {!insideLib && (
              <AnimatePresence mode="wait">
              {selectedToIcon !== template._id ? (
                <motion.div
                  initial={{ opacity: 0, scale: 0.6 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.6 }}
                  transition={{
                    duration: 0.3,
                    delay: insideLib ? (index % 12) * 0.1 : index * 0.1,
                  }}
                >
                  <Box position="absolute" top={4} right={10}>
                  {template.status !== 'PENDING' && (


<>{
  !insideChat&&
                    <IconButton
                      size="small"
                      onClick={() => { setSelectedToIcon(template._id); }}
                      sx={{
                        height: '35px',
                        width: '35px',
                        backgroundColor: 'transparent',
                        '&:hover': { backgroundColor: 'transparent' },
                      }}
                    >
                      <MoreVertIcon style={{color:"#848484"}}/>
                    </IconButton>
}</>

                    )}

                  </Box>
                </motion.div>
              ) : (
                <motion.div
                  initial={{ opacity: 0, x: 100 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 100 }}
                  transition={{
                    duration: 0.3,
                    delay: insideLib ? (index % 12) * 0.1 : index * 0.1,
                  }}
                >
                  <Box position="absolute" top={4} right={10}>
                    {template.status !== 'PENDING' && (
                      <>
                        {/* {!template?.carousel?.length && ( */}
                          <IconButton
                            onClick={() => handleEdit(template._id)}
                            size="small"
                            disableRipple
                            disableFocusRipple
                            disabled={template.status === 'PENDING_DELETION'}
                            sx={{
                              height: '35px',
                              width: '35px',
                              position: 'absolute',
                              top: 2,
                              right: 34,
                              zIndex: 1,
                              backgroundColor: 'transparent',
                              '&:hover': {
                                filter:
                                  'brightness(0) saturate(100%) invert(13%) sepia(99%) saturate(7477%) hue-rotate(330deg) brightness(92%) contrast(104%)',
                                backgroundColor: 'transparent',
                              },
                            }}
                          >
                            <img src={editIcon} alt="icon" height={22} />
                          </IconButton>
                        {/* )} */}
                        <IconButton
                          onClick={() => handleDelete(template.template_name)}
                          size="small"
                          disableRipple
                          disableFocusRipple
                          disabled={template.status === 'PENDING_DELETION'}
                          sx={{
                            height: '35px',
                            width: '35px',
                            position: 'absolute',
                            top: 2,
                            right: 5,
                            zIndex: 1,
                            backgroundColor: 'transparent',
                            '&:hover': {
                              filter:
                                'brightness(0) saturate(100%) invert(13%) sepia(99%) saturate(7477%) hue-rotate(330deg) brightness(92%) contrast(104%)',
                              backgroundColor: 'transparent',
                            },
                          }}
                        >
                          <img src={deleteIcon} alt="icon" height={22} />
                        </IconButton>
                      </>
                    )}
                  </Box>
                </motion.div>
              )}
            </AnimatePresence>
            
                )}
                {insideLib ? (
                  <>
                    <Button
                      sx={{
                        fontSize: '13px',
                        fontWeight: 500,
                        maxHeight: '35px',
                        width: '100% !important',
                        backgroundColor: '#FFFFFF',
                        border: '1px solid #27AB69',
                        color: '#27AB69', // Text color
                        borderRadius: '5px',
                        '&:hover': {
                          borderColor: '1px solid #27AB69',
                          backgroundColor: '#D8FFEB',
                          color: '27AB69'
                        }
                      }}
                      endIcon={<img src={eyeIcon} alt="Eye icon" />}
                      onClick={() => {
                        handleCreateTemplate(template);
                      }}
                      variant="contained"
                      color="primary"
                    >
                      Create
                    </Button>
                  </>
                ) : (
                  <Box
                    marginTop={2}
                    display="flex"
                    flexDirection="row"
                    gap="15px"
                  >
                    {/* Add margin top for the preview button */}
                    <Button
                      sx={{
                        fontSize: '13px',
                        fontWeight: 500,
                        maxHeight: '35px',
                        width: '100% !important',
                        backgroundColor: '#FFFFFF',
                        border: '1px solid #27AB69',
                        color: '#27AB69', // Text color
                        borderRadius: '5px',
                        '&:hover': {
                          borderColor: '1px solid #27AB69',
                          backgroundColor: '#D8FFEB',
                          color: '27AB69'
                        }
                      }}
                      disabled={template.status === 'PENDING_DELETION'}
                      endIcon={<img src={eyeIcon} alt="Eye icon" />}
                      onClick={() => handleTemplateDialogOpen(template._id)}
                      variant="contained"
                      color="primary"
                    >
                      Preview
                    </Button>
                    {template.status !== 'PENDING' ? (
                      wappBusiness?.wallet_balance <= 0 ? (
                        <Tooltip
                          title="You need to add money to your wallet for further broadcast."
                          placement="top"
                          arrow
                        >
                          <span>
                            <Button
                              sx={{
                                fontSize: '13px',
                                fontWeight: 400,
                                maxHeight: '35px',
                                width: '100%',
                                backgroundColor: '#27AB69',
                                borderColor: '#27AB69',
                                color: '#ffffff',
                                '&:hover': {
                                  backgroundColor: '#1e7e34'
                                },
                                borderRadius: '5px'
                              }}
                              endIcon={<img src={SendIcon} alt="Send icon" />}
                              onClick={(event) => {
                                event.stopPropagation(); // This will stop the button action when wallet balance is 0
                              }}
                              disabled={true} // Optional: Disable the button when the wallet balance is 0
                            >
                              <span>Send</span>
                            </Button>
                          </span>
                        </Tooltip>
                      ) : template.status === 'REJECTED' ||
                        template.status === 'PAUSED' ? (
                        <Tooltip
                          title={
                            template.status === 'REJECTED'
                              ? 'Template Rejected'
                              : 'Template Paused'
                          }
                          placement="top"
                          arrow
                        >
                          <span>
                            <Button
                              sx={{
                                fontSize: '13px',
                                fontWeight: 400,
                                maxHeight: '35px',
                                width: '100%',
                                backgroundColor: '#27AB69',
                                borderColor: '#27AB69',
                                color: '#ffffff',
                                '&:hover': {
                                  backgroundColor: '#1e7e34'
                                },
                                borderRadius: '5px'
                              }}
                              endIcon={<img src={SendIcon} alt="Send icon" />}
                              disabled={true}
                            >
                              <span>Send</span>
                            </Button>
                          </span>
                        </Tooltip>
                      ) : (
                        <Button
                          sx={{
                            fontSize: '13px',
                            fontWeight: 400,
                            maxHeight: '35px',
                            width: '100%',
                            backgroundColor: '#27AB69',
                            borderColor: '#27AB69',
                            color: '#ffffff',
                            '&:hover': {
                              backgroundColor: '#1e7e34'
                            },
                            borderRadius: '5px'
                          }}
                          disabled={template.status === 'PENDING_DELETION'}
                          endIcon={<img src={SendIcon} alt="Send icon" />}
                          onClick={() => {
                            if(insideChat){
                              
                                handleSendTemplate(
                                  template?.template_id,
                                  template?._id
                                ),
                                  setShowTemplates(false);
                              
                            }else{

                              handleSendButtonClick();
                              setTemplateId(template);
                            }}
                          }
                        >
                          <span>Send</span>
                        </Button>
                      )
                    ) : (
                      <Button
                        sx={{
                          fontSize: '13px',
                          fontWeight: 400,
                          // maxWidth: '150px',
                          maxHeight: '35px',
                          width: '100%',
                          backgroundColor: '#FD673A',
                          borderColor: '#FD673A',
                          color: '#ffffff',
                          '&:hover': {
                            backgroundColor: '#FD673A'
                          },
                          borderRadius: '5px',
                          pointerEvents: 'none'
                        }}
                        endIcon={<img src={pending} alt="Pending icon" />}
                        // onClick={handleSendButtonClick}
                        onClick={() => {
                          handleSendButtonClick();
                          setTemplateId(template);
                        }}
                      >
                        Pending
                      </Button>
                    )}
                  </Box>
                )}
              </Box>
            </CardContent>
          </Card>
        </motion.div>
      </Grid>
        ))
      
      }
      <CommonCofirmation
        open={deleteConfirmation.open}
        onClose={() =>
          setDeleteConfirmation({
            open: false,
            templateName: '',
            loading: false
          })
        }
        onConfirm={handleConfirmDelete}
        loading={deleteConfirmation.loading}
        heading={`Delete Template`}
        description={`Are you sure you want to delete the template ${deleteConfirmation.templateName}?`}
        cancelText={'Cancel'}
        confirmText={'Delete'}
      />
      <CreateNewCampaign
        // id={id}
        template={templateId}
        isDialogOpen={isDialogOpen}
        onClose={handleDialogClose}
        // campaigns={campaigns}
      />
      <Dialog
        // sx={{ minWidth: '400px' }}
        maxWidth="xs"
        open={openTemplateDialog}
        // onClose={handleTemplateDialogClose}
      >
        <DialogActions sx={{ backgroundColor: '#F2F2F5', padding: 0 }}>
          <IconButton onClick={handleTemplateDialogClose}>
            <img
              src={closeIcon}
              alt="Close"
              sx={{ height: '45px', width: '45px' }}
            />
          </IconButton>
        </DialogActions>
        <DialogContent
          sx={{
            backgroundColor: '#F2F2F5',
            justifyContent: 'center',
            m: '0px'
            // p: '20px',
            // paddingLeft: '35px !important',
            // paddingRight: '35px !important'
          }}
        >
          <TemplateBg data={selectedViewTemplate} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TemplateBox;
