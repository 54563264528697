import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton
} from '@mui/material';
import React from 'react';
import closeIcon from 'src/assets/svgIcons/closeIcon.svg';

const CustomDialogie = ({
  children,
  openTemplateDialog,
  performCancel,
  width,
  style,
  background,
  title,
  titleFont
}) => {
  return (
    <Dialog
      maxWidth="lg"
      open={openTemplateDialog}

      // onClose={handleTemplateDialogClose}
    >
      <DialogActions
        sx={{
          backgroundColor: background ? background : '#F2F2F5',
          padding: 0
        }}
      >
        <DialogTitle
          sx={{
            display: 'flex',
            width:"100%",
            padding:"16px",
            alignItems: 'center',
            justifyContent: title?'space-between':"flex-end", // Adjust alignment
            fontSize:titleFont?titleFont: 18,
            fontWeight: 500,
            color: '#111111',
            paddingBottom:"0px"
          }}
        >
          {title && <span>Create Campaign</span>}
          <IconButton onClick={performCancel}>
            <img
              src={closeIcon}
              alt="Close"
              sx={{ height: '45px', width: '45px' }}
            />
          </IconButton>
        </DialogTitle>
        {/* <IconButton onClick={performCancel}>
          <img
            src={closeIcon}
            alt="Close"
            sx={{ height: '45px', width: '45px' }}
          />
        </IconButton> */}
      </DialogActions>
      <DialogContent
        sx={{
          backgroundColor: background ? background : '#F2F2F5',
          justifyContent: 'center',
          m: '0px',
          width: width ? width : '400px'
          // p: '20px',
          // paddingLeft: '35px !important',
          // paddingRight: '35px !important'
        }}
      >
        <Box sx={{ ...style }}>{children}</Box>
      </DialogContent>
    </Dialog>
  );
};

export default CustomDialogie;
