import {
  Box,
  Dialog,
  Button,
  Card,
  CardContent,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Typography,
  Tooltip,
  useMediaQuery
} from '@mui/material';
import React, { useState } from 'react';
import pending from '../../../assets/TemplateIcons/pending.svg';

import carouselIcon from 'src/assets/TemplateIcons/carouselIcon.svg';
import { AnimatePresence, motion } from 'framer-motion';
import editIcon from 'src/assets/svgIcons/Editsvg.svg';
import eyeIcon from '../../../assets/TemplateIcons/eyeIcon.svg';

import documentButton from 'src/assets/MediaIcons/documents.svg';
import imageButton from 'src/assets/MediaIcons/images.svg';
import videoButton from 'src/assets/MediaIcons/videos.svg';
import textButton from 'src/assets/CustomerPageMedias/buildChatbotIcons/text.svg';
import Toast from 'src/utility/Toast';
import CommonCofirmation from '../Components/CommonConfirmation';
import { useNavigate } from 'react-router';
import closeIcon from '../../../assets/svgIcons/closeIcon.svg';
import TemplateBg from 'src/atom/components/TemplateBg';
import SendIcon from '../../../assets/TemplateSendIcon.svg';
import deleteIcon from 'src/assets/TemplateIcons/deleteIcons.svg';
import CreateNewCampaign from '../Components/CreateNewCampaign';
import { useSelector } from 'react-redux';
import TemplateApiCalls from 'src/utility/TemplateApiCalls';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const TemplateLibraryBox = ({
  handleSendTemplate,
  setShowTemplates,
  insideChat,
  filteredTemplates,
  setDeleted,
  deleted,
  insideLib,
  setSelectedToIcon,
  selectedToIcon,
  handleCreateTemplate
}) => {
  const navigate = useNavigate();
  const { wappBusiness } = useSelector((state) => state.wapp);

  const [openTemplateDialog, setOpenTemplateDialog] = useState(false);

  const [isDialogOpen, setDialogOpen] = useState(false);

  const [templateId, setTemplateId] = useState(null);
  const handleTemplateDialogOpen = (templateId) => {
    handleView(templateId);
    setOpenTemplateDialog(true);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  const [selectedViewTemplate, setSelectedViewTemplate] = useState('');
  const handleTemplateDialogClose = () => {
    setOpenTemplateDialog(false);
    setSelectedViewTemplate('');
  };

  const handleView = async (templateId) => {
    const response = await TemplateApiCalls.getTemplatesById(templateId);
    setSelectedViewTemplate(response?.data);
  };
  const handleEdit = (data) => {
    navigate(`/templates/editTemplate/${data}`);
  };
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    open: false,
    templateName: '',
    loading: false
  });
  const handleDelete = async (name) => {
    setDeleteConfirmation({ open: true, templateName: name, loading: false });
  };

  const handleConfirmDelete = async () => {
    try {
      setDeleteConfirmation({ ...deleteConfirmation, loading: true });
      const response = await TemplateApiCalls.deleteTemplateByName(
        deleteConfirmation.templateName
      );
      Toast({ type: 'success', message: response?.message });
      setDeleted(true);
      setDeleteConfirmation({ open: false, templateName: '', loading: false });
    } catch (error) {
      Toast({ type: 'error', message: error?.response?.message });
      console.error('Error deleting template:', error);
      setDeleteConfirmation({ ...deleteConfirmation, loading: false });
    }
  };

  const handleSendButtonClick = () => {
    setDialogOpen(true);
  };
  return (
    <>
      {filteredTemplates?.map((template, index) => (
        <Grid
          key={template._id}
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
        >
          <motion.div
          style={{ height:filteredTemplates.length===1?"": "100%",cursor: "pointer"}}
            initial={{ opacity: 0, scale: 0.6 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.6 }}
            transition={{
              duration: 0.3,
              delay: insideLib ? (index % 12) * 0.1 : index * 0.1
            }}
            onClick={() => {
                handleCreateTemplate(template);
              }}
          >
            {' '}
            <TemplateBg data={template} width={'100%'} height={"100%"}/>
          </motion.div>
        </Grid>
      ))}
      <CommonCofirmation
        open={deleteConfirmation.open}
        onClose={() =>
          setDeleteConfirmation({
            open: false,
            templateName: '',
            loading: false
          })
        }
        onConfirm={handleConfirmDelete}
        loading={deleteConfirmation.loading}
        heading={`Delete Template`}
        description={`Are you sure you want to delete the template ${deleteConfirmation.templateName}?`}
        cancelText={'Cancel'}
        confirmText={'Delete'}
      />
      <CreateNewCampaign
        // id={id}
        template={templateId}
        isDialogOpen={isDialogOpen}
        onClose={handleDialogClose}
        // campaigns={campaigns}
      />
    </>
  );
};

export default TemplateLibraryBox;
