import styled from '@emotion/styled';
import {
  Badge,
  Box,
  Button,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tab,
  Tooltip,
  tooltipClasses,
  Typography
} from '@mui/material';
import { alpha } from '@mui/system';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { features } from 'src/content/features';
import NewFeaturIcon from 'src/assets/ManagePageIcons/newFeature.gif';

export const StyledTab = styled(Tab)`
  min-width: 170px;
  padding: 8px 8px;
  height: 30px;
  font-weight: 500;
  text-transform: none;
  border-radius: 8px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  ${({ selected }) =>
    selected
      ? `
        background-color: #EEFBF5;
        color: #000;
      `
      : `
        background-color: transparent;
        color: #888888;
        &:hover {
          background-color: #f2f2f2;
        }
      `}

  &:hover {
    background-color: transparent;
    color: #27ab69;
    opacity: 100;
    & img {
      filter: brightness(0) saturate(100%) invert(59%) sepia(33%) saturate(901%)
        hue-rotate(98deg) brightness(87%) contrast(88%);
    }
  }
`;
const StyledListItemIcon = styled(ListItemIcon)(({ theme, active }) => ({
  minWidth: '25px',
  zIndex: '11',
  marginRight: theme.spacing(1),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& > img': {
    width: '20px',
    height: '20px',
    filter:
      active === 'true'
        ? ' brightness(0) saturate(100%) invert(52%) sepia(95%) saturate(348%) hue-rotate(98deg) brightness(88%) contrast(88%);' // #27AB69
        : 'brightness(0) saturate(100%) invert(37%) sepia(0%) saturate(1791%) hue-rotate(149deg) brightness(96%) contrast(87%);' // Black
  },
  '&:hover > img': {
    filter:
      'brightness(0) saturate(100%) invert(59%) sepia(33%) saturate(901%) hue-rotate(98deg) brightness(87%) contrast(88%)' // Apply hover effect
  }
}));
const ListItemWrapper = styled(Box)(({ theme, selected }) => ({
  margin: `${theme.spacing(0.5)} ${theme.spacing(0.05)}`,
  height: '40px',
  paddingLeft: '14px',
  color: selected ? '#27AB69' : '#5F5F5F',
  borderRadius: theme.general.borderRadius,
  cursor: 'pointer',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  // transition: 'color 0.3s',
  transition: 'none',
  '&:hover': {
    color: '#27AB69',
    backgroundColor: 'transparent'
  }
}));

export const TabCustom = ({
  tab,
  setSelectedTab,
  selectedTab,
  icon,
  style
}) => {
  return (
    <Box key={tab.id} sx={{ position: 'relative', ...style }}>
      {/* Motion Bubble for Active Tab */}
      {selectedTab === tab.id && (
        <motion.span
          layoutId="bubble-2"
          style={{
            position: 'absolute',
            inset: 0,
            backgroundColor: '#EEFBF5', // Light background when active
            borderRadius: '8px',
            zIndex: 10
          }}
          transition={{
            type: 'spring',
            bounce: 0.2,
            duration: 0.6
          }}
        />
      )}

      {icon && (
        <StyledListItemIcon active={selectedTab === tab.id ? 'true' : 'false'}>
          {icon}
        </StyledListItemIcon>
      )}
      {/* Tab Button */}
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <StyledTab
          sx={{ zIndex: 11 }}
          label={tab.label}
          onClick={() => setSelectedTab(tab.id)}
          disableRipple
          selected={selectedTab === tab.id}
        />
      </Box>
    </Box>
  );
};
export const TabCustom_Sidebar = ({
  item,
  selectedItem,
  style,
  handleItemClick,
  setSelectedItem
}) => {
  return (
    <ListItemWrapper
      key={item.id}
      selected={selectedItem === item.id}
      // selected={selectedItem}
      onClick={() => handleItemClick(item.id)}
    >
      {/* {features.find(
        (feature) =>
          feature.title === item.label && new Date(feature.endDate) > new Date()
      ) && (
        <Box sx={{ position: 'absolute', right: -3, top: -10, zIndex: 99999 }}>
          <img src={NewFeaturIcon} width={'30px'} height={'30px'} />
        </Box>
      )} */}

      {selectedItem === item.id && (
        <motion.span
          layoutId="bubble-3"
          style={{
            position: 'absolute',
            inset: 0,
            backgroundColor: '#EEFBF5', // Light background when active
            borderRadius: '8px'
          }}
          transition={{
            type: 'spring',
            bounce: 0.2,
            duration: 0.6
          }}
        />
      )}
      {/* <StyledListItemIcon active={selectedItem === item.id}>
        {item.icon}
      </StyledListItemIcon> */}
      <StyledListItemIcon active={selectedItem === item.id ? 'true' : 'false'}>
        {item.icon}
      </StyledListItemIcon>
      <Box sx={{ zIndex: 11, fontSize: '13px' }}>{item.label}</Box>
    </ListItemWrapper>
  );
};
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.wappBizColor.black,
    color: theme.wappBizColor.white,
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: theme.typography.pxToRem(12),
    padding: theme.spacing(1),
    borderRadius: '4px'
    // height: '40px'
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.wappBizColor.black,
    '&::before': {
      // This is where the actual arrow is styled
      backgroundColor: theme.wappBizColor.black,
      // boxShadow: '0 2px 2px 0 rgba(0,0,0,0.2)',  // Optional: adds a soft shadow to mimic curvature
      borderRadius: '2px' // Soften edges a bit; adjust as needed
    }
  }
}));

const StyledButton = styled(Button)`
  && {
    border-radius: 8px;
    // margin-bottom: 3px;
    background-color: #ffffff; /* Add your desired background color */
    // transition: background-color 0.3s ease; /* Add transition effect for hover */
    padding: 8px; /* Adjust padding as needed */

    & img {
      width: 22px;
      height: 22px;
      filter: brightness(0) invert(0);
    }
    &:hover img,
    &.active img {
      filter: invert(49%) sepia(79%) saturate(406%) hue-rotate(98deg)
        brightness(95%) contrast(88%);
    }
  }
`;
const NotificationsBadge = styled(Badge)(
  ({ theme }) => `
    .MuiBadge-badge {
        background-color: ${alpha(theme.palette.error.main, 0.1)} !important;
        color: ${theme.palette.error.main} !important;
        min-width: 16px; 
        height: 16px;
        padding: 0;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: "";
        }
    }
`
);

export const TabMain_Sidebar = ({
  to,
  icon,
  label,
  badgeContent,
  isActive,
  businessProfileData,
  RouterLink,
  closeSidebar,
  isRegister,
  activeIcon
}) => {
  const [isHovered, setIsHovered] = useState(false); // Track hover state

  return (
    <ListItem component="div" key={label}>
      <CustomTooltip title={label} placement="right">
        <StyledButton
          disableRipple
          component={RouterLink}
          onClick={closeSidebar}
          to={to}
          activeclassname="active"
          className={
            businessProfileData?.isRegistered === false ||
            (isRegister === 0 && to !== '/')
              ? 'disabled'
              : ''
          }
          onMouseEnter={() => setIsHovered(true)} // Start hover
          onMouseLeave={() => setIsHovered(false)} // End hover
        >
          {isActive && (
            <motion.span
              layoutId="bubble-4"
              style={{
                position: 'absolute',
                inset: 0,
                backgroundColor: '#D8FFEB', // Light background when active
                borderRadius: '8px'
              }}
              transition={{
                type: 'spring',
                bounce: 0.2,
                duration: 0.6
              }}
            />
          )}
          <Box
            sx={{ zIndex: 12 }}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            {/* <img  src={isHovered ? `${activeIcon}` : icon} alt={`${label} Icon`}   /> */}
            <img src={icon} alt={`${label} Icon`} />
            {/* <IconHeading>{label}</IconHeading> */}
            {badgeContent > 0 && (
              <NotificationsBadge
                badgeContent={badgeContent}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
              />
            )}
          </Box>
        </StyledButton>
      </CustomTooltip>
    </ListItem>
  );
};
const ButtonTypography = styled(Typography)`
  margin-right: 10px;
  font-size: 13px;
  font-weight: 500;
  text-transform: none;
  z-index: 1;

  // color: ${(props) => (props.active ? '#27AB69' : '#989898')};
  color: ${(props) => (props.active === 'true' ? '#27AB69' : '#989898')};
`;

const StyledButtonTemplate = styled.button`
  border-radius: 10px;
  position: relative;
  background-color: ${(props) =>
    props.active ? 'transparent' : 'transparent'};
  margin-right: 8px;
  padding: 6px 8px;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  &:hover {
    color: '#27AB69';
  }
  & > img {
    // height:16px;
    margin-right: 8px;
    filter: ${(props) =>
      props.active
        ? 'invert(59%) sepia(86%) saturate(4327%) hue-rotate(78deg) brightness(92%) contrast(89%)'
        : 'none'};
  }
`;
export const CreateTabTemplate = ({
  handleContentSelection,
  item,
  contentData
}) => {
  return (
    <StyledButtonTemplate
      onClick={() => handleContentSelection(item?.id)}
      active={contentData.type === item?.id}
    >
      {contentData?.type === item?.id && (
        <motion.span
          layoutId="bubble-4sa"
          style={{
            width: '100%',
            height: '40px',
            position: 'absolute',
            inset: 0,
            backgroundColor: '#D8FFEB', // Light background when active
            borderRadius: '8px'
          }}
          transition={{
            type: 'spring',
            bounce: 0.2,
            duration: 0.6
          }}
        />
      )}

      <img src={item?.icon} alt={`${item?.icon}`} style={{ position: "relative"}}/>

      <ButtonTypography
        variant="button"
        active={contentData.type === item?.id ? 'true' : 'false'}
      >
        {item?.label}
      </ButtonTypography>
    </StyledButtonTemplate>
  );
};
