import { Helmet } from 'react-helmet-async';
import { Box } from '@mui/material';
// import Footer from 'src/components/Footer';
import TemplateContent from './TemplateContent';
import styled from '@emotion/styled';
import ApiCalls from 'src/utility/ApiCalls';
import { useEffect, useState } from 'react';
import TemplateApiCalls from 'src/utility/TemplateApiCalls';
import TemplateBg from 'src/atom/components/TemplateBg'

const RootWrapper = styled(Box)(
  ({ theme }) => `
    height: calc(94vh - ${theme.header.height});
    display: flex;
    z-index: 0;
    
    ${theme.breakpoints.down('sm')} {
      height: calc(100vh - ${theme.header.height});
    }
  `
);

const index = ({initialData,top,custom}) => {
  // For Flow forms
  const [publishedForms, setPublishedForms] = useState([]);
const [attribute, setAttribute] = useState([]);
  const fetchPublishedForms = async () => {
    const response = await ApiCalls.listAllGlobalForms();
    
    if (response.status === 200 && !response.error) {
      const publishedFlows = response.data
        .flatMap(form => form.flows.filter(flow => flow.flowStatus === "Published").map(flow => ({
          flowName: form.flowName,
          flowId: flow.flowId
        })));
      
      setPublishedForms(publishedFlows);
    } else {
      // Handle the error scenario
      console.error("Failed to fetch flows:", response.message);
    }
  };

  const fetchAttribute = async () => {
    const response = await TemplateApiCalls.getTemplateAttributes();
    
    if (response.status === 200 && !response.error) {
      const publishedFlows = response.data
       
      
      setAttribute(publishedFlows);
    } else {
      // Handle the error scenario
      console.error("Failed to fetch flows:", response.message);
    }
  };

  useEffect(()=>{
    fetchAttribute()
    fetchPublishedForms()
  },[])
console.log("publishedForms",publishedForms)
  return (
    <>
      <Helmet>
        <title>New Template</title>
      </Helmet>
      <RootWrapper>
        <TemplateContent custom={custom} top={top} attribute={attribute} publishedForms={publishedForms} initialData={initialData}/>
              {/* ss  <TemplateBg top={top} />
      scc */}
      </RootWrapper>
      {/* <Footer /> */}
    </>
  );
};

export default index;
