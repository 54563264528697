
import {
    Box,
    useMediaQuery,
    useTheme,
    MenuItem,
    Menu,
    InputBase,
    Typography,
    Button
  } from '@mui/material';
  import React, { useCallback, useEffect, useState } from 'react';
  import syncIcon from 'src/assets/newTemplateIcons/sync.svg';
  import uploadIcon from 'src/assets/downicon.png';
  import { AnimatePresence, motion } from 'framer-motion';
  import styled from '@emotion/styled';

  const ButtonDrop = styled(Button)(({ selected }) => ({
    color: selected ? '#A3A3A3' : '#27AB69',
    fontWeight: 500,
    borderRadius: '10px',
    width: '180px',
    height: '40px',
    padding: '10px 10px',
    border: '1px solid #27AB69',
    marginRight: '5px',
    backgroundColor: selected ? '#BEFCC5' : '#F9F9F9',
    '&:hover': {
      color: '#27AB69',
      backgroundColor: '#BEFCC5'
    },
    '@media screen and (max-width: 500px)': {
      // marginBottom: '5px',
      width: '100%'
    }
  }));
  const CustomButton = styled(Button)`
    && {
      margin-right: 15px;
      border-radius: 10px;
      height: 40px;
      width: 80px;
      background-color: #27ab69;
      color: #fff;
      font-size: 15px;
      font-weight: 500;
      // display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      cursor: pointer;
      transition: background-color 0.3s ease;
      &:hover {
        background-color: #1e7e34;
      }
      @media screen and (max-width: 600px) {
        width: 100%; // Adjust width for smaller screens
      }
    }
  `;
  const Buttons = styled(Button)(({ selected }) => ({
    color: selected ? '#27AB69' : '#5F5F5F',
    fontWeight: 500,
    minWidth: 'auto',
    borderRadius: '5px',
    padding: '5px 10px',
    margin: '0px',
    backgroundColor: selected ? '#EEFBF5' : 'transparent',
    '&:hover': {
      backgroundColor: '#FFFFFF',
      color: '#27AB69',
      '& img': {
        filter:
          'brightness(0) saturate(100%) invert(59%) sepia(33%) saturate(901%) hue-rotate(98deg) brightness(87%) contrast(88%)' // #27AB69
      }
    },
  
    '& img': {
      filter: selected
        ? 'brightness(0) saturate(100%) invert(59%) sepia(33%) saturate(901%) hue-rotate(98deg) brightness(87%) contrast(88%)' // #27AB69
        : 'brightness(0) saturate(100%) invert(37%) sepia(0%) saturate(1791%) hue-rotate(149deg) brightness(96%) contrast(87%);' // Black
    }
  }));
  const TemplateFilterSectionLibrary = ({

    topic,industry,
    setIndustry,
    setTopic,
    refetch,
  }) => {


    let tabs2 = [
      {
        id: 'all',
        label: 'All'
      },
      {
        id: 'E_COMMERCE',
        label: 'E_COMMERCE'
      },
      {
        id: 'FINANCIAL_SERVICES',
        label: 'FINANCIAL_SERVICES'
      },
     
    ];
    let tabs = [
      {
        id: 'all',
        label: 'All'
      },
      {
        id: 'ACCOUNT_UPDATES',
        label: 'ACCOUNT_UPDATES'
      },
      {
        id: 'CUSTOMER_FEEDBACK',
        label: 'CUSTOMER_FEEDBACK'
      },
      {
        id: 'ORDER_MANAGEMENT',
        label: 'ORDER_MANAGEMENT'
      },
      {
        id: 'PAYMENTS',
        label: 'PAYMENTS'
      },
     
    ];
  
 

    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: { xs: 'wrap', md: 'nowrap' }
        }}
      >
          <Box
            sx={{
              pb: 1.5,
              width: '100%',
              zIndex: 9999,
              display: 'flex',
              gap:"10px",
              flexWrap:"wrap"
            }}
          >
            <Box
              sx={{
                maxWidth: { sm: '100%', md: '649px' },
  
                // width: '100%',
                display: 'flex',
                gap: '.5vw',
                overflowX: 'scroll',
                bgcolor: 'white',
                p: 0.7,
                borderRadius: '10px',
              }}
            >
         <>
                  {tabs2.map((tab) => (
                    <Buttons
                      key={tab.id}
                      value="all"
                      aria-label="myTemplates"
                      onClick={() => setIndustry(tab.id)}
                      selected={industry === tab.id}
                    >
                      {industry === tab.id && (
                        <motion.span
                          layoutId="bubble-lib-1"
                          style={{
                            position: 'absolute',
                            inset: 0,
                            color: 'red',
                            backgroundColor: '#EEFBF5',
                            borderRadius: '8px',
                            zIndex: 10
                          }}
                          transition={{
                            type: 'spring',
                            bounce: 0.2,
                            duration: 0.6
                          }}
                        />
                      )}
                      <Box
                        sx={{
                          zIndex: 11,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        <Typography
                          sx={{
                            // display: { xs: 'none', sm: 'inline' },
                            fontSize: '13px',
                            fontWeight: '500'
                          }}
                        >
                          {tab.label}
                        </Typography>
                      </Box>
                    </Buttons>
                  ))}
                </>
            </Box>
            <Box
              sx={{
                maxWidth: { sm: '100%', md: '649px' },
  
                // width: '100%',
                display: 'flex',
                gap: '.5vw',
                overflowX: 'scroll',
                bgcolor: 'white',
                p: 0.7,
                borderRadius: '10px',

              }}
            >
         <>
                  {tabs.map((tab) => (
                    <Buttons
                      key={tab.id}
                      value="all"
                      aria-label="myTemplates"
                      onClick={() => setTopic(tab.id)}
                      selected={topic === tab.id}
                    >
                      {topic === tab.id && (
                        <motion.span
                          layoutId="bubble-lib-2"
                          style={{
                            position: 'absolute',
                            inset: 0,
                            color: 'red',
                            backgroundColor: '#EEFBF5',
                            borderRadius: '8px',
                            zIndex: 10
                          }}
                          transition={{
                            type: 'spring',
                            bounce: 0.2,
                            duration: 0.6
                          }}
                        />
                      )}
                      <Box
                        sx={{
                          zIndex: 11,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        <Typography
                          sx={{
                            // display: { xs: 'none', sm: 'inline' },
                            fontSize: '13px',
                            fontWeight: '500'
                          }}
                        >
                          {tab.label}
                        </Typography>
                      </Box>
                    </Buttons>
                  ))}
                </>
            </Box>
    
            <Box display="flex" gap={2}></Box>
          </Box>
    
      </Box>
    );
  };
  
  export default TemplateFilterSectionLibrary;
  