import {
  Box,
  useMediaQuery,
  useTheme,
  MenuItem,
  Menu,
  InputBase,
  Typography,
  Button
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import syncIcon from 'src/assets/newTemplateIcons/sync.svg';
import uploadIcon from 'src/assets/downicon.png';
import { AnimatePresence, motion } from 'framer-motion';
import styled from '@emotion/styled';
import SearchCustom from 'src/atom/button/search/SearchCustom';

import allTemplateIcon from 'src/assets/TemplateIcons/allTemplatesIcon.svg';
import actionRequiredIcon from 'src/assets/TemplateIcons/actionRequiredIcon.svg';
import approvedIcon from 'src/assets/TemplateIcons/approvedIcon.svg';
import pendingIcon from 'src/assets/TemplateIcons/pendingIcon.svg';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';

const ButtonDrop = styled(Button)(({ selected }) => ({
  color: selected ? '#A3A3A3' : '#626262',
  fontWeight: 500,
  borderRadius: '10px',
  fontSize:"13px",
  width: '180px',
  height: '40px',
  padding: '10px 10px',
  // border: '1px solid #27AB69',
  marginRight: '5px',
  backgroundColor: selected ? '#BEFCC5' : '#ffffff',
  '&:hover': {
    color: '#27AB69',
    backgroundColor: '#BEFCC5'
  },
  '@media screen and (max-width: 500px)': {
    // marginBottom: '5px',
    width: '100%'
  }
}));
const CustomButtonPrimary = styled(Button)`
  && {
    margin-right: 15px;
    border-radius: 10px;
    height: 40px;
    width: 80px;
    background-color: #27ab69;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    // display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s ease;
    &:hover {
      background-color: #1e7e34;
    }
    @media screen and (max-width: 600px) {
      width: 100%; // Adjust width for smaller screens
    }
  }
`;
const CustomButton = styled(Button)`
  && {
    margin-right: 15px;
    border-radius: 10px;
    height: 40px;
    width: 80px;
   color: #27ab69;
   background-color: #fff;
    font-size: 15px;
  border: 1px solid #27AB69;

    font-weight: 500;
    // display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s ease;
    &:hover {
         color: #27AB69;
    background-color: #BEFCC5;
    }
   
    @media screen and (max-width: 600px) {
      width: 100%; // Adjust width for smaller screens
    }
  }
`;
const Buttons = styled(Button)(({ selected }) => ({
  color: selected ? '#27AB69' : '#5F5F5F',
  fontWeight: 500,
  minWidth: 'auto',
  borderRadius: '5px',
  padding: '5px 10px',
  margin: '0px',
  backgroundColor: selected ? '#EEFBF5' : 'transparent',
  '&:hover': {
    backgroundColor: '#FFFFFF',
    color: '#27AB69',
    '& img': {
      filter:
        'brightness(0) saturate(100%) invert(59%) sepia(33%) saturate(901%) hue-rotate(98deg) brightness(87%) contrast(88%)' // #27AB69
    }
  },

  '& img': {
    filter: selected
      ? 'brightness(0) saturate(100%) invert(59%) sepia(33%) saturate(901%) hue-rotate(98deg) brightness(87%) contrast(88%)' // #27AB69
      : 'brightness(0) saturate(100%) invert(37%) sepia(0%) saturate(1791%) hue-rotate(149deg) brightness(96%) contrast(87%);' // Black
  }
}));
const TemplateFilterSection = ({
  rejectedTemplates,
  pendingTemplates,
  approvedTemplates,
  allTemplates,
  setSelectedAllType,
  insideLib,
  searchQuery,
  setSearchQuery,
  category,
  setCategory,
  refetch,
  selectedAllType 
}) => {
  const [search, setSearch] = useState(false);
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('sm'));
  const [rotate, setRotate] = useState(0);
  let tabs = [
    {
      id: 'myTemplates',
      icon: allTemplateIcon,
      label: 'Templates',
      count: allTemplates?.length
    },
    {
      id: 'approved',
      icon: approvedIcon,
      label: 'Approved',
      count: approvedTemplates
    },
    {
      id: 'pending',
      icon: pendingIcon,
      label: 'Pending',
      count: pendingTemplates
    },
    {
      id: 'action',
      icon: actionRequiredIcon,
      label: 'Action Required',
      count: rejectedTemplates
    }
  ];
  const handleRotate = () => {
    // Rotate by 360 degrees on each click
    console.log(rotate);
    setRotate((prevRotate) => prevRotate + 360);
    refetch(); // Call your refetch function
  };
  let tabs2 = [
    {
      id: 'all',
      label: 'All'
    },
    {
      id: 'MARKETING',
      label: 'Marketing'
    },
    {
      id: 'UTILITY',
      label: 'Utiliy'
    },
    {
      id: 'AUTHENTICATION',
      label: 'Authentication'
    },
    {
      id: 'CAROUSEL',
      label: 'Carousel'
    }
  ];
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);
  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const [status, setStatus] = useState(tabs[0]);
  useEffect(() => {
    if (allTemplates) {
      setStatus(tabs[0]);
    }
  }, [allTemplates]);
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: { xs: 'wrap', md: 'nowrap' }
      }}
    >
        <Box
          sx={{
            pb: 1.5,
            width: '100%',
            zIndex: 9999,
            display: 'flex'
          }}
        >
          <Box
            sx={{
              maxWidth: { sm: '100%', md: '649px' },

              // width: '100%',
              display: 'flex',
              gap: '.5vw',
              overflowX: 'scroll',
              bgcolor: 'white',
              p: 0.7,
              borderRadius: '10px',
              // borderTopLeftRadius: '10px',
              // borderBottomLeftRadius: '10px'
            }}
          >
            {!isMdUp ? (
              <>
                {!search && (
                  <>
                    {tabs2.map((tab) => (
                      <Buttons
                        key={tab.id}
                        value="myTemplates"
                        aria-label="myTemplates"
                        onClick={() => setCategory(tab.id)}
                        selected={selectedAllType === tab.id}
                      >
                        {category === tab.id && (
                          <motion.span
                            layoutId="bubble"
                            style={{
                              position: 'absolute',
                              inset: 0,
                              color: 'red',
                              backgroundColor: '#EEFBF5',
                              borderRadius: '8px',
                              zIndex: 10
                            }}
                            transition={{
                              type: 'spring',
                              bounce: 0.2,
                              duration: 0.6
                            }}
                          />
                        )}
                        <Box
                          sx={{
                            zIndex: 11,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <Typography
                            sx={{
                              // display: { xs: 'none', sm: 'inline' },
                              fontSize: '13px',
                              fontWeight: '500'
                            }}
                          >
                            {tab.label}
                          </Typography>
                        </Box>
                      </Buttons>
                    ))}
                  </>
                )}
              </>
            ) : (
              <>
                {tabs2.map((tab) => (
                  <Buttons
                    key={tab.id}
                    value="myTemplates"
                    aria-label="myTemplates"
                    onClick={() => setCategory(tab.id)}
                    selected={selectedAllType === tab.id}
                  >
                    {category === tab.id && (
                      <motion.span
                        layoutId="bubble"
                        style={{
                          position: 'absolute',
                          inset: 0,
                          color: 'red',
                          backgroundColor: '#EEFBF5',
                          borderRadius: '8px',
                          zIndex: 10
                        }}
                        transition={{
                          type: 'spring',
                          bounce: 0.2,
                          duration: 0.6
                        }}
                      />
                    )}
                    <Box
                      sx={{
                        zIndex: 11,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Typography
                        sx={{
                          // display: { xs: 'none', sm: 'inline' },
                          fontSize: '13px',
                          fontWeight: '500'
                        }}
                      >
                        {tab.label}
                      </Typography>
                    </Box>
                  </Buttons>
                ))}
              </>
            )}
          <SearchCustom
            search={search}
            setSearch={setSearch}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            />
            </Box>
   <Box>
            <ButtonDrop
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '5px',
                marginLeft:"5px"
              }}
            >
        
              {status.label}
              <span>({status?.count})</span>
              {/* <img
                src={uploadIcon}
                alt="icon"
                height={20}
                style={{ paddingLeft: '4px' }}
              ></img>*/}
              <ExpandMoreTwoToneIcon/> 
            </ButtonDrop>
            <Menu
              id="simple-menu"
              width="250px"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              PaperProps={{
                style: {
                  transformOrigin: 'top', // Set the origin to the top of the menu
                  marginTop: '5px' // Add margin top to move the menu down
                }
              }}
              sx={{
                '.css-1kj71s-MuiPaper-root-MuiPopover-paper-MuiMenu-paper .css-v88vw9-MuiList-root-MuiMenu-list':
                  { padding: '0px !important' },
                '.css-1qo2slv-MuiButtonBase-root-MuiMenuItem-root': {
                  padding: '4px',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  color: '#959595'
                }
              }}
            >
              {/* <MenuItem onClick={handleClose}>
      </MenuItem> */}
              {tabs.map((item) => (
                <MenuItem
                  onClick={() => {
                    setStatus(item);

                    setSelectedAllType(item.id);
                    handleClose();
                  }}
                >
                  {item.label}
                </MenuItem>
              ))}
            </Menu>
          </Box>
          {/* <Box display="flex" gap={2}></Box> */}
        </Box>
      {!insideLib && (
        <Box
          sx={{
            pb: 1.5,
            width: { xs: '100%' },
            // width:"100%",
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center'
          }}
        >
          <CustomButton variant="contained" onClick={() => handleRotate()}>
            <Box
              sx={{
                zIndex: 11,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Typography
                sx={{
                  display: { xs: 'none', sm: 'inline' },
                  fontSize: '12px',
                  fontWeight: '500',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px'
                }}
              >
                <img
                  src={syncIcon}
                  alt="syncIcon"
                  style={{
                    transform: `rotate(${rotate}deg)`,
                    transition: 'transform 1s ease', // Smooth transition
                 filter: 'invert(51%) sepia(93%) saturate(497%) hue-rotate(120deg) brightness(96%) contrast(91%)',
                  // filter: 'invert(33%) sepia(52%) saturate(748%) hue-rotate(133deg) brightness(95%) contrast(92%)'
                  }}
                />
                <span>Sync</span>
              </Typography>
            </Box>
          </CustomButton>
       
        </Box>
      )}
    </Box>
  );
};

export default TemplateFilterSection;
