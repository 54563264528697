import React, { useState } from 'react';
import { Dialog, Checkbox, Box, DialogTitle, DialogContent, Button, CircularProgress, Typography, styled } from '@mui/material';
import OtpInput from 'react-otp-input';
import Toast from 'src/utility/Toast';
import ApiCalls from 'src/utility/ApiCalls';
import './VerifyDialog.css'; // Import the CSS file
import { useDispatch } from 'react-redux';
import { getRegisteredBusiness } from 'src/redux/actions/wappAction';

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

// Custom styled tooltip
const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#333',
      color: 'white',
      boxShadow: theme.shadows[1],
      fontSize: 11,
      padding: '4px 8px' // Reduced padding
    }
  }));

const VerifyDialog = ({ open, onClose }) => {
    const [rememberPin, setRememberPin] = useState(false);
    const [otp, setOtp] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const dispatch = useDispatch();

    const verifyOTP = async () => {
        if (otp.length !== 6) {
            setError('Invalid OTP');
            Toast({ message: 'Invalid OTP', type: 'error' });
            return;
        }
        try {
            setLoading(true);
            // Simulate API call to verify OTP
            const response = await ApiCalls.VerifyRegister({ pin: otp, tf: rememberPin });
            if (response?.status === 200 && response?.error === false) {
                onClose();
                setLoading(false);
                dispatch(getRegisteredBusiness);
                Toast({ message: response?.message, type: 'success' });
            } else {
                setError('OTP verification failed');
                Toast({ message: response.message, type: 'error' });
                setLoading(false);
            }
        } catch (error) {
            Toast({ message: error?.message, type: 'error' });
            setLoading(false);
            setError('An error occurred while verifying OTP');
        }
    };

    return (
        <Dialog
            open={open}
            BackdropProps={{
                style: { backgroundColor: 'rgba(0, 0, 0, 0.8)' }
            }}
            sx={{
                '& .MuiDialog-paper': {
                    maxWidth: { xs: '90vw', sm: '70vw', md: '60vw' },
                    maxHeight: { xs: '70vh', sm: '60vh' },
                    padding: 2,
                    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.2)'
                }
            }}
        >
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontWeight: 'bold' }}>
                <Typography variant='h4' fontSize={18}>Two Factor Authentication</Typography>
                <Typography variant='subtitle2' color="green" sx={{ cursor: "pointer", textDecoration: 'underline' }}
                    onClick={() => {
                        window.location.href = `mailto:support@wapp.biz?subject=${'Need Support for Two Factor Authentication'}`;
                    }}
                >Need Help ?</Typography>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ textAlign: 'center', mb: 3 }}>
                    <Typography gutterBottom variant='body2'>If Two-factor verification was done before, Use that Pin <br/> or Create a new Pin.</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <OtpInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={6}
                            shouldAutoFocus={true}
                            renderInput={(props) => <input {...props} className="otp-input" />}
                            inputType={'number'}
                            containerStyle={{
                                display: 'flex',
                                justifyContent: 'center',
                                gap: '0.25rem'
                            }}
                            inputStyle={{
                                width: '100%',
                                maxWidth: '3rem',
                                height: '3rem',
                                margin: '0 0.25rem',
                                fontSize: '1.5rem',
                                borderRadius: '0.25rem',
                                border: '1px solid #ccc',
                                textAlign: 'center',
                                outline: 'none',
                                boxSizing: 'border-box',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                transition: 'border-color 0.3s'
                            }}
                            focusStyle={{
                                borderColor: 'green',
                            }}
                        />
                    </Box>
                </Box>
                <Button
                    variant="contained"
                    onClick={verifyOTP}
                    disabled={loading}
                    fullWidth
                    sx={{
                        backgroundColor: '#27AB69',
                        mt: 2,
                        py: 1.5,
                        fontSize: '1rem',
                        fontWeight: 'bold',
                    }}
                >
                    {loading ? <CircularProgress size={24} /> : 'Verify'}
                </Button>
                <p style={{ textAlign: 'center' }}>
                forget or misplace your PIN ?
                    <CustomTooltip placement="top" title="Go to settings, log into your Facebook Business, and click the business you are using to manage your WABA (WhatsApp Business Account).">
                        <a target='_blank' href='https://business.facebook.com/latest/settings'>Click here</a>
                    </CustomTooltip>
                </p>
            </DialogContent>
        </Dialog>
    );
};

export default VerifyDialog;
