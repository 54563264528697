import axios from 'axios';
import { getDecryptedData, storeEncryptedData } from './LocalStorageManager';

const apiKey = process.env.REACT_APP_API_URL;

export const axiosInstance = axios.create({
  baseURL: `${apiKey}/api/`
});
export const axiosAdminInstance = axios.create({
  baseURL: `${apiKey}/api/`
});

axiosInstance.interceptors.request.use(
  (config) => {
    // Retrieve token from session storage
    const userInfo = getDecryptedData('userInfo');
    // Check if token exists before adding the Authorization header
    if (userInfo?.token) {
      config.headers['Authorization'] = `${userInfo.token}`;
      config.withCredentials = true;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axiosAdminInstance.interceptors.request.use(
  (config) => {
    // Retrieve token from session storage
    const userInfo =
      JSON.parse(sessionStorage.getItem('adminInfo')) ||
      getDecryptedData('managerInfo');
    if (userInfo?.token) {
      config.headers['Authorization'] = `${userInfo.token}`;
      config.withCredentials = true;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 403) {
      if (error.response.data?.data === '403_EXPIRED_SUBSCRIPTION') {
        console.log('Redirecting to plans page due to expired subscription...');
        // Get decrypted user info
        let userInfo = getDecryptedData('userInfo') || {};
        console.log(userInfo, 'userInfo');

        // Update subscription details
        userInfo.planId = null;
        userInfo.planName = null;
        userInfo.planType = null;
        // userInfo.paymentStatus = 'expired';

        // Save updated data securely back to local storage
        storeEncryptedData('userInfo', userInfo);
      }
    }
    return Promise.reject(error);
  }
);

