import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import CustomTooltip from 'src/content/applications/Components/customTooltip';
import WarningIcon from 'src/assets/svgIcons/warningIcon.svg';
import VerifyDialog from 'src/content/applications/Dashboard/VerifyDialog';

const index = () => {
  const [showDialog, setShowDialog] = useState(false);

  console.log('show Dialog : ', showDialog);

  const navigate = useNavigate();

  return (
    <Box sx={{ display: 'flex' }}>
      {showDialog && <VerifyDialog open={showDialog} onClose={setShowDialog} />}
      <CustomTooltip
        title="Click here to verify your mobile number"
        placement="top"
      >
        <Typography
          sx={{
            px: '6px',
            py: '4px',
            borderRadius: '6px',
            bgcolor: '#fab005',
            color: '#fff',
            cursor: 'pointer',
            fontSize: '13px',
            display: 'flex',
            alignItems: 'center'
          }}
          onClick={() => setShowDialog(true)}
        >
          Mobile number verification is pending
          <img
            src={WarningIcon}
            width={'20px'}
            height={'20px'}
            style={{ marginLeft: '5px' }}
          />
        </Typography>
      </CustomTooltip>
    </Box>
  );
};

export default index;
