import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  List,
  ListItemButton,
  ListItemText,
  Popover,
  Typography
} from '@mui/material';
// import HeaderSearch from './Search';
import HeaderNotifications from './Notifications';
import Calendar from './Calendar';
import Support from './Support';
import VerifyNumber from './VerifyNumber';
import { useSelector } from 'react-redux';
import profileAvatar from 'src/assets/CustomerPageMedias/cutomerTopbarDropdown/profileUser.svg';
import changePassword from 'src/assets/CustomerPageMedias/cutomerTopbarDropdown/changePassword.svg';
import signOutAvatar from 'src/assets/CustomerPageMedias/cutomerTopbarDropdown/signOut.svg';
import notificationIcon from 'src/assets/CustomerPageMedias/cutomerTopbarDropdown/notification.svg';
import quickGuide from 'src/assets/CustomerPageMedias/cutomerTopbarDropdown/quickGuide.svg';
import support from 'src/assets/CustomerPageMedias/cutomerTopbarDropdown/support.svg';
import calendar from 'src/assets/CustomerPageMedias/cutomerTopbarDropdown/calendar.svg';
import planIcon from 'src/assets/ManagePageIcons/plansIcon.svg';
import { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { getDecryptedData } from 'src/utility/LocalStorageManager';
import { useTheme } from '@emotion/react';
import { useNavigate } from 'react-router';
import { useAuth } from 'src/Authentication/AuthContext';
import { addAction, resetState } from 'src/redux/features/wappSlice';
import { useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import ApiCalls from 'src/utility/ApiCalls';
import BusinessApiCalls from 'src/utility/BusinessApiCalls';
import AddBusinessIcon from 'src/assets/svgIcons/addBusiness.svg';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import { motion } from 'framer-motion';
import addIcon from 'src/assets/svgIcons/addIconWhite.svg';
import Toast from 'src/utility/Toast';
import CustomTooltip from 'src/content/applications/Components/customTooltip';

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)`
  font-weight: 500;
  color: #111111;
  display: block;
  font-size: 14px;
`;

const UserBoxDescription = styled(Typography)`
  font-weight: 400;
  color: #a3a3a3;
  display: block;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 5px;
`;

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#F6F6F6'
  }
}));

const MotionDiv = motion(Typography);

const apiKey = process.env.REACT_APP_API_URL;

function HeaderButtons() {
  const theme = useTheme();
  const ref = useRef(null);

  const { businessProfileData, unreadCustomerCount } = useSelector(
    (state) => state.wapp
  );
  const [isOpen, setOpen] = useState(false);
  const [businessName, setBusinessName] = useState('');
  const [numberInfo, setNumberInfo] = useState(null);
  const [selectedNumber, setSelectedNumber] = useState('');
  const [registeredNumbers, setRegisteredNumbers] = useState([]);

  const queryClient = useQueryClient();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const fetchProfile = async () => {
    try {
      const response = await ApiCalls.getRegisteredBusiness();

      if (response) {
        setRegisteredNumbers(response?.data);
      }

      // const displayName = await BusinessApiCalls.fetchNumberDisplayName();
      // if (
      //   displayName?.status === 200 &&
      //   displayName?.error == false &&
      //   displayName?.data
      // ) {
      //   setBusinessName(displayName?.data);
      // }

      const data = response?.data?.find((number) => number.status === 'active');

      if (data) {
        setSelectedNumber(data?.phone_number_id);
        setNumberInfo(data);
      }
    } catch (error) {
      console.error('Error fetching profile data:', error);
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  const handleNumberChange = async (number) => {
    setSelectedNumber(number.phone_number_id);
    setNumberInfo(number);

    try {
      const response = await BusinessApiCalls.changeNumberStatus(
        number?.phone_number_id
      );
      if (response.data) {
        Toast({
          message: 'Number change successfully',
          type: 'success'
        });
        dispatch(addAction({ payload: number, type: 'businessProfileData' }));
        fetchProfile();
        window.location.reload();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <>
      {(businessProfileData?.account_status === 'PENDING' ||
        businessProfileData?.isRegistered == false) && (
        <Box mr={'8px'}>
          <VerifyNumber />
        </Box>
      )}

      {numberInfo && (
        <UserBoxButton
          color="secondary"
          ref={ref}
          onClick={() => setOpen(!isOpen)}
        >
          <Avatar
            variant="circular"
            src={`${apiKey}${numberInfo?.media_url}`}
            alt={numberInfo?.verified_name}
            sx={{ fontWeight: 500 }}
          />
          <Hidden mdDown>
            <UserBoxText>
              <UserBoxLabel>{numberInfo?.phone}</UserBoxLabel>
              <UserBoxDescription>
                <img src={AddBusinessIcon} width={'16px'} height={'16px'} />
                <Typography sx={{ fontSize: '13px' }}>
                  {numberInfo?.verified_name}
                </Typography>
              </UserBoxDescription>
            </UserBoxText>
          </Hidden>
          <Hidden>
            <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
          </Hidden>

          <Popover
            anchorEl={ref.current}
            onClose={() => setOpen(false)}
            open={isOpen}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            sx={{
              marginTop: '4.4%',
              [theme.breakpoints.down('xs')]: {
                marginLeft: '40px', // Specific for extra-small devices
                marginTop: '50px'
              },
              [theme.breakpoints.between('xs', 'sm')]: {
                marginLeft: '40px', // Adjusted for small devices
                marginTop: '60px'
              },
              [theme.breakpoints.up('sm')]: {
                marginLeft: '40px', // Adjusted for medium devices
                marginTop: '60px'
              },
              [theme.breakpoints.up('md')]: {
                marginLeft: '40px', // Adjusted for medium devices
                marginTop: '60px'
              },
              [theme.breakpoints.up('lg')]: {
                marginLeft: '5px', // Adjusted for large devices
                marginTop: '65px'
              }
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <Divider sx={{ mb: 0 }} />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'start',
                gap: 0.6,
                p: 1,
                borderRadius: '12px',
                width: '250px',
                bgcolor: '#FEFEFE'
              }}
            >
              {registeredNumbers?.map((number) => (
                // <List>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 1,
                    py: 0.5,
                    px: 1,
                    borderRadius: '8px',
                    cursor: 'pointer'
                  }}
                  onClick={() => handleNumberChange(number)}
                >
                  <Avatar
                    variant="circular"
                    src={`${apiKey}${number.media_url}`}
                    alt={number.verified_name}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <Box>
                      <Typography sx={{ fontSize: '14px', color: '#050505' }}>
                        {number?.phone}
                      </Typography>
                      <Typography sx={{ fontSize: '12px', color: '#9F9F9F' }}>
                        {number?.verified_name}
                      </Typography>
                    </Box>
                    {unreadCustomerCount[number?.phone_number_id] && (
                      <Typography
                        sx={{
                          bgcolor: '#5fc944',
                          color: '#fff',
                          width: '16px',
                          height: '16px',
                          display: 'flex',
                          justifyContent: 'center',
                          justifyItems: 'center',
                          alignItems: 'center',
                          fontSize: '11px',
                          borderRadius: '50%'
                        }}
                      >
                        <span>
                          {
                            Object.keys(
                              unreadCustomerCount[number?.phone_number_id]
                            ).length
                          }
                        </span>
                      </Typography>
                    )}
                  </Box>
                </Box>
                // </List>
              ))}
              <MotionDiv
                whileTap={{
                  scale: 0.8,
                  transition: {
                    type: 'spring',
                    stiffness: 300,
                    damping: 10
                  }
                }}
                sx={{
                  mt: '8px',
                  mb: '4px',
                  display: 'flex',
                  justifyContent: 'center',
                  textAlign: 'center',
                  width: '100%',
                  bgcolor: '#27AB69',
                  color: '#fff',
                  p: '8px',
                  borderRadius: '8px',
                  cursor: 'pointer'
                }}
                onClick={() => navigate('/addNewNumber')}
              >
                Add New Number
                <img src={addIcon} style={{ marginLeft: '10px' }} />
              </MotionDiv>
            </Box>
          </Popover>
        </UserBoxButton>
      )}

      <Hidden smDown>
        <Box sx={{ mr: 0.5, display: 'flex', alignItems: 'center' }}>
          <Box sx={{ mx: '1px' }} component="span">
            <Calendar />
            {/* <HeaderNotifications /> */}
          </Box>
          <Box sx={{ mx: '1px' }} component="span">
            {/* <Calendar /> */}
            <Support />
          </Box>
          <Box sx={{ mx: '1px' }} component="span">
            {/* <Calendar /> */}
            <HeaderNotifications />
          </Box>
        </Box>
      </Hidden>
    </>
  );
}

export default HeaderButtons;
