import { Box, Typography } from '@mui/material';
import React ,{memo,useMemo,useEffect} from 'react'
import ChatBG from 'src/assets/TemplateIcons/BG.svg';
import TemplateChatBgPreview from './TemplateChatBgPreview';
import styled from '@emotion/styled';
import VideoComponent from './VideoComponent';


const TemplateBg = memo(({data,width,height,top}) => {
  const RightSide = useMemo(() => styled(Box)(() => ({
    backgroundColor: '#ffffff',
    height: height || "",
    width: width || "400px",
    position: "sticky",
    top: top || "90px",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxShadow: '0px 3px 4.5px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.02)',
    },
  })), [width, height, top]);
  const dataMemo = useMemo(() => (data), [JSON.stringify(data)]);


  useEffect(() => {
    console.log('✅ TemplateBg Mounted');
    return () => {
      console.log('❌ TemplateBg Unmounted');
    };
  }, []);
  return (
    <RightSide>
    <Typography
      fontSize="16px"
      fontWeight={500}
      color="#111111"
      style={{padding:"20px"}}
    >
      Template Preview
    </Typography>
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      sx={{ backgroundImage: ` url(${ChatBG})`,height:"100%"}}
    >
      <TemplateChatBgPreview data={dataMemo} bgColor={'white'} chatPage={true} minWidth={"257px"} insideTemplate={{top:"0px",right:"9px",left:"-9px",  transform: 'rotate(0deg)',}} />
   
   
    </Box>
  </RightSide>
  )
})
export default TemplateBg
