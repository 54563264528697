import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  Menu,
  MenuItem,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material';
// import { makeStyles } from '@mui/styles';
import { AnimatePresence, motion } from 'framer-motion';

import AddIcon from '@mui/icons-material/Add';
import React, { useEffect, useRef ,useMemo} from 'react';
import { useState } from 'react';
import TemplateApiCalls from 'src/utility/TemplateApiCalls';
import Toast from 'src/utility/Toast';
import { useTheme } from '@emotion/react';
import EmojiPicker from 'emoji-picker-react';
import ApiCalls from 'src/utility/ApiCalls';
import { useNavigate } from 'react-router';
import styled from '@emotion/styled';
import StepConnector, {
  stepConnectorClasses
} from '@mui/material/StepConnector';
import Check from '@mui/icons-material/Check';
import PropTypes from 'prop-types';
import TemplatePreview from '../Components/TemplatePreviews';
// import noneIcon from '../../../assets/newTemplateIcons/none.svg';
// import textIcon from '../../../assets/newTemplateIcons/text.svg';
import imageIcon from '../../../assets/newTemplateIcons/image.svg';
import videoIcon from '../../../assets/newTemplateIcons/video.svg';
import documentIcon from '../../../assets/newTemplateIcons/document.svg';
import quickReplay from '../../../assets/newTemplateIcons/quickReply.svg';
import callToAction from '../../../assets/newTemplateIcons/callToAction.svg';
import infoIcon from '../../../assets/newTemplateIcons/infoIcon.svg';
import FlowFormIcon from '../../../assets/newTemplateIcons/FormIcon.svg';
// import CircularProgress from '@material-ui/core/CircularProgress';
import { isValidPhoneNumber } from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './phoneInputStyle.css';
import { useAuth } from 'src/Authentication/AuthContext';
import { getDecryptedData } from 'src/utility/LocalStorageManager';
import Select from 'react-select';
import { bgcolor } from '@mui/system';
import documentButton from '../../../assets/MediaIcons/documents.svg';
import imageButton from '../../../assets/MediaIcons/images.svg';
import videoButton from '../../../assets/MediaIcons/videos.svg';
import variableIcon from 'src/assets/ChatbotIcons/variableIcon.svg';
import boldIcon from 'src/assets/TemplateIcons/bold.svg';
import attributeIcon from 'src/assets/newTemplateIcons/attribute.svg';
import italicIcon from 'src/assets/TemplateIcons/italic.svg';
import strikeIcon from 'src/assets/TemplateIcons/strikeThrough.svg';
import smileIcon from 'src/assets/ChatbotIcons/smileIcon.svg';
import addIcon from 'src/assets/svgIcons/addIconWhite.svg';
import textIcon from 'src/assets/svgIcons/textIcon.svg';
import noneIcon from 'src/assets/svgIcons/noneIcon.svg';
import ChatBG from 'src/assets/TemplateIcons/chatbg.png';
import { CreateTabTemplate } from '../Manage/manageStyle';
import QuickReply from './components/QuickReply';
import CallToAction from './components/CallToAction';
import TemplateBg from 'src/atom/components/TemplateBg';
import { position } from 'stylis';
import AttributeMatch from './components/AttributeMatch';
import { useSelector } from 'react-redux';

const customStyles = {
  control: (provided) => ({
    ...provided,
    width: '100%',
    height: 45,
    borderRadius: 8,
    fontSize: 13,
    overflow: 'hidden',
    borderColor: '#ececec'
  }),
  option: (base, { isSelected }) => ({
    ...base,
    backgroundColor: isSelected ? '#d1e7dd' : 'transparent',
    color: isSelected ? '#0f5132' : '#000',
    '&:hover': {
      backgroundColor: isSelected ? '#bcd0c7' : '#D8FFEB'
    }
  }),
  menu: (provided) => ({
    ...provided,
    width: '100%',
    overflow: 'hidden'
  })
};

const apiKey = process.env.REACT_APP_API_URL;

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 1,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)'
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#27AB69',
      borderTopWidth: 4
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#27AB69',
      borderTopWidth: 4
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 2,
    borderRadius: 1
  }
}));

const QontoStepIconRoot = styled('div')(({ theme, active }) => ({
  color: active
    ? '#27AB69'
    : theme.palette.mode === 'dark'
      ? theme.palette.grey[700]
      : '#eaeaf0',
  display: 'flex',
  height: 5,
  alignItems: 'center',
  '& .QontoStepIcon-completedIcon': {
    color: '#27AB69',
    zIndex: 1,
    fontSize: 22
  },
  '& .QontoStepIcon-circle': {
    width: 14,
    height: 14,
    borderRadius: '50%',
    backgroundColor: 'currentColor'
  },
  '& .QontoStepIcon-circle-active': {
    width: 16,
    height: 16,
    borderRadius: '50%',
    backgroundColor: '#27AB69'
  }
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {active ? (
        <div className="QontoStepIcon-circle-active" />
      ) : completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool
};

//  Prev,next,send Button dsign
const SendPrevButtons = styled(Button)`
  && {
    width: 288px;
    height: 45px;
    font-size: 15px;
    font-weight: 600;
    border-radius: 5px;
    padding: 10px 16px;
    margin-top: 30px;
    background-color: ${(props) => (props.disabled ? '#FFFFFF' : '#27ab69')};
    color: ${(props) => (props.disabled ? '#27ab69' : '#fff')};
    border: 2px solid ${(props) => (props.disabled ? '#27ab69' : 'transparent')};
    &:hover {
      background-color: ${(props) => (props.disabled ? '#FFFFFF' : '#1e8c54')};
    }
  }
`;

//  text field styles
const CustomTextField = styled(TextField)(
  () => `
    & .MuiInputBase-root {
      height: 45px;
      display: flex;
      align-items: center;
    },
    & .MuiOutlinedInput-notchedOutline{
      border-color:#ececec;
    },
    border-radius: 10px;
    font-size: 13px;
  `
);

const customStylesReactSelect = {
  control: (provided, { isDisabled, isFocused }) => ({
    ...provided,
    boxSizing: 'border-box',
    height: '45px', // Matches the height from your styled component
    borderRadius: '10px', // Matches the border-radius
    border: isFocused
      ? '2px solid #008000'
      : isDisabled
        ? '1px solid #9099a9'
        : '1px solid #ececec', // Green when active, grey otherwise
    boxShadow: 'none', // Removes the blue focus border
    backgroundColor: isDisabled ? 'white' : 'white', // Red when disabled
    opacity: isDisabled ? 0.6 : 1, // Reduce opacity for disabled state
    pointerEvents: isDisabled ? 'none' : 'auto', // Disable interaction if needed
    cursor: isDisabled ? 'none' : 'pointer', // Disable interaction if needed
    '&:hover': {
      border: '1px solid black' // Black border on hover (only when enabled)
    }
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#a2a9b7' // Blue placeholder color
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999, // Ensure the dropdown is above other elements
    position: 'absolute'
  })
};

const CustomSelect = styled(Select)`
  & .MuiInputBase-root {
    height: 45px; /* Adjust height as needed */
    display: flex;
    align-items: center;
    border-radius: 10px; /* Adjust border-radius */
  }
`;

//  All headings
const Headings = styled(Typography)(
  () => `
      color: #111111;
      font-size: 14px; 
      font-weight: 500; 
      margin-bottom:8px;
      margin-top:10px;
    `
);
const MainHeadings = styled(Typography)(
  () => `
      color: #111111;
      font-size: 16px; 
      font-weight: 500; 
      margin-bottom:8px;
      margin-top:10px;
    `
);

const SecondPageHeadings = styled(Typography)(
  () => `
      color: #111111;
      font-size: 15px; 
      font-weight: 500; 
      margin-bottom:10px;
      margin-top:20px;
      // align-content:center;
    `
);

//  action buttons classification eg: URL/Mobile
const CallToActionButtonHeading = styled(Typography)(
  () => `
      color: #989898;
      font-size: 15px; 
      font-weight: 500; 
      background-color: #EFEFEF;    
      `
);

//  action buttons inner text
const InnerButtonTextField = styled(TextField)`
  & .MuiInputLabel-root {
    color: #c0c0c0;
    font-size: 14px;
    font-weight: 400;
  }
  & .MuiInput-underline:before {
    border-bottom: 1px solid #ebebeb;
  }
`;

//  header type selection button style
const StyledButton = styled.button`
  border-radius: 10px;
  background-color: ${(props) => (props.active ? '#BEFCC5' : 'transparent')};
  margin-right: 8px;
  padding: 6px 8px;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  &:hover {
    background-color: ${(props) => (props.active ? '#BEFCC5' : '#f0f0f0')};
  }
  & > img {
    // height:16px;
    margin-right: 8px;
    filter: ${(props) =>
    props.active
      ? 'invert(59%) sepia(86%) saturate(4327%) hue-rotate(78deg) brightness(92%) contrast(89%)'
      : 'none'};
  }
`;

//  header type selection button style
const CarouselButton = styled(Button)(({ theme }) => ({
  color: '#959595',
  margin: '0px 5px 0px 0px',
  padding: '4px 8px',
  // backgroundColor: '#BEFCC5',
  border: '1px solid #959595',
  fontWeight: 500,
  borderRadius: '10px',
  '&:hover': {
    backgroundColor: '#BEFCC5',
    color: '#27AB69'
  }
}));

const ButtonTypography = styled(Typography)`
  margin-right: 10px;
  font-size: 13px;
  font-weight: 500;
  text-transform: none;
  // color: ${(props) => (props.active ? '#27AB69' : '#989898')};
  color: ${(props) => (props.active === 'true' ? '#27AB69' : '#989898')};
`;

//  Add variable button style
const StyledText = styled(Typography)`
  margin-top: 5px;
  color: #27ab69;
  font-size: 12px;
  font-weight: 500;
  padding-left: 10px;
  height: auto;
  border-radius: 50px;
  width: auto;
  display: inline-block;
  text-align: center;
  cursor: pointer;
`;

const LeftSide = styled(Box)(({ theme }) => ({
  backgroundColor: '#ffffff',
  borderRadius: '10px',
  padding: '2vh 1.5vw',
  minHeight: `calc(100vh - 98px)`,
  boxShadow: '0px 3px 4.5px rgba(0, 0, 0, 0.1)'
}));

//  template preview side
const RightSide = styled(Box)(() => ({
  backgroundColor: '#ffffff',
  // borderRadius: '10px',
  width: '400px',
  position: 'sticky',
  top: '90px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  // padding: '15px 30px',
  boxShadow: '0px 3px 4.5px rgba(0, 0, 0, 0.1)'
}));

//  All error texts
const ErrorText = styled(Typography)`
  font-size: 13px;
  font-weight: 400;
  color: #ff2f2f;
`;

//  All button texts
const ButtonTextMAxCount = styled(Typography)`
  font-size: 11px;
  font-weight: 400;
  color: #a3a3a3;
`;

<Typography
  color="error"
  sx={{
    fontSize: '0.75rem',
    mt: '-5px',
    mb: '5px',
    textAlign: 'start'
  }}
>
  Please enter a valid URL
</Typography>;

const extractPlaceholders = (text) => {
  const regex = /{{(.*?)}}/g;
  const placeholders = [];
  let match;
  for (match = regex.exec(text); match !== null; match = regex.exec(text)) {
    placeholders.push(match[1]);
  }
  return placeholders;
};

const TemplateContent = ({
  custom,
  top,
  publishedForms,
  initialData,
  attribute
}) => {
  const options = publishedForms?.map((flow) => ({
    value: flow.flowId,
    label: flow.flowName
  }));
  // const classes = useStyles();
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(
    theme.breakpoints.up('sm') && theme.breakpoints.down('md')
  );

  //  Design and the steps for stepper function
  let steps = ['Basic Info', 'Content', 'Buttons'];

  const [currentTime, setCurrentTime] = useState('');
  const [buttonSection, setButtonSection] = useState('none');
  const [activeStep, setActiveStep] = useState(0);
  const [allNonExpiredMedia, setAllNonExpiredMedia] = useState([]);
  // first tab states
  const [templateName, setTemplateName] = useState('');
  const [category, setCategory] = useState('');
  const [language, setLanguage] = useState('');
  const [isTemplateNameEmpty, setIsTemplateNameEmpty] = useState(false);
  const [isCategoryEmpty, setIsCategoryEmpty] = useState(false);
  const [isLanguageEmpty, setIsLanguageEmpty] = useState(false);
  const [isTemplateNameValid, setIsTemplateNameValid] = useState(true);

  // Second tab states
  const [bodyContent, setBodyContent] = useState('');
  const [isBodyEmpty, setIsBodyEmpty] = useState(false);
  const [footerContent, setFooterContent] = useState('');
  const [contentData, setContentData] = useState({
    type: 'none',
    text: '',
    image: '',
    video: '',
    application: ''
  });
  console.log('contentData', contentData);
  // Thrid tab states
  // Call to action
  const [callToActionButtonTextOne, setCallToActionButtonTextOne] =
    useState('');
  const [callToActionButtonTextTwo, setCallToActionButtonTextTwo] =
    useState('');
  const [callToActionButtonTextThree, setCallToActionButtonTextThree] =
    useState('');
  const [callToActionButtonTextOneError, setCallToActionButtonTextOneError] =
    useState(false);
  const [callToActionButtonTextTwoError, setCallToActionButtonTextTwoError] =
    useState(false);
  const [
    callToActionButtonTextThreeError,
    setCallToActionButtonTextThreeError
  ] = useState(false);

  const [url, setUrl] = useState('');
  const [mobile, setMobile] = useState('');
  // const [mobileWithOutCountryCode, setMobileWithOutCountryCode] = useState('');

  const [mobileError, setMobileError] = useState(false);
  const [urlError, setUrlError] = useState(false);

  // quick replay
  const [buttonTextOne, setButtonTextOne] = useState('');
  const [buttonTextTwo, setButtonTextTwo] = useState('');
  const [buttonTextThree, setButtonTextThree] = useState('');

  //  Flow button
  const [buttonTextFlow, setButtonTextFlow] = useState('');
  const [selectedFlow, setSelectedFlow] = useState('');
  const [selectedFlowsList, setSelectedFlowsList] = useState([]);
  const [buttonTextFlowError, setButtonTextFlowError] = useState('');
  const [selectedFlowEmpty, setSelectedFlowEmpty] = useState(false);

  // states for saving variables from textheader and body
  const [bodyPlaceholders, setBodyPlaceholders] = useState([]);
  const [textPlaceholders, setTextPlaceholders] = useState([]);


  //if your are adding here also add in static file
  //@desc     
  //@author   Prateek
  //@date     2025-Feb-3
  const arrayOfStrings = [
    'name',
    'city',
    'mobile',
    'orderId',
    'items',
    'amount',
    'orderStatus',
    'documentLink'
  ];

  // For Variable dropdown
  const [bodyanchorEl, setBodyAnchorEl] = useState(null);
  const [attributeanchorEl, setAttributeAnchorEl] = useState(null);
  const [headeranchorEl, setHeaderAnchorEl] = useState(null);

  //  for Emogies
  const [chosenEmoji, setChosenEmoji] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const emojiButtonRef = useRef();
  const emojiPickerRef = useRef(null);

  // //Media selection
  const [openDialog, setOpenDialog] = useState(false);
  const [media, setMedia] = useState([]);
  const [selectedMediaId, setSelectedMediaId] = useState('');
  const [selectedMedia, setSelectedMedia] = useState('');
  const [updateUpload, setUpdateUpload] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  //  For carousel
  const [carouselHeaderData, setCarouselHeaderData] = useState([]);
  const [cards, setCards] = useState([]);
  const [activeCard, setActiveCard] = useState(0);
  const [openCarouselDialog, setOpenCarouselDialog] = useState(false);
  const [carouselMedia, setCarouselMedia] = useState([]);
  const [updateUploadCarousel, setUpdateUploadCarousel] = useState(false);

  const [selectedCarouselMedia, setSelectedCarouselMedia] = useState({});
  const [selectedCarouselMediaArray, setSelectedCarouselMediaArray] = useState(
    []
  );
  const [carouselMediaType, setCarouselMediaType] = useState('');

  // For allowing and hiding Premium feature
  const [isAllow, SetIsAllow] = useState(false);

  const [securityRecommendation, setSecurityRecommendation] = useState(false);
  const [expirationTime, setExpirationTime] = useState(5);
  const [copyCode, setCopyCode] = useState('Copy Code');

  //
  //

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        emojiPickerRef.current &&
        !emojiPickerRef.current.contains(event.target) &&
        emojiButtonRef.current &&
        !emojiButtonRef.current.contains(event.target)
      ) {
        setShowEmojiPicker(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [showEmojiPicker]);

  useEffect(() => {
    if (isAuthenticated) {
      // const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
      const userInfo = getDecryptedData('userInfo');
      if (userInfo.planName === 'Premium') {
        SetIsAllow(true);
      }
    }
  }, [isAuthenticated]);

  //  Set tabs according to category
  if (category && category == 'CAROUSEL') {
    steps = ['Basic Info', 'Cards'];
  }

  if (category && category == 'AUTHENTICATION') {
    steps = ['Basic Info', 'Buttons'];
  }

  //  To add first card by default

  //fetch all non expired media
  useEffect(() => {
    const fetchMedia = async () => {
      const response = await ApiCalls.listMedia();

      const recentMedia = response.data.filter((item) => !item.expired);
      setAllNonExpiredMedia(recentMedia);
      setUpdateUploadCarousel(false);
    };
    fetchMedia();
  }, [updateUploadCarousel]);

  // For emogies
  // const handleEmojiClick = (emojiData) => {
  //   const selectedEmoji = emojiData.emoji;
  //   setChosenEmoji(selectedEmoji);
  //   setBodyContent((prevMessage) => prevMessage + selectedEmoji);
  // };

  // handle emogies with the cursor posotion
  const handleEmojiClick = (emojiData) => {
    const selectedEmoji = emojiData.emoji;
    if (bodyTextFieldRef.current) {
      const inputElement = bodyTextFieldRef.current;
      const startPos = inputElement.selectionStart;
      const endPos = inputElement.selectionEnd;
      const currentValue = bodyContent;

      const updatedText =
        currentValue.substring(0, startPos) +
        selectedEmoji +
        currentValue.substring(endPos);

      setBodyContent(updatedText);

      // Update the cursor position after inserting the emoji
      setTimeout(() => {
        inputElement.selectionStart = inputElement.selectionEnd =
          startPos + selectedEmoji.length;
      }, 0);
    }
  };

  const handleBodyButtonClick = (event) => {
    setBodyAnchorEl(event.currentTarget);
  };
  const handleAttributeButtonClick = (event) => {
    setAttributeAnchorEl(event.currentTarget);
  };
  const handleAttributeMenuClose = () => {
    setAttributeAnchorEl(null);
  };
  const handleHeaderButtonClick = (event) => {
    setHeaderAnchorEl(event.currentTarget);
  };

  const handleBodyMenuClose = () => {
    setBodyAnchorEl(null);
  };

  const handleHeaderMenuClose = () => {
    setHeaderAnchorEl(null);
  };

  const bodyTextFieldRef = useRef(null);
  const headerTextFieldRef = useRef(null);

  const handleMenuBodyItemClick = (item) => {
    const variableWithBrackets = `{{${item}}}`;
    if (bodyTextFieldRef.current) {
      const inputElement = bodyTextFieldRef.current;
      const startPos = inputElement.selectionStart;
      const endPos = inputElement.selectionEnd;
      const currentValue = bodyContent;
      const updatedText =
        currentValue.substring(0, startPos) +
        variableWithBrackets +
        currentValue.substring(endPos);
        setBodyContent(updatedText);
      }
      handleBodyMenuClose()
  };
  const handleMenuAttribute = (item) => { };

  const handleMenuHeaderItemClick = (item) => {
    const variableWithBrackets = `{{${item}}}`;

    if (headerTextFieldRef.current) {
      const inputElement = headerTextFieldRef.current;
      const startPos = inputElement.selectionStart;
      const endPos = inputElement.selectionEnd;
      const currentValue = inputElement.value;
      const updatedText =
        currentValue.substring(0, startPos) +
        variableWithBrackets +
        currentValue.substring(endPos);

      setContentData((prevData) => ({
        ...prevData,
        text: updatedText
      }));
      handleHeaderMenuClose();
    }
  };

  const handleCategoryChange = (event) => {
    setIsCategoryEmpty(false);
    setSelectedMediaId('')
    setSelectedMedia('')
    //  Delete all state when the category changing
    setCallToActionButtonTextOne('');
    setCallToActionButtonTextTwo('');
    setCallToActionButtonTextThree('');
    setButtonTextFlow('');
    setSelectedFlow('');
    setSelectedFlowsList([]);
    setMobile('');
    setUrl('');
    setButtonTextOne('');
    setButtonTextTwo('');
    setButtonTextThree('');
    setMultipleButtons([]);
    SetMultipleCallToActionUrl([]);
    setBodyContent('');
    setContentData({
      type: 'none',
      text: '',
      image: '',
      video: '',
      application: ''
    });
    setFooterContent('');
    setCards([]);

    const selectedCategory = event.value;
    setCategory(selectedCategory);

    // if (selectedCategory === 'marketing') {
    //   setCallToActionButtonTextThree('STOP');
    // } else {
    setCallToActionButtonTextThree('');
    // }
  };

  const handleContentSelection = (contentType) => {

    if (custom ? custom : !initialData) {
      setContentData((prevData) => ({
        ...prevData,
        type: contentType,
        text: contentType === 'text' ? '' : prevData.text,
        image: contentType === 'image' ? null : prevData.image,
        video: contentType === 'video' ? null : prevData.video,
        application: contentType === 'application' ? null : prevData.application
      }));
    }
  };

  useEffect(() => {
    console.log('setting value to con', contentData);
    console.log("clear")
    // setSelectedMediaId('')
    // setSelectedMedia('')
    setContentData((prevData) => ({
      type: prevData.type,
      text: '',
      image: '',
      video: '',
      application: ''
    }));
  }, [contentData.type]);

  const handleNext = () => {
    if (activeStep === 0) {
      if (templateName.trim() === '') {
        setIsTemplateNameEmpty(true);
      } else {
        setIsTemplateNameEmpty(false);
      }

      if (category.trim() === '') {
        setIsCategoryEmpty(true);
      } else {
        setIsCategoryEmpty(false);
      }

      if (language.trim() === '') {
        setIsLanguageEmpty(true);
      } else {
        setIsLanguageEmpty(false);
      }
    }

    if (category !== 'CAROUSEL') {
      if (activeStep === 1) {
        if (bodyContent.trim() === '') {
          setIsBodyEmpty(true);
        } else {
          setIsBodyEmpty(false);
        }
      }
    }

    // Check if all required fields are filled
    if (
      (activeStep === 0 &&
        templateName.trim() !== '' &&
        category.trim() !== '' &&
        language.trim() !== '') ||
      (activeStep === 1 && bodyContent.trim() !== '')
    ) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  //  For show curent time on preview
  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const formattedTime = now.toLocaleTimeString();
      setCurrentTime(formattedTime);
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  //  For extract variables from textHeaders and body
  useEffect(() => {
    const newBodyPlaceholders = extractPlaceholders(bodyContent);
    const newTextPlaceholders = extractPlaceholders(contentData.text);

    setBodyPlaceholders(newBodyPlaceholders);
    setTextPlaceholders(newTextPlaceholders);
  }, [bodyContent, contentData.text]);

  // useEffect(() => {
  //   if (category === 'MARKETING' && buttonTextFlow == '') {
  //     setCallToActionButtonTextThree('STOP');
  //   }
  //   if (category === 'MARKETING' && buttonSection == 'flow') {
  //     setCallToActionButtonTextThree('');
  //   }
  // }, [category, buttonTextFlow, buttonSection]);

  const replaceWithSequentialNumbers = (content) => {
    let counter = 1;
    return content?.replace(/{{\w+}}/g, () => `{{${counter++}}}`);
  };

  //  Usage
  const updatedBodyContent = replaceWithSequentialNumbers(bodyContent);
  const updatedHeaderContent = replaceWithSequentialNumbers(contentData?.text);

  //  URL validation
  function isValidUrl(url) {
    const regex =
      /^(https?:\/\/)([a-z0-9\.-]+)\.([a-z]{2,6})([\/\w \.-]*)*\/?$/i;
    return regex.test(url);
  }

  const handlePhoneChange = (value) => {
    setMobileError(false);

    if (value === undefined || value.replace(/\D/g, '').length === 0) {
      // If the input is empty or only non-digit characters are present, clear the state.
      setMobile('');
    } else {
      // Otherwise, update the state with the new value.
      setMobile(value);
    }
  };

  //  For to validate carousel
  // const validateCarousel = () => {
  //   const errors = cards.map((card, index) => {
  //     const cardErrors = {
  //       headerError: !card.headerContent,
  //       bodyError: !card.body.trim(),
  //       buttonsError: card.buttons.length === 0 || card.buttons.some(button => (button.type === 'quick_reply' && !button.text.trim())|| (button.type === 'url' && !button.url.trim()) || (button.type === 'phone_number' && !button.phone_number.trim()))
  //     };
  //     return cardErrors;
  //   });

  //   return errors;
  // };
  const validateCarousel = () => {
    let errorMessages = [];
    console.log("cards", cards)
    cards.forEach((card, index) => {
      let cardErrors = [];

      if (!card.headerContent) {
        console.log('2')

        cardErrors.push('header');
      }
      if (!card.body.trim()) {
        console.log('3')

        cardErrors.push('content');
      }
      if (
        card.buttons.length === 0 ||
        card.buttons.some(
          (button) =>
            (button.type === 'quick_reply' && !button.text.trim()) ||
            (button.type === 'url' && !button.url.trim()) ||
            (button.type === 'phone_number' && !button.phone_number.trim())
        )
      ) {
        console.log('4')

        cardErrors.push('buttons');
      }

      if (cardErrors.length > 0) {
        console.log('5')
        errorMessages.push(`Card ${index + 1}: ${cardErrors.join(', ')}`);
      }
    });

    return errorMessages;
  };
  const [multipleButtons, setMultipleButtons] = useState([]);
  const [multipleCallToActionUrl, SetMultipleCallToActionUrl] = useState([]);
  const { businessProfileData } = useSelector((state) => state.wapp);

  const handleFinalSubmit = async () => {
    setSubmitLoading(true);
    setMobileError(false);
    setUrlError(false);
    setCallToActionButtonTextOneError(false);
    setCallToActionButtonTextTwoError(false);
    setCallToActionButtonTextThreeError(false);

    setSelectedFlowEmpty(false);
    setButtonTextFlowError('');

    if (bodyContent && bodyContent.trim() === '') {
      setIsBodyEmpty(true);
      setSubmitLoading(false);
      return;
    } else {
      setIsBodyEmpty(false);
    }

    if (category === 'CAROUSEL') {
      const carouselErrorMessages = validateCarousel();
      if (carouselErrorMessages.length > 0) {
        setSubmitLoading(false);
        Toast({
          type: 'error',
          message:
            'Please complete the following fields in your carousel cards:\n' +
            carouselErrorMessages.join('; ')
        });
        return;
      }
    }

    if (callToActionButtonTextOne && !isValidUrl(url)) {
      setUrlError(true);
      return;
    }

    if (url && !callToActionButtonTextOne) {
      setCallToActionButtonTextOneError(true);
      return;
    }

    const formattedMobile = mobile?.replace('+', ''); // Removes the '+' sign

    if (callToActionButtonTextTwo && !isValidPhoneNumber(mobile)) {
      setMobileError(true);
      return;
    }

    if (formattedMobile && !callToActionButtonTextTwo) {
      setCallToActionButtonTextTwoError(true);
      return;
    }

    if (buttonSection == 'flow' && buttonTextFlow == '') {
      setButtonTextFlowError('Please fill flow button text');
      return;
    }

    if (buttonSection == 'flow' && selectedFlow == '') {
      setSelectedFlowEmpty(true);
      return;
    }

    setSubmitLoading(true);
    let response;
    if (initialData?._id) {
      response = await TemplateApiCalls.updateTextTemplate(
        // console.log(

        templateName,
        updatedHeaderContent,
        updatedBodyContent,
        category,
        language,
        contentData,
        footerContent,
        buttonTextOne.trim(),
        buttonTextTwo.trim(),
        buttonTextThree,
        callToActionButtonTextOne.trim(),
        callToActionButtonTextTwo.trim(),
        callToActionButtonTextThree,
        url,
        formattedMobile,
        textPlaceholders,
        bodyPlaceholders,
        selectedMediaId,
        buttonTextFlow,
        selectedFlow,
        cards,
        carouselHeaderData,
        securityRecommendation,
        expirationTime,
        copyCode,
        initialData?._id,
        multipleButtons,
        multipleCallToActionUrl,

      );
    } else {
      response = await TemplateApiCalls.createTextTemplate(
        // console.log(
        templateName,
        updatedHeaderContent,
        updatedBodyContent,
        category,
        language,
        contentData,
        footerContent,
        buttonTextOne.trim(),
        buttonTextTwo.trim(),
        buttonTextThree,
        callToActionButtonTextOne.trim(),
        callToActionButtonTextTwo.trim(),
        callToActionButtonTextThree,
        url,
        formattedMobile,
        textPlaceholders,
        bodyPlaceholders,
        selectedMediaId,
        buttonTextFlow,
        selectedFlow,
        cards,
        carouselHeaderData,
        securityRecommendation,
        expirationTime,
        copyCode,
        multipleButtons,
        multipleCallToActionUrl,
        businessProfileData?.phone_number_id
      );
    }
    if (response?.status === 200) {
      setSubmitLoading(false);
      if (response?.error) {
        if (response.data.error.error_user_msg) {
          Toast({
            type: 'error',
            message: response?.data?.error.error_user_msg
          });
        } else {
          Toast({
            type: 'error',
            message: response?.data?.error.message
          });
        }
      } else {
        Toast({ type: 'success', message: response?.message });
        navigate('/templates');
      }
    } else {
      setSubmitLoading(false);
      Toast({ type: 'error', message: response?.message });
    }
  };

  // useEffect(() => {
  //   const handleBodyClick = (event) => {
  //     // Check if the click occurred outside the EmojiPicker and Emoji button
  //     if (
  //       !emojiButtonRef.current?.contains(event.target) &&
  //       event.target.tagName === 'EmojiPicker' // Assuming EmojiPicker renders an IMG tag
  //     ) {
  //       setShowEmojiPicker(false);
  //     }
  //   };
  //   document.body.addEventListener('mousedown', handleBodyClick);
  //   // Cleanup function to remove the event listener when the component is unmounted
  //   return () => {
  //     document.body.removeEventListener('mousedown', handleBodyClick);
  //   };
  // }, []);

  // Media selection
  const handleConfirmMedia = () => {
    switch (contentData.type) {
      case 'image':
        setContentData((prevData) => ({
          ...prevData,
          image: selectedMedia
        }));
        break;
      case 'video':
        setContentData((prevData) => ({
          ...prevData,
          video: selectedMedia
        }));
        break;
      case 'application':
        setContentData((prevData) => ({
          ...prevData,
          application: selectedMedia
        }));
        break;
      default:
        break;
    }
    // Close the dialog box
    setOpenDialog(false);
  };

  // for media selecting
  const selectMedia = async () => {
    setOpenDialog(true);
    const response = await ApiCalls.listMedia();

    if (response?.status === 200) {
      // setMedia(response?.data);
      const recentMedia = response.data.filter((item) => !item.expired);
      setMedia(recentMedia);
    }
  };

  useEffect(() => {
    if (updateUpload) {
      selectMedia();
      setUpdateUpload(false);
    }
  }, [updateUpload]);

  const filteredMedia = media?.filter((mediaItem) => {
    return (
      mediaItem.media_type.toLowerCase() === contentData.type.toLowerCase()
    );
  });

  //  select id of an media
  const handleMediaSelection = (media) => {
    // Set the selected media ID
    if (category === 'CAROUSEL') {
      updateCard(activeCard, 'headerContent', media?.header_handle);
      return;
    }

    setSelectedMediaId(media?._id);
    setSelectedMedia(media);
  };

  //  For add media
  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    const formData = new FormData();
    formData.append('file', selectedFile);

    ApiCalls.sendMediaOld(formData)
      .then((response) => {
        Toast({ type: 'success', message: 'Media uploaded successfully' });
        setUpdateUpload(true);
      })
      .catch((error) => {
        Toast({ type: 'error', message: 'Media uploading failed' });
      });
  };

  //  For add media
  const handleFileChangeCarousel = async (event) => {
    const selectedFile = event.target.files[0];
    const formData = new FormData();
    formData.append('file', selectedFile);

    ApiCalls.sendMediaOld(formData)
      .then((response) => {
        Toast({ type: 'success', message: 'Media uploaded successfully' });
        setUpdateUploadCarousel(true);
      })
      .catch((error) => {
        Toast({ type: 'error', message: 'Media uploading failed' });
      });
  };

  // For button group selection call to action/ quick replay
  const handleButtonClick = (section) => {
    setButtonSection(section);
    setCallToActionButtonTextOne('');
    setCallToActionButtonTextTwo('');
    setButtonTextFlow('');
    setSelectedFlow('');
    setSelectedFlowsList([]);
    setMobile('');
    setUrl('');
    setButtonTextOne('');
    setButtonTextTwo('');
    setMultipleButtons([]);
    SetMultipleCallToActionUrl([]);
    setButtonTextThree('');
  };

  //  Data for template preview
  const constructTemplateHeader = () => {
    switch (contentData.type) {
      case 'text':
        if (contentData.text) {
          return { type: contentData.type, value: contentData.text };
        }
        break;
      case 'image':
      case 'video':
      case 'application':
        if (
          contentData[contentData.type] &&
          contentData[contentData.type].media_url
        ) {
          return {
            type: contentData.type,
            value: contentData[contentData.type].media_url
          };
        }
        break;
    }
  };

  // Function to generate the buttons array for preview
  const generateButtonsArray = () => {
    const buttons = [];

    if (buttonTextOne) {
      buttons.push({
        type: 'QUICK_REPLY',
        text: buttonTextOne
      });
    }

    if (buttonTextTwo) {
      buttons.push({
        type: 'QUICK_REPLY',
        text: buttonTextTwo
      });
    }

    if (buttonTextThree) {
      buttons.push({
        type: 'QUICK_REPLY',
        text: buttonTextThree
      });
    }

    if (callToActionButtonTextOne) {
      buttons.push({
        type: 'URL',
        text: callToActionButtonTextOne,
        url: url
      });
    }

    if (callToActionButtonTextTwo) {
      buttons.push({
        type: 'PHONE_NUMBER',
        text: callToActionButtonTextTwo,
        phone_number: mobile
      });
    }

    if (callToActionButtonTextThree) {
      buttons.push({
        type: 'QUICK_REPLY',
        text: callToActionButtonTextThree
      });
    }

    if (buttonTextFlow) {
      buttons.push({
        type: 'QUICK_REPLY',
        text: buttonTextFlow
      });
    }
    if (multipleCallToActionUrl.length > 0) {
      buttons.push(...multipleCallToActionUrl);
    }
    if (multipleButtons.length > 0) {
      buttons.push(...multipleButtons);
    }

    return buttons;
  };

  // constructing carousel object for preview
  const generateCarouselArray = () => {
    return cards.map((card, index) => ({
      carousel_header: {
        type: carouselHeaderData[index]?.type,
        id: carouselHeaderData[index]?.id,
        url: carouselHeaderData[index]?.URL
      },
      carousel_body: card.body,
      carousel_buttons: card.buttons.map((button) => ({
        type: button.type.toUpperCase(),
        text: button.text,
        ...(button.url && { url: button.url }),
        ...(button.phone_number && { phone_number: button.phone_number })
      }))
    }));
  };

  // Constructing the data object
  const data = {
    template_header: constructTemplateHeader(),
    template_body: bodyContent,
    template_footer: footerContent,
    template_buttons: generateButtonsArray(),
    carousel: generateCarouselArray()
  };
  console.log('data', data);

  //  For card creation
  const addCard = () => {
    if (cards.length < 10) {
      // Check the header type and content of the first card to apply to new cards
      const firstCardHeaderType = cards.length === 0 ? '' : cards[0].headerType;
      // const firstCardHeaderContent = cards.length === 0 ? '' : cards[0].headerContent;
      // Clone the button structure from the first card if it exists
      const firstCardButtons =
        cards.length > 0 && cards[0].buttons.length > 0
          ? cards[0].buttons.map((button) => ({
            type: button.type,
            text: '', // Reset text for new card buttons
            url: '', // Reset URL for new card buttons
            phone_number: '' // Reset phone number for new card buttons
          }))
          : []; // Default to an empty array if no buttons are defined
      setCards([
        ...cards,
        {
          headerType: firstCardHeaderType,
          headerContent: '',
          body: '',
          buttons: firstCardButtons
        }
      ]);
      setActiveCard(cards.length);
    }
  };
  const removeCard = (index) => {
    if (cards.length < 10) {
      console.log(cards.filter((_, i) => i !== index)
      )
      setCards([
        ...cards.filter((_, i) => i !== index)

      ]);
      setSelectedCarouselMediaArray([
        ...selectedCarouselMediaArray.filter((_, i) => i !== index)

      ]);
      setCarouselHeaderData([
        ...carouselHeaderData.filter((_, i) => i !== index)

      ]);
      setActiveCard(0);
    }
  };

  const updateCard = (index, field, value) => {
    const updatedCards = cards.map((card, i) =>
      i === index ? { ...card, [field]: value } : card
    );
    setCards(updatedCards);
  };

  const addButton = (index, buttonType) => {
    const updatedCards = cards.map((card, i) => {
      if (i === index && card.buttons.length < 2) {
        return {
          ...card,
          buttons: [...card.buttons, { type: buttonType, text: '', url: '' }]
        };
      }
      return card;
    });
    setCards(updatedCards);
    //   const updatedCards = cards.map((card, index) => {
    //   const updatedButtons = card.buttons.map(button => ({
    //     ...button,
    //     type: buttonType,
    //     text: '',
    //     url: '',
    //     phone_number: ''
    //   }));
    //   return { ...card, buttons: updatedButtons };
    // });
    // setCards(updatedCards);
  };

  const updateButtonContent = (cardIndex, buttonIndex, field, value) => {
    const updatedCards = cards.map((card, i) => {
      if (i === cardIndex) {
        const updatedButtons = card.buttons.map((button, j) =>
          j === buttonIndex ? { ...button, [field]: value } : button
        );
        return { ...card, buttons: updatedButtons };
      }
      return card;
    });
    setCards(updatedCards);
  };

  const handleCarouselMediaSelection = (type) => {
    const updatedCards = cards.map((card, index) => {
      return index === 0
        ? { ...card, headerType: type }
        : { ...card, headerType: type, headerContent: '' };
    });
    setCards(updatedCards);
  };

  // for carousel media selecting
  const selectMediaForCarousel = async (type) => {
    const recentMedia = allNonExpiredMedia.filter(
      (item) => item.media_type === type
    );
    setCarouselMedia(recentMedia);
    setCarouselMediaType(type);
    setCarouselMediaType(type);
    setOpenCarouselDialog(true);
  };

  const handleCarouselMediaSelect = (media) => {
    setSelectedCarouselMedia({
      media_name: media.media_name,
      media_url: media.media_url,
      _id: media._id,
      header_handle: media.header_handle,
      media_type: media.media_type
    });
  };

  const handleCarouselConfirmMedia = () => {
    updateCard(
      activeCard,
      'headerContent',
      selectedCarouselMedia.header_handle
    );

    const newHeaderData = {
      type: selectedCarouselMedia.media_type,
      id: selectedCarouselMedia._id,
      URL: selectedCarouselMedia.media_url
    };

    // Update the carouselHeaderData array
    const updatedCarouselHeaderData = [...carouselHeaderData];
    updatedCarouselHeaderData[activeCard] = newHeaderData;
    setCarouselHeaderData(updatedCarouselHeaderData);

    const updatedCarouselMediaArray = [...selectedCarouselMediaArray];
    updatedCarouselMediaArray[activeCard] = selectedCarouselMedia;
    setSelectedCarouselMediaArray(updatedCarouselMediaArray);

    setOpenCarouselDialog(false);
    setSelectedCarouselMedia({});
  };
  const getButtonType = (buttons) => {
    // Check if there's any QUICK_REPLY button
    const hasFlow = buttons?.some((button) => button.type === 'FLOW');
    const hasQuickReply = buttons?.some(
      (button) => button.type === 'QUICK_REPLY'
    );

    // Check if there's any PHONE_NUMBER or URL button
    const hasCallToAction = buttons?.some(
      (button) => button.type === 'PHONE_NUMBER' || button.type === 'URL'
    );

    // Return based on conditions

    if (hasFlow) {
      return 'flow';
    } else if (hasCallToAction) {
      return 'callToAction';
    } else if (hasQuickReply) {
      return 'quickReplay';
    } else {
      return 'none';
    }
  };
  useEffect(() => {
    if (initialData) {
      setLanguage(initialData?.language);
      setTemplateName(initialData?.template_name);
      setBodyContent(initialData?.template_body);
      if(initialData?.carousel?.length){
setCategory("CAROUSEL")
const cardsIntance=initialData?.carousel?.map((item)=>{
  return {
  headerType: item.carousel_header?.type,
  headerContent:allNonExpiredMedia.filter((it)=>it._id===item.carousel_header?.id)[0]?.header_handle ,
  body: item.carousel_body,
  buttons: item.carousel_buttons.map((button) => ({
    type: button?.type,
    text: button?.text,
   ...(button?.url && { url: button?.url }),
   ...(button?.phone_number && { phone_number: button?.phone_number })
  }))

}}
)
setCards([...cardsIntance])
const carouselHeaderDataIntance=initialData?.carousel?.map((item)=>
  {
    return{
      type: item.carousel_header?.type,
      id: item.carousel_header?.id,
      URL: item.carousel_header?.url
    }
  })
setCarouselHeaderData([
  ...carouselHeaderDataIntance
])


const selectedCarouselMediaArraInstance = [];

carouselHeaderDataIntance?.forEach((item) => {
  const matchedMedia = allNonExpiredMedia.find((med) => item.id === med._id);
  if (matchedMedia) {
    selectedCarouselMediaArraInstance.push({
      media_name: matchedMedia.media_name,
      media_url: matchedMedia.media_url,
      _id: matchedMedia._id,
      header_handle: matchedMedia.header_handle,
      media_type: matchedMedia.media_type
    });
  }
});




setSelectedCarouselMediaArray([...selectedCarouselMediaArraInstance])
      }else{

        setCategory(initialData?.category);
   
      setFooterContent(initialData?.template_footer);

      if (initialData?.template_header?.type === 'text') {
        setContentData({
          type: 'text',
          text: initialData?.template_header?.value,
          image: '',
          video: '',
          application: ''
        });
      } else if (
        initialData?.template_header?.type === 'image' ||
        initialData?.template_header?.type === 'video' ||
        initialData?.template_header?.type === 'document'
      ) {
        let img = '';
        let video = '';
        let application = '';
        if (initialData?.template_header?.type === 'image') {
          img = allNonExpiredMedia.filter(
            (item) => item?.media_url === initialData?.template_header?.value
          )[0];

          setContentData({
            type: 'image',
            text: '',
            image: img,
            video: video,
            application: application
          });
          setSelectedMedia(img);
          setSelectedMediaId(img?._id);
        } else if (initialData?.template_header?.type === 'video') {
          video = allNonExpiredMedia.filter(
            (item) => item?.media_url === initialData?.template_header?.value
          )[0];

          setContentData({
            type: 'video',
            text: '',
            image: img,
            video: video,
            application: application
          });
          setSelectedMedia(video);
          setSelectedMediaId(video?._id);
        } else if (initialData?.template_header?.type === 'document') {
          application = allNonExpiredMedia.filter(
            (item) => item?.media_url === initialData?.template_header?.value
          )[0];

          setContentData({
            type: 'application',
            text: '',
            image: img,
            video: video,
            application: application
          });
          setSelectedMedia(application);
          setSelectedMediaId(application?._id);
        }
      }

      if (initialData?.template_buttons?.length > 0) {
        const step3State = getButtonType(initialData?.template_buttons);
        setButtonSection(step3State);

        if (step3State === 'callToAction') {
          const forUrl = initialData?.template_buttons?.filter(
            (item) => item.type === 'URL'
          );
          const forPhone = initialData?.template_buttons?.filter(
            (item) => item.type === 'PHONE_NUMBER'
          )[0];

          if (forPhone) {
            setCallToActionButtonTextTwo(forPhone?.text);
            setMobile(`+${forPhone?.phone_number}`);
          }
          if (forUrl) {
            SetMultipleCallToActionUrl(forUrl);
          }

          const forQuick = initialData?.template_buttons?.filter(
            (item) => item.type === 'QUICK_REPLY'
          );

          setMultipleButtons(forQuick);
        } else if (step3State === 'quickReplay') {
          const forQuick = initialData?.template_buttons?.filter(
            (item) => item.type === 'QUICK_REPLY'
          );

          setMultipleButtons(forQuick);
        } else if (step3State === 'flow') {
          setButtonTextFlow(initialData?.template_buttons[0].text);
          setSelectedFlow(initialData?.template_buttons[0].flow_id),
            setSelectedFlowEmpty(false);
          const flowInit = options.filter(
            (item) => item.value === initialData?.template_buttons[0].flow_id
          )[0];
          setSelectedFlowsList(flowInit);
        }
      }
    }
    }
  }, [initialData, allNonExpiredMedia]);

  const generateTemplate = () => {
    const carouselData = {
      type: 'CAROUSEL',
      cards: cards.map((card) => ({
        components: [
          {
            type: 'HEADER',
            format: card.headerType.toUpperCase(),
            example: {
              header_handle: [card.headerContent]
            }
          },
          {
            type: 'BODY',
            text: card.body
          },
          {
            type: 'BUTTONS',
            buttons: card.buttons.map((button) => ({
              type: button.type.toUpperCase().replace(' ', '_'),
              text: button.text,
              ...(button.type === 'url' && { url: button.url }),
              ...(button.type === 'phone_number' && {
                phone_number: button.phone_number
              })
            }))
          }
        ]
      }))
    };
  };
  const handleTextFormatting = (textFieldRef, formattingChars) => {
    const inputElement = textFieldRef.current;

    if (inputElement) {
      const start = inputElement.selectionStart;
      const end = inputElement.selectionEnd;

      const selectedText = bodyContent.slice(start, end);
      const beforeText = bodyContent.slice(0, start);
      const afterText = bodyContent.slice(end);

      const formattedText = `${beforeText}${formattingChars}${selectedText}${formattingChars}${afterText}`;

      setBodyContent(formattedText);

      // Reset the cursor position after adding the formatting
      setTimeout(() => {
        inputElement.selectionStart = start + formattingChars.length;
        inputElement.selectionEnd = end + formattingChars.length;
        inputElement.focus();
      }, 0);
    }
  };
  const handleAttributeChange = (number, value) => {
    console.log('number', number, 'to', value, '===', bodyContent);

    // const regExp = new RegExp(placeholder, 'g');
    setBodyContent(bodyContent.replace(`{{${number}}}`, `{{${value}}}`));
  };
  const [dynamicArray, setDynamicArray] = useState([]);
  useEffect(() => {
    if (bodyContent.length > 0) {
      const matches = bodyContent?.match(/{{(\d+)}}/g) || [];

      if (matches) {
        setDynamicArray(
          matches.map((match) => {
            const key = match.replace(/{{|}}/g, ''); // Remove {{ }}
            return { key, value: '' }; // Assign mapped value or empty string
          })
        );
      }
    }
  }, [bodyContent]);
  const languageOptions = [
    { value: 'en', label: 'English' },
    { value: 'en_US', label: 'English (US)' },
    { value: 'en_GB', label: 'English (UK)' },
    { value: 'hi', label: 'Hindi' },
    { value: 'mr', label: 'Marathi' },
    { value: 'gu', label: 'Gujarati' },
    { value: 'bn', label: 'Bengali' },
    { value: 'kn', label: 'Kannada' },
    { value: 'ml', label: 'Malayalam' },
    { value: 'ta', label: 'Tamil' },
    { value: 'pa', label: 'Punjabi' },
    { value: 'ar', label: 'Arabic' },
    { value: 'fr', label: 'French' },
    { value: 'de', label: 'German' },
    { value: 'es', label: 'Spanish' },
    { value: 'ru', label: 'Russian' },
    { value: 'ja', label: 'Japanese' },
    { value: 'ko', label: 'Korean' },
    { value: 'it', label: 'Italian' },
    { value: 'zh_CN', label: 'Chinese (CHN)' }
  ];

  const categoryOptions = [
    { value: 'MARKETING', label: 'Marketing' },
    { value: 'UTILITY', label: 'Utility' },
    { value: 'AUTHENTICATION', label: 'Authentication' },
    ...(isAllow ? [{ value: 'CAROUSEL', label: 'Carousel' }] : [])
  ];

  let templateTabs = [
    { id: 'none', label: 'None', icon: noneIcon },
    { id: 'text', label: 'Text', icon: textIcon },
    { id: 'image', label: 'Image', icon: imageButton },
    { id: 'video', label: 'Video', icon: videoButton },
    { id: 'application', label: 'Document', icon: documentButton }
  ];
  const [filteredTabs, setFilteredTabs] = useState(templateTabs); // Initialize state with templateTabs

  useEffect(() => {
    if (category === 'UTILITY') {
      setFilteredTabs(templateTabs.filter((tab) => tab));
    } else {
      setFilteredTabs(templateTabs); // Reset to original tabs if the category changes
    }

    if (category === 'CAROUSEL' && cards.length === 0) {
      addCard();
    }
  }, [category]);

  return (<>
  
    <Grid
      container
      justifyContent="center"
      spacing={isMobile ? 0 : isTablet ? 1 : 2}
      zIndex={999}
    >
      {/* <Button onClick={()=>{
        console.log("initial data",initialData)
        console.log("cxard",cards)
        console.log("selectedCarouselMediaArray",selectedCarouselMediaArray)
        console.log("carouselHeaderData",carouselHeaderData)
        console.log("selectedCarouselMedia",selectedCarouselMedia)
      }}>
        hello
      </Button> */}
      {/* Left section */}
      <Grid item xs={12} sm={8} justifyContent="flex-start">
        <LeftSide>
          <Box>
            {/* Stepper component */}
            {/* <Typography
              sx={{ fontSize: '16px', fontWeight: 500, marginTop: '8px' }}
            >
              New Template
            </Typography> */}
            {/* <Box sx={{ zIndex: 100 }}>
              <Stepper
                sx={{
                  marginLeft: '-12%',
                  // backgroundColor: 'white',
                  pl: { xs: '10px' },
                  bgcolor: 'transparent',
                  zIndex: 50
                }}
                alternativeLabel
                activeStep={activeStep}
                connector={<QontoConnector />}
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={QontoStepIcon}>
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box> */}
            <Box
              sx={{
                // overflowY: 'auto',
                // maxHeight: `calc(99vh - 260px)`
                maxHeight: 'fit-content'
              }}
            >
              <Box mt={1}>
                {
                  <Box>
                    <MainHeadings>Basic Info</MainHeadings>
                    <Grid container spacing={3} rowSpacing={1}>
                      {/* Template Name Section */}
                      <Grid item xs={12} md={4} sx={{ zIndex: '99999' }}>
                        <Headings>
                          Template Name{' '}
                          <Tooltip
                            title="Name can only have lowercase letters, numbers and underscores.
                            This template name can be anything that helps you identify it
                            when sending template messages. This will not be showing to
                            your customers, Eg: For marketing template about stock
                            clearance sale, you can name the template stock_clearance_sale."
                            arrow
                            placement="right"
                          >
                            <span
                              style={{
                                position: 'relative',
                                display: 'inline-block',
                                verticalAlign: 'middle' // This helps center the icon vertically
                              }}
                            >
                              <img
                                src={infoIcon}
                                alt="Action Required Icon"
                                style={{
                                  verticalAlign: 'middle',
                                  marginLeft: '5px',
                                  cursor: 'pointer'
                                }}
                              />
                            </span>
                          </Tooltip>
                        </Headings>

                        <CustomTextField
                          placeholder="Template Name"
                          value={templateName}
                          multiline
                          maxRows={5}
                          onChange={(event) => {
                            let inputValue = event.target.value;
                            inputValue = inputValue
                              .replace(/\s+/g, '_')
                              .toLowerCase();
                            const isValidInput = /^[a-z0-9_]*$/.test(
                              inputValue
                            );

                            if (inputValue.length <= 512 && isValidInput) {
                              setTemplateName(inputValue);
                              setIsTemplateNameValid(true);
                              setIsTemplateNameEmpty(false);
                            } else {
                              setIsTemplateNameValid(false);
                              setTimeout(
                                () => setIsTemplateNameValid(true),
                                1000
                              );
                            }
                          }}
                          required
                          helperText={
                            isTemplateNameEmpty ? (
                              <span style={{ color: 'red', fontWeight: 400 }}>
                                Template Name is required
                              </span>
                            ) : !isTemplateNameValid ? (
                              <ErrorText>
                                Name can only have lowercase letters, numbers,
                                and underscores.
                              </ErrorText>
                            ) : (
                              ``
                            )
                          }
                          InputProps={{
                            readOnly: custom ? !custom : initialData,
                            disabled: custom ? !custom : initialData,
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                style={{
                                  position: 'absolute',
                                  bottom: 10,
                                  right: 5
                                }}
                              >
                                <ButtonTextMAxCount>
                                  {templateName.length}/512
                                </ButtonTextMAxCount>
                              </InputAdornment>
                            )
                          }}
                          sx={{ width: '100%' }}
                        />
                      </Grid>

                      {/* Template Category Section */}
                      <Grid
                        item
                        xs={12}
                        md={4}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          position: 'relative',
                          justifyContent: 'flex-end',
                          zIndex: '3'
                        }}
                      >
                        <Headings>Template Category</Headings>
                        <CustomSelect
                          value={
                            categoryOptions.find(
                              (option) => option.value === category
                            ) || null
                          }
                          isDisabled={custom ? !custom : initialData}
                          onChange={(selectedOption) =>
                            handleCategoryChange(selectedOption)
                          }
                          options={categoryOptions}
                          placeholder="Select Category"
                          styles={customStylesReactSelect}
                          menuPosition="fixed"
                          menuPlacement="auto"
                        />

                        {isCategoryEmpty && (
                          <ErrorText
                            style={{
                              marginLeft: '8px'
                            }}
                          >
                            Please select a category
                          </ErrorText>
                        )}
                      </Grid>

                      {/* Template Language Section */}
                      <Grid
                        item
                        xs={12}
                        md={4}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          position: 'relative',
                          justifyContent: 'flex-end',
                          zIndex: '2'
                        }}
                      >
                        <Headings sx={{ marginTop: '0px' }}>
                          Template Language
                        </Headings>
                        <CustomSelect
                          value={
                            languageOptions.find(
                              (option) => option.value === language
                            ) || null
                          }
                          isDisabled={custom ? !custom : initialData}
                          onChange={(selectedOption) => {
                            setLanguage(selectedOption?.value || '');
                            setIsLanguageEmpty(false);
                          }}
                          options={languageOptions}
                          placeholder="Select Language"
                          styles={customStylesReactSelect}
                          menuPosition="fixed"
                          menuPlacement="auto"
                        />

                        {isLanguageEmpty && (
                          <ErrorText
                            style={{
                              marginLeft: '8px'
                            }}
                          >
                            Please select a language
                          </ErrorText>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                }

                {category !== 'CAROUSEL' && category !== 'AUTHENTICATION' && (
                  <div>
                    <MainHeadings>Content</MainHeadings>
                    <SecondPageHeadings>
                      Header{' '}
                      <Tooltip
                        title="Please add a title to your message. You can choose from text, image, video, or document."
                        arrow
                        sx={{
                          color: '#7A7A7A',
                          fontSize: '12px',
                          backgroundColor: '#ffffff',
                          width: '300px',
                          padding: '10px',
                          borderRadius: '4px'
                        }}
                      >
                        <span
                          style={{
                            position: 'relative',
                            display: 'inline-block'
                          }}
                        >
                          <img
                            src={infoIcon}
                            alt="Action Required Icon"
                            style={{ marginLeft: '5px', cursor: 'pointer' }}
                          />
                        </span>
                      </Tooltip>
                    </SecondPageHeadings>
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '2vw',
                        mb: '10px'
                        // bgcolor: 'red'
                      }}
                      style={{
                        overflowX: 'auto',
                        overflowY: 'hidden',
                        whiteSpace: 'nowrap'
                      }}
                    >
                      {filteredTabs?.map((item) => (
                        <CreateTabTemplate
                          contentData={contentData}
                          handleContentSelection={handleContentSelection}
                          item={item}
                        />
                      ))}
                    </Box>

                    {/* Render content fields based on selected content */}
                    {contentData.type === 'text' && (
                      <Box
                        display="flex"
                        alignItems="flex-end"
                        flexDirection="column"
                      >
                        <CustomTextField
                          placeholder="Add Text"
                          fullWidth
                          value={contentData.text}
                          maxRows={2}
                          inputRef={headerTextFieldRef}
                          onChange={(event) => {
                            const newText = event.target.value;
                            if (newText.length <= 60) {
                              setContentData((prevData) => ({
                                ...prevData,
                                text: newText
                              }));
                            }
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                style={{
                                  position: 'absolute',
                                  bottom: 10,
                                  right: 5
                                }}
                              >
                                <ButtonTextMAxCount>
                                  {contentData.text?.length}/60
                                </ButtonTextMAxCount>
                              </InputAdornment>
                            )
                          }}
                        />
                        {/* Add a Select dropdown for the array of strings */}
                        {/* Button-like structure on the right */}

                        <StyledText
                          sx={{ marginTop: '5px' }}
                          onClick={handleHeaderButtonClick}
                        >
                          <img
                            src={variableIcon}
                            width={'30px'}
                            height={'30px'}
                          />
                        </StyledText>

                        {/* Dropdown menu */}
                        <Menu
                          id="variable-menu"
                          anchorEl={headeranchorEl}
                          open={Boolean(headeranchorEl)}
                          onClose={handleHeaderMenuClose}
                        >
                          {arrayOfStrings.map((item, index) => (
                            <MenuItem
                              key={index}
                              onClick={() => handleMenuHeaderItemClick(item)}
                            >
                              {item}
                            </MenuItem>
                          ))}
                        </Menu>
                      </Box>
                    )}

                    {contentData.type === 'image' && (
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignContent="space-between"
                        alignItems="center"
                      >
                        {/* <StyledText onClick={selectMedia}>
                          Choose image
                        </StyledText>
                        <Typography>
                          {contentData?.image?.media_name}
                        </Typography> */}
                        <Box
                          sx={{
                            display: 'flex',
                            alignContent: 'center',
                            gap: '5px',
                            bgcolor: '#befcc5',
                            p: '5px',
                            borderRadius: '6px',
                            cursor: 'pointer',
                            mb: '10px'
                          }}
                          onClick={selectMedia}
                        >
                          <Typography sx={{ color: '#343434' }}>
                            Choose Image
                          </Typography>
                          <img
                            src={addIcon}
                            style={{
                              filter:
                                'brightness(0) saturate(100%) invert(42%) sepia(41%) saturate(959%) hue-rotate(63deg) brightness(102%) contrast(98%)'
                            }}
                          />
                        </Box>
                        <Typography>
                          {contentData?.image?.media_name}
                        </Typography>
                      </Box>
                    )}

                    {contentData.type === 'video' && (
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignContent="space-between"
                        alignItems="center"
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignContent: 'center',
                            gap: '5px',
                            bgcolor: '#befcc5',
                            p: '5px',
                            borderRadius: '6px',
                            cursor: 'pointer',
                            mb: '10px'
                          }}
                          onClick={selectMedia}
                        >
                          <Typography sx={{ color: '#343434' }}>
                            Choose Video
                          </Typography>
                          <img
                            src={addIcon}
                            style={{
                              filter:
                                'brightness(0) saturate(100%) invert(42%) sepia(41%) saturate(959%) hue-rotate(63deg) brightness(102%) contrast(98%)'
                            }}
                          />
                        </Box>
                        <Typography>
                          {contentData?.video?.media_name}
                        </Typography>
                      </Box>
                    )}
                    {contentData.type === 'application' && (
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignContent="space-between"
                        alignItems="center"
                      >
                        {/* <StyledText onClick={selectMedia}>
                          Choose Document
                        </StyledText>
                        <Typography>
                          {contentData?.application?.media_name}
                        </Typography> */}

                        <Box
                          sx={{
                            display: 'flex',
                            alignContent: 'center',
                            gap: '5px',
                            bgcolor: '#befcc5',
                            p: '5px',
                            borderRadius: '6px',
                            cursor: 'pointer',
                            mb: '10px'
                          }}
                          onClick={selectMedia}
                        >
                          <Typography sx={{ color: '#343434' }}>
                            Choose Document
                          </Typography>
                          <img
                            src={addIcon}
                            style={{
                              filter:
                                'brightness(0) saturate(100%) invert(42%) sepia(41%) saturate(959%) hue-rotate(63deg) brightness(102%) contrast(98%)'
                            }}
                          />
                        </Box>
                        <Typography>
                          {contentData?.application?.media_name}
                        </Typography>
                      </Box>
                    )}

                    <SecondPageHeadings fontSize={15}>Body</SecondPageHeadings>
                    <Box sx={{ position: 'relative' }}>
                      <TextField
                        placeholder="Add Content "
                        variant="outlined"
                        value={bodyContent}
                        multiline
                        rows={5}
                        inputRef={bodyTextFieldRef}
                        onChange={(event) => {
                          if (event.target.value.length <= 1024) {
                            setBodyContent(event.target.value);
                            setIsBodyEmpty(false);
                          }
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              style={{
                                position: 'absolute',
                                bottom: 10,
                                right: 5
                              }}
                            >
                              <ButtonTextMAxCount>
                                {bodyContent.length}/1024
                              </ButtonTextMAxCount>
                            </InputAdornment>
                          )
                        }}
                        helperText={
                          isBodyEmpty && (
                            <span style={{ color: 'red', fontWeight: 400 }}>
                              Template content is mandatory
                            </span>
                          )
                        }
                        sx={{
                          width: '100%',
                          '& .MuiInputBase-root': {
                            paddingBottom: '42px' // Internal padding for the input field
                          }
                        }}
                      />
                      <Box
                        sx={{ position: 'absolute' }}
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          left: '9px',
                          bottom: '0px',
                          marginTop: 1
                        }}
                      >
                        <Tooltip arrow placement="top" title="Choose an emoji">
                          <Typography
                            ref={emojiButtonRef}
                            sx={{
                              fontSize: theme.typography.pxToRem(16),
                              mt: '5px',
                              cursor: 'pointer'
                            }}
                            color="primary"
                            onClick={() => setShowEmojiPicker((prev) => !prev)}
                          >
                            <img
                              src={smileIcon}
                              height={'30px'}
                              width={'30px'}
                            />
                          </Typography>
                        </Tooltip>
                        <Box>
                          {showEmojiPicker && (
                            <Box
                              ref={emojiPickerRef}
                              sx={{
                                position: 'absolute',
                                zIndex: '99',
                                right: '-128px',
                                top: '-350px',
                                maxHeight: '200px',
                                maxWidth: '300px'
                              }}
                            >
                              <EmojiPicker
                                height={'350px'}
                                onEmojiClick={(emojiObject) =>
                                  handleEmojiClick(emojiObject)
                                }
                                previewConfig={{
                                  showPreview: false
                                }}
                              />
                            </Box>
                          )}
                        </Box>
                        <StyledText onClick={handleBodyButtonClick}>
                          <Tooltip
                            arrow
                            placement="top"
                            title="Choose an variable"
                          >
                            <img
                              src={variableIcon}
                              width={'30px'}
                              height={'30px'}
                            />
                          </Tooltip>
                        </StyledText>
                        <StyledText onClick={handleAttributeButtonClick}>
                          <Tooltip
                            arrow
                            placement="top"
                            title="Choose an attribute"
                          >
                            <img
                              src={attributeIcon}
                              width={'30px'}
                              height={'30px'}
                            />
                          </Tooltip>
                        </StyledText>
                        <StyledText
                          onClick={() =>
                            handleTextFormatting(bodyTextFieldRef, '*')
                          }
                        >
                          <Tooltip arrow placement="top" title="Make it bold">
                            <img
                              src={boldIcon}
                              width={'30px'}
                              height={'30px'}
                            />
                          </Tooltip>
                        </StyledText>
                        <StyledText
                          onClick={() =>
                            handleTextFormatting(bodyTextFieldRef, '_')
                          }
                        >
                          <Tooltip arrow placement="top" title="Make it italic">
                            <img
                              src={italicIcon}
                              width={'30px'}
                              height={'30px'}
                            />
                          </Tooltip>
                        </StyledText>
                        <StyledText
                          onClick={() =>
                            handleTextFormatting(bodyTextFieldRef, '~')
                          }
                        >
                          <Tooltip arrow placement="top" title="Make it strike">
                            <img
                              src={strikeIcon}
                              width={'30px'}
                              height={'30px'}
                            />
                          </Tooltip>
                        </StyledText>
                      </Box>
                    </Box>

                    <Menu
                      id="variable-menu-attribute"
                      anchorEl={attributeanchorEl}
                      open={Boolean(attributeanchorEl)}
                      onClose={handleAttributeMenuClose}
                    >
                      {attribute?.map((item, index) => (
                        <MenuItem
                          key={index}
                          onClick={() => handleMenuBodyItemClick(item?.name)}
                        >
                          {item?.name}
                        </MenuItem>
                      ))}
                    </Menu>
                    {/* Dropdown menu */}
                    <Menu
                      id="variable-menu"
                      anchorEl={bodyanchorEl}
                      open={Boolean(bodyanchorEl)}
                      onClose={handleBodyMenuClose}
                    >
                      {arrayOfStrings.map((item, index) => (
                        <MenuItem
                          key={index}
                          onClick={() => handleMenuBodyItemClick(item)}
                        >
                          {item}
                        </MenuItem>
                      ))}
                    </Menu>
                    <SecondPageHeadings fontSize={15}>
                      Footer (Optional)
                    </SecondPageHeadings>
                    <CustomTextField
                      placeholder="Add Footer"
                      value={footerContent}
                      multiline
                      maxRows={2}
                      onChange={(event) => {
                        if (event.target.value.length <= 60) {
                          setFooterContent(event.target.value);
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{
                              position: 'absolute',
                              bottom: 10,
                              right: 5
                            }}
                          >
                            <ButtonTextMAxCount>
                              {footerContent?.length}/60
                            </ButtonTextMAxCount>
                          </InputAdornment>
                        )
                      }}
                      sx={{ width: '100%' }}
                    />
                  </div>
                )}

                {/* Dailogbox starts */}

                <Dialog
                  open={openDialog}
                  onClose={() => setOpenDialog(false)}
                  maxWidth="lg"
                // sx={{ position: 'relative' }}
                >
                  {/* <DialogTitle sx={{ fontSize: 18 }}>Media Gallery</DialogTitle> */}

                  <DialogContent
                    sx={{
                      overflowY: 'scroll',
                      '&::-webkit-scrollbar': { display: 'none' },
                      height: '530px',
                      width: '950px'
                      // position:'absolute'
                    }}
                  >
                    <Grid
                      item
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            fontSize: '18px',
                            fontWeight: 500
                          }}
                        >
                          Media Gallery
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          position: 'absolute',
                          right: 15,
                          zIndex: 9999
                        }}
                      >
                        <Input
                          accept="
                        audio/aac,
                        audio/mp4,
                        audio/mpeg,
                        audio/amr,
                        audio/ogg,
                        text/plain,
                        application/pdf,
                        application/vnd.ms-powerpoint,
                        application/msword,
                        application/vnd.ms-excel,
                        application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                        application/vnd.openxmlformats-officedocument.presentationml.presentation,
                        application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                        image/jpeg,
                        image/png,
                        image/webp,
                        video/mp4,
                        video/3gp,
                        sticker"
                          id="contained-button-file"
                          multiple
                          type="file"
                          onChange={handleFileChange}
                          style={{ display: 'none' }}
                        />
                        <label htmlFor="contained-button-file">
                          <Button
                            sx={{ bgcolor: '#27AB69', color: '#ffffff', ml: 1 }}
                            variant="contained"
                            component="span"
                            endIcon={<AddIcon />}
                          >
                            Add
                          </Button>
                        </label>
                      </Box>
                    </Grid>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignContent: 'center',
                        height: '100%',
                        mt: -5,
                        ml: -3,
                        width: '100%',
                        position: 'absolute'
                      }}
                    >
                      {filteredMedia.length === 0 && (
                        <Box sx={{ fontWeight: 500 }}>No media available</Box>
                      )}
                    </Box>
                    <AnimatePresence>
                      <Grid
                        container
                        sx={{
                          justifyContent: { xs: 'center', md: 'flex-start' }
                        }}
                      >
                        {filteredMedia?.map((media, index) => (
                          <motion.div
                            key={media._id}
                            initial={{ opacity: 0, scale: 0.6 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0.6 }}
                            transition={{
                              duration: 0.3,
                              delay: index * 0.1
                            }}
                          >
                            <Card
                              sx={{
                                maxWidth: 300,
                                margin: 1,
                                position: 'relative' // Ensure the card acts as the positioning context
                              }}
                            >
                              {/* Media content */}

                              <div
                                style={{
                                  position: 'relative',
                                  width: '100%',
                                  height: 155
                                }}
                              >
                                {/* Media */}
                                {contentData.type === 'image' &&
                                  media.media_type === 'image' && (
                                    <CardMedia
                                      component="img"
                                      onClick={() =>
                                        handleMediaSelection(media)
                                      }
                                      style={{
                                        minWidth: '200px',
                                        maxWidth: '200px', // Ensuring the video stays within container width
                                        width: '100%',
                                        height: '100%', // Allowing height to adjust proportionally
                                        objectFit: 'cover'
                                      }}
                                      src={`${apiKey}/${media.media_url?.replace(
                                        /^\.\//,
                                        ''
                                      )}`}
                                      alt={media.media_id}
                                    />
                                  )}
                                {contentData.type === 'video' &&
                                  media.media_type === 'video' && (
                                    <CardMedia
                                      component="video"
                                      onClick={() =>
                                        handleMediaSelection(media)
                                      }
                                      style={{
                                        minWidth: '140px',
                                        maxWidth: '200px',
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover'
                                      }}
                                      controls
                                      src={`${apiKey}/${media.media_url?.replace(
                                        /^\.\//,
                                        ''
                                      )}`}
                                      alt={media.media_id}
                                    />
                                  )}
                                {contentData.type === 'application' &&
                                  media.media_type === 'application' && (
                                    <iframe
                                      src={`https://docs.google.com/viewer?url=${apiKey}/${media.media_url?.replace(
                                        /^\.\//,
                                        ''
                                      )}&embedded=true`}
                                      title={media.media_id}
                                      style={{
                                        width: '100%',
                                        height: '100%',
                                        minWidth: '155px',
                                        objectFit: 'cover',
                                        border: 'none'
                                      }}
                                    />
                                  )}
                                {/* Checkbox */}
                                <div
                                  style={{
                                    position: 'absolute',
                                    bottom: 0,
                                    right: 0
                                  }}
                                >
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        // className={classes.checkbox}
                                        style={{
                                          position: 'absolute',
                                          bottom: 0,
                                          right: 0,
                                          transform: 'scale(0.8)',
                                          checkbox: {
                                            '&.Mui-checked': {
                                              color: '#27AB69'
                                            },
                                            '& .MuiIconButton-root': {
                                              borderColor: '#27AB69',
                                              backgroundColor: '#27AB69'
                                            },
                                            '& .MuiSvgIcon-root': {
                                              backgroundColor:
                                                'rgba(255, 255, 255, 0.5)',
                                              borderRadius: 4
                                            }
                                          }
                                        }}
                                        checked={selectedMediaId === media._id}
                                        onChange={() =>
                                          handleMediaSelection(media)
                                        }
                                      />
                                    }
                                  />
                                </div>
                              </div>
                            </Card>
                          </motion.div>
                        ))}
                      </Grid>
                    </AnimatePresence>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => setOpenDialog(false)}
                      sx={{ color: '#27AB69', fontWeight: 500 }}
                    >
                      Close
                    </Button>
                    <Button
                      onClick={handleConfirmMedia}
                      sx={{
                        bgcolor: '#27AB69',
                        color: '#ffffff',
                        ml: 1
                      }}
                      variant="contained"
                    >
                      Confirm
                    </Button>
                  </DialogActions>
                </Dialog>

                {/* =================================================================================================================== */}
                {/* =================================================================================================================== */}

                {/* tabpanel 2 for carousel */}
                {category === 'CAROUSEL' && (
                  <div>
                    <SecondPageHeadings fontSize={15}>
                      Content
                    </SecondPageHeadings>
                    <TextField
                      placeholder="Add Content "
                      variant="outlined"
                      value={bodyContent}
                      multiline
                      rows={5}
                      inputRef={bodyTextFieldRef}
                      onChange={(event) => {
                        if (event.target.value.length <= 1024) {
                          setBodyContent(event.target.value);
                          setIsBodyEmpty(false);
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{
                              position: 'absolute',
                              bottom: 10,
                              right: 5
                            }}
                          >
                            <ButtonTextMAxCount>
                              {bodyContent.length}/1024
                            </ButtonTextMAxCount>
                          </InputAdornment>
                        )
                      }}
                      helperText={
                        isBodyEmpty && (
                          <span style={{ color: 'red', fontWeight: 400 }}>
                            Template content is mandatory
                          </span>
                        )
                      }
                      sx={{ width: '100%' }}
                    />
                    {/* Add a Select dropdown for the array of strings */}
                    {/* Button-like structure on the right */}
                    {/* Button on the bottom right */}
                    <Box
                      sx={{ position: 'relative' }}
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: 1,
                        bgcolor: 'red'
                      }}
                    >
                      <Tooltip arrow placement="top" title="Choose an emoji">
                        <Typography
                          ref={emojiButtonRef}
                          sx={{
                            fontSize: theme.typography.pxToRem(16),
                            cursor: 'pointer',
                            mt: '5px'
                          }}
                          color="primary"
                          onClick={() => setShowEmojiPicker((prev) => !prev)}
                        >
                          <img src={smileIcon} height={'30px'} width={'30px'} />
                        </Typography>
                      </Tooltip>
                      <Box>
                        {showEmojiPicker && (
                          <Box
                            ref={emojiPickerRef}
                            sx={{
                              position: 'absolute',
                              right: '60px',
                              top: '-280px',
                              zIndex: 999,
                              maxHeight: '150px',
                              maxWidth: '300px'
                            }}
                          >
                            <EmojiPicker
                              height={'280px'}
                              onEmojiClick={(emojiObject) =>
                                handleEmojiClick(emojiObject)
                              }
                              previewConfig={{
                                showPreview: false
                              }}
                            />
                          </Box>
                        )}
                      </Box>
                      <StyledText onClick={handleBodyButtonClick}>
                        <img
                          src={variableIcon}
                          width={'30px'}
                          height={'30px'}
                        />
                      </StyledText>
                    </Box>

                    {/* Dropdown menu */}
                    <Menu
                      id="variable-menu"
                      anchorEl={bodyanchorEl}
                      open={Boolean(bodyanchorEl)}
                      onClose={handleBodyMenuClose}
                    >
                      {arrayOfStrings.map((item, index) => (
                        <MenuItem
                          key={index}
                          onClick={() => handleMenuBodyItemClick(item)}
                        >
                          {item}
                        </MenuItem>
                      ))}
                    </Menu>

                    {/* cards creation */}
                    <SecondPageHeadings variant="h4" gutterBottom>
                      Cards{' '}
                    </SecondPageHeadings>
                    <>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: 2
                        }}
                      >
                        {/* {cards.map((_, index) => (
                          <Button
                            key={index}
                            onClick={() => setActiveCard(index)}
                            variant={
                              activeCard === index ? 'contained' : 'outlined'
                            }
                            sx={{ marginRight: 1 }}
                          >
                            {index + 1}
                          </Button>
                        ))} */}
                        {cards.map((_, index) => (<Box sx={{ position: "relative" }}>
                          <Button
                            key={index}
                            onClick={() => setActiveCard(index)}
                            variant="contained" // This can be controlled via sx
                            sx={{
                              width: '15px',
                              margin: '0px 5px 0px 0px',
                              padding: '10px 4px',
                              borderRadius: '10px',
                              backgroundColor:
                                activeCard === index
                                  ? '#BEFCC5'
                                  : 'transparent',
                              color:
                                activeCard === index ? '#27AB69' : '#959595',
                              // border: activeCard === index ? 'none' : '1px solid #959595',
                              border: 'none',
                              '&:hover': {
                                backgroundColor:
                                  activeCard === index
                                    ? '#BEFCC5'
                                    : 'transparent',
                                color:
                                  activeCard === index ? '#27AB69' : '#959595',
                                // border: activeCard === index ? 'none' : '1px solid #959595',
                                border: 'none'
                              }
                            }}
                          >
                            {index + 1}
                          </Button>
                          {cards.length>1&&

                          <div onClick={() => removeCard(index)} style={{
                            position: "absolute",
                            top: "-10px",
                            width: "20px",
                            right: "0px",
                            display: "flex",
                            background: "#27ab6954",
                            height: "20px",
                            padding: "2px",
                            justifyContent: "center",
                            cursor:"pointer",
                            borderRadius:"40px",
                            alignItems: "center",
                          }}>
                            x
                          </div>
                        }
                        </Box>

                        ))}
                        <IconButton
                          onClick={addCard}
                          disabled={cards.length >= 10}
                          color="primary"
                        >
                          <AddIcon />
                        </IconButton>
                      </Box>
                      {cards.length > 0 && (
                        <Card sx={{ marginBottom: 2 }}>
                          <CardContent>
                            <SecondPageHeadings variant="h6">
                              Header
                            </SecondPageHeadings>

                            {activeCard === 0 && (
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  marginBottom: 2
                                }}
                              >
                                <StyledButton
                                  onClick={() =>
                                    handleCarouselMediaSelection('image')
                                  }
                                  active={
                                    cards[activeCard].headerType === 'image'
                                  }
                                >
                                  <img src={imageIcon} alt="ImageIcon" />
                                  <ButtonTypography
                                    variant="button"
                                    active={
                                      cards[activeCard].headerType === 'image'
                                        ? 'true'
                                        : 'false'
                                    }
                                  >
                                    Image
                                  </ButtonTypography>
                                </StyledButton>
                                <StyledButton
                                  onClick={() =>
                                    handleCarouselMediaSelection('video')
                                  }
                                  active={
                                    cards[activeCard].headerType === 'video'
                                  }
                                >
                                  <img src={videoIcon} alt="VideoIcon" />
                                  <ButtonTypography
                                    variant="button"
                                    active={
                                      cards[activeCard].headerType === 'video'
                                        ? 'true'
                                        : 'false'
                                    }
                                  >
                                    Video
                                  </ButtonTypography>
                                </StyledButton>
                              </Box>
                            )}

                            {cards[activeCard].headerType === 'image' && (
                              <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                              >
                                <StyledText
                                  onClick={() =>
                                    selectMediaForCarousel('image')
                                  }
                                >
                                  Choose image
                                </StyledText>
                                {/* <Typography>
                  {cards[activeCard].headerContent ? cards[activeCard].headerContent : 'No image selected'}
                </Typography> */}
                                <Typography variant="caption">
                                  Selected Image:{' '}
                                  {
                                    selectedCarouselMediaArray[activeCard]
                                      ?.media_name
                                  }
                                </Typography>
                              </Box>
                            )}
                            {cards[activeCard].headerType === 'video' && (
                              <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                              >
                                <StyledText
                                  onClick={() =>
                                    selectMediaForCarousel('video')
                                  }
                                >
                                  Choose Video
                                </StyledText>
                                {/* <Typography>
                {cards[activeCard].headerContent ? cards[activeCard].headerContent : 'No video selected'}
                </Typography> */}
                                <Typography variant="caption">
                                  Selected Video:{' '}
                                  {
                                    selectedCarouselMediaArray[activeCard]
                                      ?.media_name
                                  }
                                </Typography>
                              </Box>
                            )}
                            {/* <TextField
                              fullWidth
                              margin="normal"
                              label="Body"
                              multiline
                              rows={4}
                              value={cards[activeCard].body}
                              onChange={(e) =>
                                updateCard(activeCard, 'body', e.target.value)
                              }
                            /> */}
                            <SecondPageHeadings fontSize={15} mt="15px">
                              Content
                            </SecondPageHeadings>
                            <TextField
                              placeholder="Add Content "
                              variant="outlined"
                              value={cards[activeCard].body}
                              multiline
                              rows={2}
                              // onChange={(event) => {
                              //   if (event.target.value.length <= 1024) {
                              //     setBodyContent(event.target.value);
                              //     setIsBodyEmpty(false);
                              //   }
                              // }}
                              onChange={(e) => {
                                if (e.target.value.length <= 160) {
                                  // updateCard(
                                  //   activeCard,
                                  //   'body',
                                  //   e.target.value
                                  // );
                                  const updatedValue = e.target.value.replace(
                                    /\n/g,
                                    ''
                                  );
                                  updateCard(activeCard, 'body', updatedValue);
                                }
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    position="end"
                                    style={{
                                      position: 'absolute',
                                      bottom: 10,
                                      right: 5
                                    }}
                                  >
                                    <ButtonTextMAxCount>
                                      {cards[activeCard].body.length}/160
                                    </ButtonTextMAxCount>
                                  </InputAdornment>
                                )
                              }}
                              helperText={
                                !cards[activeCard].body && (
                                  <span
                                    style={{ color: 'red', fontWeight: 400 }}
                                  >
                                    Template content is mandatory
                                  </span>
                                )
                              }
                              sx={{ width: '100%' }}
                            />
                            <SecondPageHeadings mt="20px">
                              Buttons
                            </SecondPageHeadings>
                            {activeCard === 0 &&
                              cards[activeCard].buttons.length < 2 && (
                                <>
                                  <CarouselButton
                                    onClick={() =>
                                      addButton(activeCard, 'quick_reply')
                                    }
                                    sx={{ marginRight: 1 }}
                                  >
                                    Quick Reply
                                  </CarouselButton>
                                  <CarouselButton
                                    onClick={() => addButton(activeCard, 'url')}
                                    sx={{ marginRight: 1 }}
                                  >
                                    URL
                                  </CarouselButton>
                                  <CarouselButton
                                    onClick={() =>
                                      addButton(activeCard, 'phone_number')
                                    }
                                    sx={{ marginRight: 1 }}
                                  >
                                    Phone Number
                                  </CarouselButton>
                                </>
                              )}

                            {cards[activeCard].buttons.map(
                              (button, buttonIndex) => (
                                <Box key={buttonIndex} sx={{ marginY: 1 }}>
                                  <Box
                                    bgcolor="#F6F6F6"
                                    padding="10px"
                                    borderRadius="10px"
                                    marginBottom="10px"
                                  >
                                    <Box>
                                      <InnerButtonTextField
                                        placeholder={`${button.type.charAt(0).toUpperCase() +
                                          button.type.slice(1)
                                          } Button Text`}
                                        variant="standard"
                                        id="standard-basic"
                                        fullWidth
                                        value={button.text}
                                        onChange={(e) => {
                                          if (e.target.value.length <= 25) {
                                            updateButtonContent(
                                              activeCard,
                                              buttonIndex,
                                              'text',
                                              e.target.value
                                            );
                                          }
                                        }}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment
                                              position="end"
                                              style={{
                                                position: 'absolute',
                                                bottom: 10,
                                                right: 5
                                              }}
                                            >
                                              <ButtonTextMAxCount>
                                                {button.text.length}/25
                                              </ButtonTextMAxCount>
                                            </InputAdornment>
                                          )
                                        }}
                                        error={button.text.length > 24}
                                        helperText={
                                          button.text.length > 24
                                            ? 'Maximum Limit Reached'
                                            : ''
                                        }
                                        style={{ marginBottom: '10px' }}
                                      />
                                    </Box>
                                    {(button.type.toLowerCase() === 'url' ||
                                      button.type.toLowerCase() === 'phone_number') && (
                                        <>
                                          {button.type.toLowerCase() === 'url' && (
                                            <InnerButtonTextField
                                              placeholder="Type your URL"
                                              variant="standard"
                                              id="additional-field-1"
                                              fullWidth
                                              value={button.url}
                                              onChange={(e) =>
                                                updateButtonContent(
                                                  activeCard,
                                                  buttonIndex,
                                                  'url',
                                                  e.target.value
                                                )
                                              }
                                              style={{ marginBottom: '10px' }}
                                            />
                                          )}
                                          {button.type.toLowerCase() === 'phone_number' && (
                                            <PhoneInput
                                              international
                                              defaultCountry="IN"
                                              placeholder="Enter phone number"
                                              value={button.phone_number || ''}
                                              onChange={(value) =>
                                                updateButtonContent(
                                                  activeCard,
                                                  buttonIndex,
                                                  'phone_number',
                                                  value
                                                )
                                              }
                                              className="phoneInput"
                                              style={{ color: '#27ab69' }}
                                            />
                                          )}
                                        </>
                                      )}
                                  </Box>
                                </Box>
                              )
                            )}
                          </CardContent>
                        </Card>
                      )}
                      <CardActions>
                        {/* <Button
                          variant="contained"
                          color="primary"
                          onClick={generateTemplate}
                        >
                          Generate Template
                        </Button> */}
                        {/* <Button
                          variant="contained"
                          color="primary"
                          onClick={submitCatalog}
                        >
                          Submit Catalog
                        </Button> */}
                      </CardActions>
                    </>

                    {/* Dailogbox starts for carousel*/}

                    <Dialog
                      open={openCarouselDialog}
                      onClose={() => setOpenCarouselDialog(false)}
                      maxWidth="md"
                    >
                      <DialogTitle sx={{ fontSize: 18 }}>
                        Media Gallery
                      </DialogTitle>

                      <DialogContent
                        sx={{
                          overflowY: 'scroll',
                          '&::-webkit-scrollbar': { display: 'none' }
                        }}
                      >
                        <Grid
                          item
                          sx={{ display: 'flex', justifyContent: 'flex-end' }}
                        >
                          <Input
                            accept="
              audio/aac,
              audio/mp4,
              audio/mpeg,
              audio/amr,
              audio/ogg,
              text/plain,
              application/pdf,
              application/vnd.ms-powerpoint,
              application/msword,
              application/vnd.ms-excel,
              application/vnd.openxmlformats-officedocument.wordprocessingml.document,
              application/vnd.openxmlformats-officedocument.presentationml.presentation,
              application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
              image/jpeg,
              image/png,
              image/webp,
              video/mp4,
              video/3gp,
              sticker"
                            id="contained-button-file"
                            multiple
                            type="file"
                            onChange={handleFileChangeCarousel}
                            style={{ display: 'none' }}
                          />
                          <label htmlFor="contained-button-file">
                            <Button
                              sx={{
                                bgcolor: '#27AB69',
                                color: '#ffffff',
                                ml: 1
                              }}
                              variant="contained"
                              component="span"
                              endIcon={<AddIcon />}
                            >
                              Add
                            </Button>
                          </label>
                        </Grid>
                        <Grid
                          container
                          sx={{
                            justifyContent: { xs: 'center', md: 'flex-start' }
                          }}
                        >
                          {carouselMedia?.map((media) => (
                            <Card
                              key={media._id}
                              sx={{
                                maxWidth: 300,
                                margin: 1,
                                position: 'relative' // Ensure the card acts as the positioning context
                              }}
                            >
                              {/* Media content */}
                              <div
                                style={{
                                  position: 'relative',
                                  width: '100%',
                                  height: 155
                                }}
                              >
                                {/* Media */}
                                {media.media_type === 'image' && (
                                  <CardMedia
                                    component="img"
                                    // onClick={() => handleMediaSelect(media)}
                                    style={{
                                      minWidth: '200px',
                                      maxWidth: '200px', // Ensuring the video stays within container width
                                      width: '100%',
                                      height: '100%', // Allowing height to adjust proportionally
                                      objectFit: 'cover'
                                    }}
                                    src={`${apiKey}/${media.media_url?.replace(
                                      /^\.\//,
                                      ''
                                    )}`}
                                    alt={media.media_id}
                                  />
                                )}
                                {media.media_type === 'video' && (
                                  <CardMedia
                                    component="video"
                                    // onClick={() => handleMediaSelect(media)}
                                    style={{
                                      minWidth: '140px',
                                      maxWidth: '200px',
                                      width: '100%',
                                      height: '100%',
                                      objectFit: 'cover'
                                    }}
                                    controls
                                    src={`${apiKey}/${media.media_url?.replace(
                                      /^\.\//,
                                      ''
                                    )}`}
                                    alt={media.media_id}
                                  />
                                )}
                                {/* Checkbox */}
                                <div
                                  style={{
                                    position: 'absolute',
                                    bottom: 0,
                                    right: 0
                                  }}
                                >
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        style={{
                                          position: 'absolute',
                                          bottom: 0,
                                          right: 0,
                                          transform: 'scale(0.8)'
                                        }}
                                        checked={
                                          selectedCarouselMedia._id ===
                                          media._id
                                        }
                                        onChange={() =>
                                          handleCarouselMediaSelect(media)
                                        }
                                      />
                                    }
                                  />
                                </div>
                              </div>
                            </Card>
                          ))}
                        </Grid>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => setOpenCarouselDialog(false)}
                          sx={{ color: '#27AB69', fontWeight: 500 }}
                        >
                          Close
                        </Button>
                        <Button
                          onClick={handleCarouselConfirmMedia}
                          sx={{
                            bgcolor: '#27AB69',
                            color: '#ffffff',
                            fontWeight: 500
                          }}
                          variant="contained"
                        >
                          Confirm
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                )}

                {/* tabpanel 3 */}
                {category !== 'CAROUSEL' && (
                  <>
                    <MainHeadings>Buttons</MainHeadings>
                    <Typography pb={1}>
                      Create buttons that let the customer respond to your
                      message or take action
                    </Typography>

                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        borderColor: 'divider',
                        mb: '20px'
                      }}
                    >
                      {/* Render buttons instead of TabList */}
                      <StyledButton
                        onClick={() => handleButtonClick('none')}
                        active={buttonSection === 'none'}
                      >
                        <img src={noneIcon} alt="None Icon" />
                        <ButtonTypography
                          variant="button"
                          active={buttonSection === 'none' ? 'true' : 'false'}
                        >
                          {'None'}
                        </ButtonTypography>
                      </StyledButton>
                      <StyledButton
                        onClick={() => handleButtonClick('callToAction')}
                        active={buttonSection === 'callToAction'}
                      >
                        <img src={callToAction} alt="Call To Action" />
                        <ButtonTypography
                          variant="button"
                          active={
                            buttonSection === 'callToAction' ? 'true' : 'false'
                          }
                        >
                          {'Buttons'}
                        </ButtonTypography>
                      </StyledButton>
                      {/* <StyledButton
                        onClick={() => handleButtonClick('quickReplay')}
                        active={buttonSection === 'quickReplay'}
                      >
                        <img src={quickReplay} alt="Quick Reply Icon" />
                        <ButtonTypography
                          variant="button"
                          active={
                            buttonSection === 'quickReplay' ? 'true' : 'false'
                          }
                        >
                          {'Quick Replies'}
                        </ButtonTypography>
                      </StyledButton> */}
                      {isAllow && (
                        <StyledButton
                          onClick={() => handleButtonClick('flow')}
                          active={buttonSection === 'flow'}
                        >
                          <img src={FlowFormIcon} alt="Flow Form Icon" />
                          <ButtonTypography
                            variant="button"
                            active={buttonSection === 'flow' ? 'true' : 'false'}
                          >
                            {'Flows'}
                          </ButtonTypography>
                        </StyledButton>
                      )}
                    </Box>

                    {/* No buttons tab */}
                    {buttonSection === 'none' && (
                      <Typography>No Buttons</Typography>
                    )}

                    {/* Call to action tab */}
                    {buttonSection === 'callToAction' && (
                      <>
                        <Box
                          bgcolor="#F6F6F6"
                          padding="10px"
                          borderRadius="10px"
                          marginBottom="10px"
                        >
                          {/* Nested Box with Input Fields and Button-like Structure */}
                          <CallToActionButtonHeading>
                            Mobile Number
                          </CallToActionButtonHeading>
                          <Box>
                            {/* Input Field 1 with Border Below */}
                            <InnerButtonTextField
                              placeholder="Button text"
                              variant="standard"
                              id="standard-basic"
                              fullWidth
                              value={callToActionButtonTextTwo}
                              onChange={(e) => {
                                setCallToActionButtonTextTwoError(false);
                                setCallToActionButtonTextTwo(e.target.value);
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    position="end"
                                    style={{
                                      position: 'absolute',
                                      bottom: 10,
                                      right: 5
                                    }}
                                  >
                                    <ButtonTextMAxCount>
                                      {callToActionButtonTextTwo?.length}/25
                                    </ButtonTextMAxCount>
                                  </InputAdornment>
                                )
                              }}
                              error={Boolean(
                                callToActionButtonTextTwo?.length > 24
                              )}
                              helperText={
                                callToActionButtonTextTwo?.length > 24
                                  ? 'Maximum Limit Reached'
                                  : ''
                              }
                              style={{ marginBottom: '10px' }}
                            />
                            {callToActionButtonTextTwoError && (
                              <ErrorText
                                sx={{
                                  mt: '-5px',
                                  mb: '5px'
                                }}
                              >
                                Please enter button text
                              </ErrorText>
                            )}

                            {/* Input Field 2 with Border Below */}
                            <PhoneInput
                              international
                              defaultCountry="IN"
                              placeholder="Enter phone number"
                              value={mobile}
                              // onChange={setMobile}
                              // onChange={() => {
                              //   // setMobile(value);
                              //   handlePhoneChange;
                              //   setMobileError(false);
                              // }}
                              onChange={handlePhoneChange}
                              className="phoneInput"
                              style={{ color: '#27ab69' }}
                            />
                            {mobileError && (
                              <ErrorText
                                sx={{
                                  mt: '-5px',
                                  mb: '5px'
                                }}
                              >
                                Please enter a valid phone number
                              </ErrorText>
                            )}
                          </Box>
                        </Box>
                        <CallToAction
                          multipleCallToActionUrl={multipleCallToActionUrl}
                          SetMultipleCallToActionUrl={
                            SetMultipleCallToActionUrl
                          }
                        />
                        <QuickReply
                          setMultipleButtons={setMultipleButtons}
                          multipleButtons={multipleButtons}
                        />
                        <AttributeMatch
                          onChangeAtt={handleAttributeChange}
                          dynamicArray={dynamicArray}
                          setDynamicArray={setDynamicArray}
                        />
                      </>
                    )}

                    {/* Quick replay tab */}
                    {/* {buttonSection === 'quickReplay' && (
                      <>
                        <Box
                          bgcolor="#F6F6F6"
                          padding="10px"
                          borderRadius="10px"
                          marginBottom="10px"
                        >
                          <Box>
                            <InnerButtonTextField
                              placeholder="Button text"
                              id="standard-basic"
                              variant="standard"
                              fullWidth
                              value={buttonTextOne}
                              onChange={(e) => setButtonTextOne(e.target.value)}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    position="end"
                                    style={{
                                      position: 'absolute',
                                      bottom: 10,
                                      right: 5
                                    }}
                                  >
                                    <ButtonTextMAxCount>
                                      {buttonTextOne?.length}/25
                                    </ButtonTextMAxCount>
                                  </InputAdornment>
                                )
                              }}
                              error={Boolean(buttonTextOne?.length > 24)}
                              helperText={
                                buttonTextOne?.length > 24
                                  ? 'Maximum Limit Reached'
                                  : ''
                              }
                              style={{ marginBottom: '10px' }}
                            />
                          </Box>
                        </Box>
                        <Box
                          bgcolor="#F6F6F6"
                          padding="10px"
                          borderRadius="10px"
                          marginBottom="10px"
                        >
                          <Box>
                            <InnerButtonTextField
                              placeholder="Button text"
                              variant="standard"
                              id="standard-basic"
                              fullWidth
                              value={buttonTextTwo}
                              onChange={(e) => setButtonTextTwo(e.target.value)}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    position="end"
                                    style={{
                                      position: 'absolute',
                                      bottom: 10,
                                      right: 5
                                    }}
                                  >
                                    <ButtonTextMAxCount>
                                      {buttonTextTwo?.length}/25
                                    </ButtonTextMAxCount>
                                  </InputAdornment>
                                )
                              }}
                              error={Boolean(buttonTextTwo?.length > 24)}
                              helperText={
                                buttonTextTwo?.length > 24
                                  ? 'Maximum Limit Reached'
                                  : ''
                              }
                              style={{ marginBottom: '10px' }}
                            />
                          </Box>
                        </Box>
                        <Box
                          bgcolor="#F6F6F6"
                          padding="10px"
                          borderRadius="10px"
                          marginBottom="10px"
                        >
                          <Box>
                            <InnerButtonTextField
                              placeholder="Button text"
                              variant="standard"
                              id="standard-basic"
                              fullWidth
                              value={buttonTextThree}
                              onChange={(e) =>
                                setButtonTextThree(e.target.value)
                              }
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    position="end"
                                    style={{
                                      position: 'absolute',
                                      bottom: 10,
                                      right: 5
                                    }}
                                  >
                                    <ButtonTextMAxCount>
                                      {buttonTextThree?.length}/25
                                    </ButtonTextMAxCount>
                                  </InputAdornment>
                                )
                              }}
                              error={Boolean(buttonTextThree?.length > 24)}
                              helperText={
                                buttonTextThree?.length > 24
                                  ? 'Maximum Limit Reached'
                                  : ''
                              }
                              style={{ marginBottom: '10px' }}
                            />
                          </Box>
                        </Box>
                        {/* <Box bgcolor="#F6F6F6" padding="10px" borderRadius="10px">
                      <Box>
                        <InnerButtonTextField
                          placeholder="Button text"
                          variant="standard"
                          id="standard-basic"
                          fullWidth
                          value={buttonTextThree}
                          onChange={(e) => setButtonTextThree(e.target.value)}
                          style={{ marginBottom: '10px' }}
                        />

                        <ReplayButtons>{buttonTextThree}</ReplayButtons>
                      </Box>
                    </Box> 
                      </>
                    )} */}
                    {/* Flow tab */}

                    {buttonSection === 'flow' && (
                      <>
                        {/* first button */}
                        <Box
                          bgcolor="#F6F6F6"
                          padding="10px"
                          borderRadius="10px"
                          marginBottom="10px"
                        >
                          {/* Nested Box with Input Field and Button-like Structure */}
                          <Box>
                            {/* Input Field with Border Below */}
                            <InnerButtonTextField
                              placeholder="Button text"
                              id="standard-basic"
                              variant="standard"
                              fullWidth
                              value={buttonTextFlow}
                              onChange={(e) =>
                                setButtonTextFlow(e.target.value)
                              }
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    position="end"
                                    style={{
                                      position: 'absolute',
                                      bottom: 10,
                                      right: 5
                                    }}
                                  >
                                    <ButtonTextMAxCount>
                                      {buttonTextFlow?.length}/25
                                    </ButtonTextMAxCount>
                                  </InputAdornment>
                                )
                              }}
                              error={Boolean(buttonTextFlow?.length > 24)}
                              helperText={
                                buttonTextFlow?.length > 24
                                  ? 'Maximum Limit Reached'
                                  : ''
                              }
                              style={{ marginBottom: '10px' }}
                            />
                            {buttonTextFlowError && (
                              <ErrorText
                                sx={{
                                  mt: '-5px',
                                  mb: '5px'
                                }}
                              >
                                {buttonTextFlowError}
                              </ErrorText>
                            )}
                          </Box>
                          <Box>
                            <Select
                              value={selectedFlowsList}
                              onChange={(selectedValue) => {
                                setSelectedFlow(selectedValue.value),
                                  setSelectedFlowEmpty(false);
                                setSelectedFlowsList(selectedValue);
                              }}
                              options={options}
                              placeholder="Select Flows"
                              styles={customStyles}
                              isClearable
                              isSearchable
                              noOptionsMessage={() => 'No tags available'}
                              menuPosition="fixed"
                              menuPlacement="auto"
                            />
                            {/* <CustomSelect
                              value={selectedFlow}
                              onChange={(event) => {
                                setSelectedFlow(event.target.value),
                                  setSelectedFlowEmpty(false);
                              }}
                              displayEmpty
                              variant="standard"
                              sx={{
                                width: '100%',
                                color: selectedFlow ? '#111111' : '#BEBEBE',
                                mr: '8px'
                              }}
                              MenuProps={{
                                MenuListProps: {
                                  sx: {
                                    paddingY: '0px',
                                    paddingX: '0px'
                                  }
                                }
                              }}
                            >
                              <MenuItem
                                sx={{ fontSize: '15px' }}
                                value=""
                                disabled
                              >
                                Select Flow
                              </MenuItem>
                              {publishedForms?.map((flow) => (
                                <MenuItem key={flow.flowId} value={flow.flowId}>
                                  {flow.flowName}
                                </MenuItem>
                              ))}
                            </CustomSelect> */}
                          </Box>
                        </Box>
                      </>
                    )}
                  </>
                )}

                {category === 'AUTHENTICATION' && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 2
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        gap: 1,
                        justifyItems: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '14px',
                          fontWeight: 600
                        }}
                      >
                        Security Recommedation
                      </Typography>
                      <Checkbox
                        value={securityRecommendation}
                        checked={securityRecommendation}
                        onChange={() =>
                          setSecurityRecommendation(!securityRecommendation)
                        }
                        type="checkbox"
                      />
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: '14px',
                          fontWeight: 600,
                          mb: 1
                        }}
                      >
                        Expiration Time
                      </Typography>
                      <TextField
                        value={expirationTime}
                        onChange={(e) => setExpirationTime(e.target.value)}
                        sx={{ width: '100%' }}
                      />
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: '14px',
                          fontWeight: 600,
                          mb: 1
                        }}
                      >
                        Text
                      </Typography>
                      <TextField
                        value={copyCode}
                        onChange={(e) => setCopyCode(e.target.value)}
                        sx={{ width: '100%' }}
                      />
                    </Box>
                  </Box>
                )}
              </Box>

              {/* Navigation buttons */}
              <Box my={1} display="flex" justifyContent="flex-end">
                {/* <SendPrevButtons
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Prev
                </SendPrevButtons> */}
                <SendPrevButtons
                  variant="contained"
                  color="primary"
                  disabled={submitLoading}
                  onClick={
                    // activeStep === steps.length - 1
                    true ? handleFinalSubmit : handleNext
                  }
                >
                  {/* {activeStep === steps.length - 1 ? 'Finish' : 'Next'} */}
                  {submitLoading ? (
                    // <CircularProgress size={24} color="inherit" />
                    <span>Loading...</span>
                  ) : // ) : activeStep === steps.length - 1 ? (
                    true ? (
                      'Finish'
                    ) : (
                      'Next'
                    )}
                </SendPrevButtons>
              </Box>
            </Box>
          </Box>
        </LeftSide>
      </Grid>

      {/* Right section */}
      {/* <TemplateBg top={top}  /> */}

      {category !== 'AUTHENTICATION' ? (
        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center'
          }}
        >
          <TemplateBg top={top} data={data} />

        </Grid>
      ) : (
        <Grid item xs={12} sm={4} justifyContent="center"></Grid>
      )}
    </Grid>
    </>

  );
};

export default TemplateContent;
