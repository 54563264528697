import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  TextField,
  useTheme,
  Switch,
  Stack,
  IconButton
} from '@mui/material';
import TemplateApiCalls from 'src/utility/TemplateApiCalls';
import styled from '@emotion/styled';
import SelectImage from '../../../assets/TemplateIcons/selectImage.svg';
import FileIcon from '../../../assets/TemplateIcons/documentIcon.svg';
import TemplatePreviews from '../Components/TemplatePreviews';
import Loader from 'src/components/Loader/Loader';
import { useNavigate } from 'react-router-dom';
import Toast from 'src/utility/Toast';
import ApiCalls from 'src/utility/ApiCalls';
import sendIcon from '../../../assets/sendIcon.svg';
import Select from 'react-select';

// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// // import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import LocalizationProvider from '@mui/x-date-pickers'
// import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
// import MobileDatePicker from '@mui/lab/MobileDatePicker';
// import DesktopTimePicker from '@mui/lab/DesktopTimePicker';
// import MobileTimePicker from '@mui/lab/MobileTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import {
  DesktopDatePicker,
  MobileDatePicker,
  DesktopTimePicker,
  MobileTimePicker
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import closeIcon from '../../../assets/svgIcons/closeIcon.svg';
import { useSelector } from 'react-redux';
import { useAuth } from 'src/Authentication/AuthContext';
import { getDecryptedData } from 'src/utility/LocalStorageManager';
import CustomTooltip from 'src/content/applications/Components/customTooltip';
import { AnimatePresence, motion } from 'framer-motion';
import TemplateBg from 'src/atom/components/TemplateBg';

const customStyles = {
  control: (provided) => ({
    ...provided,
    width: '100%',
    height: 45,
    borderRadius: 10,
    fontSize: 13,
    overflow: 'hidden',
    borderColor: '#ececec'
  }),
  option: (base, { isSelected }) => ({
    ...base,
    backgroundColor: isSelected ? '#d1e7dd' : 'transparent',
    color: isSelected ? '#0f5132' : '#000',
    fontSize: '13px',
    '&:hover': {
      backgroundColor: isSelected ? '#bcd0c7' : '#D8FFEB'
    }
  }),
  menu: (provided) => ({
    ...provided,
    width: '100%',
    overflow: 'hidden'
  })
};

const SendButton = styled(Button)`
  && {
    border-radius: 5px;
    height: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
    // min-width: 260px;
    background-color: #27ab69;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    &:hover {
      background-color: #1e7e34;
    }
    @media screen and (max-width: 600px) {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%; // Adjust width for smaller screens
      margin-bottom: 20px; // Add some space between elements
    }
  }
`;

//  All headings
const Headings = styled(Typography)(
  () => `
      color: #111111;
      font-size: 14px; 
      font-weight: 500; 
      margin-bottom:8px;
      margin-top:5px;
    `
);

const CustomTextField = styled(TextField)(() => ({
  '& .MuiInputBase-root': {
    height: 45,
    display: 'flex',
    alignItems: 'center'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  },
  borderRadius: 10,
  fontSize: 13,
  backgroundColor: '#FFFFFF'
}));

const CustomDateTimePickerField = styled(TextField)(() => ({
  '& .MuiInputBase-root': {
    height: 40, // Height of the input
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FFFFFF', // Background color
    borderRadius: 10, // Rounded corners
    fontSize: 13 // Font size
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #ececec' // Subtle border color
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#d1e7dd' // Hover border color
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#27AB69' // Focus border color
  }
}));

const BoxWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 5px;

  justify-content: start;
  align-items: center;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    gap: 5px;
  }
`;

const CustomBox = styled(Box)`
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  min-width: 130px;
  background-color: #ffffff;
  // margin-right: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
    margin-bottom: 5px;
  }
`;

const LabelTypography = styled(Typography)`
  font-size: 11px;
  // color: #a3a3a3;
  color: ${(props) => (props.error ? 'red' : '#a3a3a3')};
  padding-right: 5px;
  margin-top: 5px;
`;

const ValueTypography = styled(Typography)`
  // font-size: 15px;
  font-size: ${(props) => (props.loading ? '13px' : '15px')};
  // color: #111111;
  color: ${(props) => (props.error ? 'red' : '#111111')};
  font-weight: 600;
`;

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 32,
  height: 20,
  padding: 0,
  marginLeft: '10px',
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '4px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 16,
    height: 16
  },
  '& .MuiSwitch-track': {
    borderRadius: 10,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}));

function CreateNewCampaign({ id, template, isDialogOpen, onClose, customer }) {
  const theme = useTheme();
  const { businessProfileData, usedMessageQuota, wappBusiness } = useSelector(
    (state) => state.wapp
  );

  const { isAuthenticated } = useAuth();

  let selectedSegmentIdFromArgument = id;
  let selectedTemplateFromArgument = template;
  const navigate = useNavigate();

  const [selectedSegmentFromDropdown, setSelectedSegmentFromDropdown] =
    useState('');

  const [templates, setTemplates] = useState([]);
  const [segments, setSegments] = useState([]);
  const [memberCount, setMemberCount] = useState(0);

  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedBroadcast, setSelectedBroadcast] = useState([]);
  const [selectedTemplatesList, setSelectedTemplatesList] = useState([]);
  const [broadcastName, setBroadcastName] = useState('');
  const [selectedImage, setSelectedImage] = useState('');
  const [selectedTemplateId, setSelectedTemplateId] = useState('');
  const [headerText, setHeaderText] = useState([]);
  const [bodyText, setBodyText] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [teamMembers, setTeamMembers] = useState([]);
  const [selectedTeamMember, setSelectedTeamMember] = useState('');
  const [selectedTeamMemberList, setSelectedTeamMemberList] = useState('');
  // const [selectedFile, setSelectedFile] = useState(null); //  for upload customers csv
  const [allMedia, setAllMedia] = useState([]);
  const [mediaAvailablity, setMediaAvailablity] = useState('available');
  const [selectedMediaId, setSelectedMediaId] = useState('');
  const [selectedBroadcastCustomers, setSelectedBroadcastCustomers] = useState(
    []
  );

  const [broadcastAmount, setBroadcastAmount] = useState(0);
  const [currency, setCurrency] = useState('');
  const [loadingEstimatedAmount, setLoadingEstimatedAmount] = useState(false);
  const [registeredNumbers, setRegisteredNumbers] = useState([]);
  const [selectedNumber, setSelectedNumber] = useState('');

  // For allowing and hiding Premium feature
  const [isAllow, SetIsAllow] = useState(false);

  const broadcastAbortController = useRef(null); // Ref to hold the abort controller for broadcasts
  const templateAbortController = useRef(null);

  const isError =
    Number(wappBusiness?.wallet_balance.toFixed(0)) <
    Number(broadcastAmount?.toFixed(0));

  // Fetching Data from Context
  useEffect(() => {
    if (isAuthenticated) {
      // const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
      const userInfo = getDecryptedData('userInfo') || null;
      if (userInfo.planName === 'Premium') {
        SetIsAllow(true);
      }
      setCurrency(userInfo?.currency);
      // Use userInfo as needed in your component
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const template = selectedTemplate || selectedTemplateFromArgument;

    if (
      template &&
      template.header_media_id.length > 0 &&
      template.template_header &&
      template.template_header !== 'text'
    ) {
      const templateMediaId = template.header_media_id[0];

      const mediaItem = allMedia.find((media) => media._id === templateMediaId);

      if (mediaItem) {
        if (!mediaItem.expired) {
          setMediaAvailablity('available');
        } else {
          setMediaAvailablity('notAvailable');
        }
      } else {
        setMediaAvailablity('notAvailable');
      }
    } else {
      setMediaAvailablity('');
    }
  }, [selectedTemplate, selectedTemplateFromArgument, template]);
  //
  //

  //  fetch all medias
  const fetchAllMedia = async () => {
    try {
      const response = await ApiCalls.listMedia();

      if (response?.data && response?.data.length > 0) {
        setAllMedia(response.data);
      } else {
        setAllMedia([]);
      }
    } catch (error) {
      console.error('Error fetching media:', error);
    } finally {
      setLoading(false);
    }
  };

  const checkMediaAvailability = (mediaId) => {
    const mediaItem = allMedia.find((media) => media._id === mediaId);

    if (mediaItem) {
      // Check if the media item is not expired
      if (!mediaItem.expired) {
        setMediaAvailablity('available');
        return true; // Media is available
      } else {
        setMediaAvailablity('notAvailable');
        return false; // Media is expired
      }
    } else {
      setMediaAvailablity('notAvailable');
      return false; // Media not found
    }
  };

  //  store selected media id
  // const handleMediaSelect = (mediaId) => {
  //   setSelectedMediaId(mediaId);
  // };
  // Store selected media ID
  const handleMediaSelect = (mediaId) => {
    // Check if the media is available
    const isMediaAvailable = checkMediaAvailability(mediaId);

    if (isMediaAvailable) {
      // Update selected media ID if available
      setSelectedMediaId(mediaId);
    } else {
      console.error('Selected media is not available or has expired.');
      // Optionally, reset selected media ID or handle the error
      setSelectedMediaId(null);
    }
  };

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  //  For select campaign image
  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
  };

  //  For business profile data
  function getMessageLimit(tierName) {
    const limits = {
      TIER_50: 'Tier 1',
      TIER_250: 'Tier 1',
      TIER_1K: 'Tier 1',
      TIER_10K: 'Tier 2',
      TIER_100K: 'Tier 3',
      TIER_UNLIMITED: 'unlimited'
    };
    return limits[tierName] || 'unknown'; // Return 'unknown' or some default value if tier is not matched
  }

  const MessagingTier = getMessageLimit(
    businessProfileData?.tier_name === 'BLOCKED'
      ? '---'
      : businessProfileData?.tier_name
  );

  // Fetch all templates

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await TemplateApiCalls.listAllTemplates(
          `&phone_number_id=${businessProfileData?.phone_number_id}`
        );
        if (response?.status === 200) {
          if (response?.data) {
            const approvedTemplates = response.data.filter(
              (template) =>
                template.status === 'APPROVED' &&
                template.category !== 'AUTHENTICATION'
            );
            const options = approvedTemplates
              ? approvedTemplates.map(
                  ({ _id, template_name, category, ...rest }) => ({
                    value: _id,
                    label: `${template_name} (${category.toLowerCase()})`,
                    // label: (
                    //   <div
                    //     style={{
                    //       display: 'flex'
                    //     }}
                    //   >
                    //     <span style={{ fontWeight: 'normal' }}>
                    //       {template_name}
                    //     </span>
                    //     <span
                    //       style={{
                    //         color: '#27AB69',
                    //         marginLeft: '5px'
                    //       }}
                    //     >
                    //       ({category.toLowerCase()})
                    //     </span>
                    //   </div>
                    // ),
                    template_name,
                    category,
                    ...rest
                  })
                )
              : [];
            setTemplates(options);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        // Set loading to false once the data is fetched or an error occurs
      }
    };
    if (!template) {
      fetchData();
    }
  }, [businessProfileData]);

  const handleTemplateClick = async (template) => {
    const response = await TemplateApiCalls.getTemplatesById(template);
    setSelectedTemplate(response?.data);
  };

  //  fetching variables from selected template
  useEffect(() => {
    if (selectedTemplate && selectedTemplate.components) {
      selectedTemplate.components.forEach((element) => {
        if (
          element.type === 'HEADER' &&
          element.example &&
          element.example.header_text
        ) {
          const values = element.example.header_text;
          setHeaderText(values);
        }
        if (
          element.type === 'BODY' &&
          element.example &&
          element.example.body_text &&
          element.example.body_text.length > 0
        ) {
          const values = element.example?.body_text[0];
          setBodyText(values);
        }
      });
    }
  }, [selectedTemplate]);

  //   For Dailogbox close
  const handleDialogClose = () => {
    setBroadcastAmount(0);
    setLoading(false);
    setBroadcastName('');
    setSelectedImage('');
    setSelectedTemplateId('');
    setSelectedTeamMember('');
    setSelectedTemplate(null);
    setSelectedSegmentFromDropdown('');
    setSelectedTeamMemberList([]);
    setMediaAvailablity('');
    setSelectedMediaId('');
    setSelectedDate(null);
    setSelectedTime(null);
    setSelectedTemplatesList([]);
    setSelectedBroadcast([]);
    onClose();
  };

  //   For sending template (create campaign)
  const handleSendTemplate = async () => {
    let scheduledAt = null;

    if (mediaAvailablity === 'notAvailable' && selectedMediaId == '') {
      Toast({
        message: 'Media in template expired You have to choose one media',
        type: 'error'
      });
      return;
    }

    if (
      checked === true &&
      (!(selectedDate instanceof Date) ||
        !(selectedTime instanceof Date) ||
        isNaN(selectedDate) ||
        isNaN(selectedTime))
    ) {
      Toast({
        message:
          'Please fill in both the date and time as you have chosen to schedule.',
        type: 'error'
      });
      return;
    }

    if (selectedDate && selectedTime) {
      scheduledAt = new Date(selectedDate);
      scheduledAt.setHours(selectedTime.getHours());
      scheduledAt.setMinutes(selectedTime.getMinutes());
      scheduledAt.setSeconds(selectedTime.getSeconds());
    }
    const selectedSegment =
      selectedSegmentIdFromArgument || selectedSegmentFromDropdown;
    if (
      (!selectedTemplate && !selectedTemplateFromArgument) ||
      !selectedSegment ||
      !broadcastName
    ) {
      Toast({ message: 'Missing required fields', type: 'error' });
      return;
    }

    setLoading(true);
    const response = await TemplateApiCalls.sendSegmentId(
      selectedTemplate?.template_id || selectedTemplateFromArgument.template_id,
      selectedSegmentIdFromArgument || selectedSegmentFromDropdown,
      selectedImage,
      broadcastName,
      scheduledAt,
      selectedTeamMember,
      selectedMediaId,
      selectedNumber?.phone_number_id
    );

    if (response?.status === 200 && response?.error === true) {
      setLoading(false);
      Toast({ type: 'error', message: response.data });
    } else if (
      response?.status == 200 &&
      response?.error === false &&
      response?.data?.scheduledAt
    ) {
      setLoading(false);
      Toast({ type: 'success', message: response?.message });
      navigate('/AllCampaigns');
    } else if (response?.status == 200 && response?.error === false) {
      setLoading(false);
      sessionStorage.setItem(
        'selectedCampaign',
        JSON.stringify(response?.data)
      );
      navigate('/campaignDetails/' + response?.data);
      Toast({ type: 'success', message: 'message sent successfully' });
    } else {
      onClose();
      setLoading(false);
      // console.error('Error sending template:', response?.error);
      Toast({ type: 'error', message: response?.data });
    }
    handleDialogClose();
  };

  useEffect(() => {
    const selectedSegmentId =
      selectedSegmentIdFromArgument || selectedSegmentFromDropdown;

    const selectedSegment = segments.find(
      (segment) => segment.value === selectedSegmentId
    );

    if (selectedSegment) {
      const membersCount = selectedSegment?.members?.length;
      // Store the members count in state
      setMemberCount(membersCount);
      setSelectedBroadcastCustomers([...selectedSegment?.members]);
      // calculateCharges([...selectedSegment?.members]);
    }
  }, [customer, selectedSegmentIdFromArgument, segments]);

  // Function to calculate charges
  const calculateCharges = async (category, customers, signal) => {
    try {
      setLoadingEstimatedAmount(true);
      const res = await ApiCalls.getEstimatedAmount(
        // selectedBroadcastCustomers,
        customers,
        category,
        currency,
        signal
      );

      if (res?.data !== null) {
        setBroadcastAmount(res?.data);
        setLoadingEstimatedAmount(false);
      } else {
        Toast({
          message: 'Error while calculating the estimated amount',
          type: 'error'
        });
      }
    } catch (error) {
      setLoadingEstimatedAmount(false);
      console.error('Error calculating charges:', error);
    }
  };

  const updateSelectedSegment = (segmentId, category, signal) => {
    // Find the selected segment based on the ID
    const selectedSegment = segments.find(
      (segment) => segment.value === segmentId || id
    );

    if (selectedSegment) {
      const membersCount = selectedSegment?.members?.length;

      // Update state directly
      setMemberCount(membersCount); // Update member count
      setSelectedBroadcastCustomers([...selectedSegment.members]); // Update customers

      if (category) {
        calculateCharges(category, [...selectedSegment.members], signal);
      }

      if (template && template?.category) {
        calculateCharges(
          template?.category,
          [...selectedSegment.members],
          signal
        );
      }
    } else {
      // Reset if no segment is found
      setMemberCount(0);
      setSelectedBroadcastCustomers([]); // Empty customers
    }
  };

  //  Check the argument have brodcast id or not
  const fetchData = async () => {
    try {
      const response = await ApiCalls.listSegments();
      if (response) {
        const options = response.map(({ _id, name, ...rest }) => ({
          value: _id,
          label: name,
          ...rest
        }));
        setSegments(options);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Fetch al team members
  const getAllUsers = async () => {
    const data = await ApiCalls.listAllUsers('idAndName');
    const options = data.data.map(({ _id, name, ...rest }) => ({
      value: _id,
      label: name,
      ...rest
    }));
    setTeamMembers(options);
  };

  const fetchRegisteredNumbers = async () => {
    try {
      const response = await ApiCalls.getRegisteredBusiness();
      if (response?.data) {
        const options = response?.data
          ? response?.data
              ?.filter((phone) => phone.pin)
              .map(({ _id, phone, phone_number_id, ...rest }) => ({
                value: phone_number_id,
                label: phone,
                phone,
                phone_number_id,
                ...rest
              }))
          : [];
        setRegisteredNumbers(options);

        const activeNumber = response?.data
          ? response?.data
              ?.filter((phone) => phone.status === 'active')
              .map(({ _id, phone, phone_number_id, ...rest }) => ({
                value: phone_number_id,
                label: phone,
                phone,
                phone_number_id,
                ...rest
              }))
          : [];

        setSelectedNumber(activeNumber[0]);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getAllUsers();
    fetchAllMedia();
    fetchRegisteredNumbers();
  }, []);

  useEffect(() => {
    fetchData();
  }, [selectedSegmentIdFromArgument]);

  const handleBroadcastSelect = (broadcast) => {
    // Cancel the previous broadcast request if it exists
    if (broadcastAbortController.current) {
      // setLoadingEstimatedAmount(true);
      broadcastAbortController.current.abort();
      templateAbortController.current &&
        templateAbortController.current.abort();
    }

    // Create a new abort controller
    broadcastAbortController.current = new AbortController();

    setSelectedBroadcast(broadcast);
    setSelectedSegmentFromDropdown(broadcast.value);

    // Call the function to update customers and member count
    updateSelectedSegment(
      broadcast.value,
      selectedTemplatesList.category,
      broadcastAbortController.current.signal
    );
  };

  const handleTemplateSelect = (template) => {
    // Cancel the previous template request if it exists
    if (templateAbortController.current) {
      // setLoadingEstimatedAmount(true);
      templateAbortController.current.abort();
      broadcastAbortController.current &&
        broadcastAbortController.current.abort();
    }

    // Create a new abort controller
    templateAbortController.current = new AbortController();

    setSelectedTemplatesList(template);
    // Call the function to update customers and member count
    updateSelectedSegment(
      selectedBroadcast.value,
      template.category,
      templateAbortController.current.signal
    );

    handleTemplateClick(template.value);
  };

  // useEffect(() => {
  //   if (selectedTemplatesList.category) {
  //     calculateCharges(selectedTemplatesList.category);
  //   }
  // }, [selectedBroadcast, selectedTemplatesList.category]);

  const handleAssignCampaignSelect = (campaign) => {
    setSelectedTeamMemberList(campaign);
    setSelectedTeamMember(campaign.value);
  };

  const handleNumberChange = (number) => {
    console.log('Selected Number : ', number);
    setSelectedNumber(number);
  };

  return (
    <>
      {/* Dialog Box */}
      <AnimatePresence>
        {isDialogOpen && (
          <Dialog
            open={isDialogOpen}
            // onClose={handleDialogClose}
            maxWidth="lg"
            PaperProps={{
              style: { backgroundColor: 'transparent', boxShadow: 'none' }
            }}
          >
            <motion.div
              initial={{ opacity: 0, scale: 0.6 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.6 }}
              transition={{
                duration: 0.3
              }}
            >
              <Box sx={{ bgcolor: '#F2F2F5', borderRadius: '8px' }}>
                <DialogTitle
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between', // Adjust alignment
                    fontSize: 18,
                    fontWeight: 500,
                    color: '#111111'
                  }}
                >
                  Create Campaign
                  <IconButton onClick={handleDialogClose}>
                    <img
                      src={closeIcon}
                      alt="Close"
                      sx={{ height: '40px', width: '40px' }}
                    />
                  </IconButton>
                </DialogTitle>
                <DialogContent
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    padding: { xs: '5px', md: '15px', lg: '20px' },
                    overflowY: 'auto'
                  }}
                >
                  <Box sx={{ maxWidth: '580px' }}>
                    <BoxWrapper>
                      {/* <WhatsappInfo/> */}
                      {/* <CustomBox theme={theme}>
                <ValueTypography>
                  {businessProfileData?.quality_rating === 'GREEN'
                    ? 'High'
                    : businessProfileData?.quality_rating === 'YELLOW'
                    ? 'Medium'
                    : businessProfileData?.quality_rating === 'RED'
                    ? 'Low'
                    : '---'}
                </ValueTypography>
                <LabelTypography>Quality rating</LabelTypography>
              </CustomBox> */}

                      <CustomBox theme={theme}>
                        <ValueTypography>
                          {usedMessageQuota} /{' '}
                          {businessProfileData?.message_limit === 'unknown'
                            ? '---'
                            : businessProfileData?.message_limit}
                        </ValueTypography>
                        <LabelTypography>Used Quota</LabelTypography>
                      </CustomBox>
                      <CustomBox theme={theme}>
                        <ValueTypography> {memberCount}</ValueTypography>
                        <LabelTypography>Selected Customers</LabelTypography>
                      </CustomBox>

                      <CustomBox theme={theme}>
                        <ValueTypography error={isError}>
                          {currency === 'INR' ? '₹ ' : '$ '}
                          {wappBusiness?.wallet_balance.toFixed(0)}
                        </ValueTypography>
                        <LabelTypography error={isError}>
                          Wallet Amount
                        </LabelTypography>
                      </CustomBox>

                      <CustomBox theme={theme}>
                        {/* <ValueTypography>{MessagingTier}</ValueTypography> */}
                        <ValueTypography loading={loadingEstimatedAmount}>
                          {currency === 'INR' ? '₹ ' : '$ '}
                          {loadingEstimatedAmount
                            ? 'Loading...'
                            : broadcastAmount > 0 && broadcastAmount < 1
                            ? 1
                            : broadcastAmount?.toFixed(0)}
                        </ValueTypography>
                        <LabelTypography>Estimated Amount</LabelTypography>
                      </CustomBox>
                      {/* <CustomBox theme={theme}>
                <ValueTypography>300</ValueTypography>
                <LabelTypography>Charge Amount</LabelTypography>
              </CustomBox> */}
                    </BoxWrapper>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                      <Grid
                        container
                        spacing={1}
                        justifyContent="start"
                        sx={{
                          // p: { md: 1, lg: 1 },
                          flexWrap: 'wrap',
                          flexDirection: 'row',
                          mt: '5px'
                        }}
                      >
                        <Grid item xs={12} sm={6} width="100%">
                          <Headings>
                            Campaign Name{' '}
                            <span className="required-asterisk">*</span>
                          </Headings>
                          <CustomTextField
                            fullWidth
                            placeholder="Campaign name"
                            value={broadcastName}
                            onChange={(e) => setBroadcastName(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} width="100%">
                          <Headings>Campaign Image </Headings>
                          <label
                            style={{
                              display: 'block',
                              position: 'relative',
                              height: '45px',
                              backgroundColor: '#FFFFFF',
                              borderRadius: '10px',
                              border: '1px solid #ececec',
                              padding: '10px 20px',
                              boxSizing: 'border-box'
                            }}
                          >
                            <input
                              type="file"
                              placeholder="Campaign Name"
                              accept="image/*"
                              onChange={handleFileSelect}
                              style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                opacity: 0,
                                cursor: 'pointer'
                              }}
                            />
                            <span
                              style={{
                                position: 'absolute',
                                top: '50%',
                                right: '20px',
                                transform: 'translateY(-50%)',
                                pointerEvents: 'none'
                              }}
                            >
                              <img
                                src={SelectImage}
                                alt="Select"
                                style={{ width: '24px', height: '24px' }}
                              />
                            </span>
                            {selectedImage ? (
                              <span
                                style={{
                                  color: '#111111',
                                  position: 'absolute',
                                  top: '50%',
                                  left: '20px',
                                  transform: 'translateY(-50%)',
                                  pointerEvents: 'none',
                                  fontSize: '14px'
                                }}
                              >
                                {selectedImage.name}
                              </span>
                            ) : (
                              <span
                                style={{
                                  color: '#A3A3A3',
                                  position: 'absolute',
                                  top: '50%',
                                  left: '15px',
                                  transform: 'translateY(-50%)',
                                  pointerEvents: 'none',
                                  fontSize: '13px'
                                }}
                              >
                                Select image
                              </span>
                            )}
                          </label>
                        </Grid>
                        {/* Segment selection dropdown */}
                        {!id && (
                          <Grid item xs={12} sm={6} width="100%">
                            <Headings>
                              Select Broadcast{' '}
                              <span className="required-asterisk">*</span>
                            </Headings>

                            <Select
                              options={segments}
                              value={selectedBroadcast}
                              onChange={handleBroadcastSelect}
                              placeholder="Select Broadcast"
                              isSearchable
                              styles={customStyles}
                              menuPosition="fixed"
                              menuPlacement="auto"
                              components={{ DropdownIndicator: null }}
                            />
                          </Grid>
                        )}
                        {!template && (
                          <Grid item xs={12} sm={6} width="100%">
                            <>
                              <Headings>
                                Select Template{' '}
                                <span className="required-asterisk">*</span>
                              </Headings>
                              <Select
                                options={templates}
                                value={selectedTemplatesList}
                                onChange={handleTemplateSelect}
                                placeholder="Select Template"
                                isSearchable
                                styles={customStyles}
                                menuPosition="fixed"
                                menuPlacement="auto"
                                components={{ DropdownIndicator: null }}
                                formatOptionLabel={({
                                  template_name,
                                  category
                                }) => (
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}
                                  >
                                    <span style={{ fontWeight: 'normal' }}>
                                      {template_name}
                                    </span>
                                    <span
                                      style={{
                                        color: '#27AB69',
                                        marginLeft: '5px',
                                        fontSize: '13px'
                                      }}
                                    >
                                      ({category?.toLowerCase()})
                                    </span>
                                  </div>
                                )}
                              />
                            </>
                          </Grid>
                        )}
                        {/* Assign Campaign */}

                        <Grid item xs={12} sm={6} width="100%">
                          {/* {!template && ( */}
                          <>
                            <Headings>Assign campaign</Headings>
                            <Select
                              options={teamMembers}
                              value={selectedTeamMemberList}
                              onChange={handleAssignCampaignSelect}
                              placeholder="Select Assign Campaign"
                              isSearchable
                              styles={customStyles}
                              menuPosition="fixed"
                              menuPlacement="auto"
                              components={{ DropdownIndicator: null }}
                            />
                          </>
                          {/* )} */}
                        </Grid>

                        <Grid item xs={12} sm={6} width="100%">
                          {/* {!template && ( */}
                          <>
                            <Headings>Select Number</Headings>
                            <Select
                              options={registeredNumbers}
                              value={selectedNumber}
                              onChange={handleNumberChange}
                              placeholder="Select Number"
                              isSearchable
                              styles={customStyles}
                              menuPosition="fixed"
                              menuPlacement="auto"
                              components={{ DropdownIndicator: null }}
                            />
                          </>
                          {/* )} */}
                        </Grid>

                        {/* For uplaod csv */}
                        {/* <Grid item xs={12} sm={6} width="100%">
                  <Headings>Upload CSV</Headings>

                  <label
                    style={{
                      display: 'block',
                      position: 'relative',
                      height: '45px',
                      backgroundColor: '#FFFFFF',
                      borderRadius: '10px',
                      border: '1px solid #ced4da',
                      padding: '10px 20px',
                      boxSizing: 'border-box'
                    }}
                  >
                    <input
                      type="file"
                      accept=".csv"
                      onChange={handleCsvSelect}
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        opacity: 0,
                        cursor: 'pointer'
                      }}
                    />
                    <span
                      style={{
                        position: 'absolute',
                        top: '50%',
                        right: '20px',
                        transform: 'translateY(-50%)',
                        pointerEvents: 'none'
                      }}
                    >
                      <img
                        src={FileIcon}
                        alt="Select"
                        style={{ width: '24px', height: '24px' }}
                      />
                    </span>
                    {selectedFile ? (
                      <span
                        style={{
                          color: '#111111',
                          position: 'absolute',
                          top: '50%',
                          left: '20px',
                          transform: 'translateY(-50%)',
                          pointerEvents: 'none',
                          fontSize: '14px'
                        }}
                      >
                        {selectedFile.name}
                      </span>
                    ) : (
                      <span
                        style={{
                          color: '#A3A3A3',
                          position: 'absolute',
                          top: '50%',
                          left: '15px',
                          transform: 'translateY(-50%)',
                          pointerEvents: 'none',
                          fontSize: '14px'
                        }}
                      >
                        Select CSV file
                      </span>
                    )}
                  </label>
                </Grid> */}

                        {isAllow && (
                          <Grid item xs={12} width="100%">
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                // justifyContent:'flex-start',
                                mt: '10px',
                                mb: '5px'
                                // ml: '9px'
                              }}
                            >
                              <Typography
                                sx={{
                                  color: '#111111',
                                  fontSize: '14px',
                                  fontWeight: 500
                                }}
                              >
                                Schedule Date & Time
                              </Typography>
                              <IOSSwitch
                                checked={checked}
                                onChange={handleChange}
                              />
                            </Box>
                          </Grid>
                        )}

                        <Grid item xs={12} sm={6} width="100%">
                          {checked && (
                            <Stack spacing={1}>
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                {window.innerWidth < 600 ? ( // For mobile devices
                                  <>
                                    <MobileDatePicker
                                      placeholder="Select Date"
                                      minDate={new Date()}
                                      value={selectedDate}
                                      onChange={(newValue) =>
                                        setSelectedDate(newValue)
                                      }
                                      TextField={(params) => (
                                        <CustomDateTimePickerField
                                          {...params}
                                        />
                                      )}
                                    />
                                  </>
                                ) : (
                                  // For desktop devices
                                  <>
                                    <DesktopDatePicker
                                      minDate={new Date()}
                                      placeholder="Select Date"
                                      value={selectedDate}
                                      onChange={(newValue) =>
                                        setSelectedDate(newValue)
                                      }
                                      sx={{
                                        '& .MuiOutlinedInput-notchedOutline': {
                                          borderColor: '#ececec',
                                          backgroundColor: '#ffffff',
                                          height: 50
                                        },
                                        '& .MuiInputBase-inputAdornedEnd': {
                                          zIndex: 1,
                                          fontSize: 12,
                                          padding: '14px'
                                        },
                                        '& .MuiInputAdornment-sizeMedium ': {
                                          zIndex: 1
                                        },
                                        '& .MuiSvgIcon-fontSizeMedium': {
                                          height: 18
                                        }
                                      }}
                                      TextField={(params) => (
                                        <CustomDateTimePickerField
                                          {...params}
                                        />
                                      )}
                                    />
                                  </>
                                )}
                              </LocalizationProvider>
                            </Stack>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6} width="100%">
                          {checked && (
                            <Stack spacing={1}>
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                {window.innerWidth < 600 ? ( // For mobile devices
                                  <>
                                    <MobileTimePicker
                                      placeholder="Select Time"
                                      value={selectedTime}
                                      onChange={(newValue) =>
                                        setSelectedTime(newValue)
                                      }
                                      TextField={(params) => (
                                        <CustomDateTimePickerField
                                          {...params}
                                        />
                                      )}
                                    />
                                  </>
                                ) : (
                                  // For desktop devices
                                  <>
                                    <DesktopTimePicker
                                      placeholder="Select Time"
                                      value={selectedTime}
                                      onChange={(newValue) =>
                                        setSelectedTime(newValue)
                                      }
                                      sx={{
                                        '& .MuiOutlinedInput-notchedOutline': {
                                          borderColor: '#ececec',
                                          backgroundColor: '#ffffff',
                                          height: 50
                                        },
                                        '& .MuiInputBase-inputAdornedEnd': {
                                          zIndex: 1,
                                          fontSize: 12,
                                          padding: '14px'
                                        },
                                        '& .MuiInputAdornment-sizeMedium ': {
                                          zIndex: 1
                                        },
                                        '& .MuiSvgIcon-fontSizeMedium': {
                                          height: 18
                                        }
                                      }}
                                      TextField={(params) => (
                                        <CustomDateTimePickerField
                                          {...params}
                                        />
                                      )}
                                    />
                                  </>
                                )}
                              </LocalizationProvider>
                            </Stack>
                          )}
                        </Grid>

                        {/* {checked && (
                  <Stack spacing={1}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      {window.innerWidth < 600 ? ( // For mobile devices
                        <>
                          <MobileDatePicker
                            placeholder="Select Date"
                            minDate={new Date()}
                            value={selectedDate}
                            onChange={(newValue) => setSelectedDate(newValue)}
                            renderInput={(params) => (
                              <CustomTextFields {...params} />
                            )}
                          />
                          <MobileTimePicker
                            placeholder="Select Time"
                            value={selectedTime}
                            onChange={(newValue) => setSelectedTime(newValue)}
                            renderInput={(params) => (
                              <CustomTextFields {...params} />
                            )}
                          />
                        </>
                      ) : (
                        // For desktop devices
                        <>
                          <DesktopDatePicker
                            minDate={new Date()}
                            placeholder="Select Date"
                            value={selectedDate}
                            onChange={(newValue) => setSelectedDate(newValue)}
                            renderInput={(params) => (
                              <CustomTextFields {...params} />
                            )}
                          />
                          <DesktopTimePicker
                            placeholder="Select Time"
                            value={selectedTime}
                            onChange={(newValue) => setSelectedTime(newValue)}
                            renderInput={(params) => (
                              <CustomTextFields {...params} />
                            )}
                          />
                        </>
                      )}
                    </LocalizationProvider>
                  </Stack>
                )} */}
                        {/* </Box> */}
                        <Grid item xs={12} width="100%">
                          {/* <CustomTooltip
                    title={
                      memberCount === 0 ? 'No customers in this broadcast' : ''
                    }
                    placement="top"
                    arrow
                  >
                    <SendButton
                      fullWidth
                      onClick={handleSendTemplate}
                      disabled={memberCount === 0 || loading}
                    >
                      {loading ? (
                        <Loader />
                      ) : (
                        <Typography
                          sx={{
                            fontWeight: 500,
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          Send
                          <img
                            src={sendIcon}
                            alt="Send"
                            style={{
                              marginLeft: '5px',
                              width: '20px',
                              height: '20px'
                            }}
                          />
                        </Typography>
                      )}
                    </SendButton>
                  </CustomTooltip> */}
                          <CustomTooltip
                            title={
                              memberCount === 0
                                ? 'No customers in this broadcast'
                                : wappBusiness?.wallet_balance <= 0 ||
                                  wappBusiness?.wallet_balance < broadcastAmount
                                ? 'Your wallet amount is not sufficient'
                                : memberCount >
                                  businessProfileData?.message_limit -
                                    usedMessageQuota
                                ? 'Message Quota is not sufficient'
                                : ''
                            }
                            placement="top"
                            arrow
                          >
                            <span>
                              {' '}
                              {/* Use span to wrap the button for disabled tooltip */}
                              <SendButton
                                fullWidth
                                onClick={handleSendTemplate}
                                disabled={
                                  memberCount === 0 ||
                                  loading ||
                                  loadingEstimatedAmount ||
                                  wappBusiness?.wallet_balance <= 0 ||
                                  wappBusiness?.wallet_balance < broadcastAmount
                                }
                                sx={{
                                  cursor:
                                    memberCount === 0 ||
                                    wappBusiness?.wallet_balance <= 0 ||
                                    wappBusiness?.wallet_balance <
                                      broadcastAmount ||
                                    memberCount >
                                      businessProfileData?.message_limit -
                                        usedMessageQuota ||
                                    loadingEstimatedAmount
                                      ? 'not-allowed'
                                      : 'pointer',
                                  opacity:
                                    memberCount === 0 ||
                                    wappBusiness?.wallet_balance <= 0 ||
                                    wappBusiness?.wallet_balance <
                                      broadcastAmount ||
                                    memberCount >
                                      businessProfileData?.message_limit -
                                        usedMessageQuota ||
                                    loadingEstimatedAmount
                                      ? 0.6
                                      : 1 // Add opacity for disabled state
                                }}
                              >
                                {loading ? (
                                  <Loader />
                                ) : (
                                  <Typography
                                    sx={{
                                      fontWeight: 500,
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}
                                  >
                                    {checked
                                      ? 'Schedule'
                                      : loadingEstimatedAmount
                                      ? 'Calculating estimated amount'
                                      : 'Send'}
                                    <img
                                      src={sendIcon}
                                      alt="Send"
                                      style={{
                                        marginLeft: '5px',
                                        width: '20px',
                                        height: '20px'
                                      }}
                                    />
                                  </Typography>
                                )}
                              </SendButton>
                            </span>
                          </CustomTooltip>
                        </Grid>
                        {/* </Grid> */}

                        {/* Right Column (Template Details Placeholder) */}
                        {/* <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    minWidth: '270px',
                    overflowY: 'scroll',
                    maxHeight: '390px'
                  }}
                > */}
                        {/* <Box pb={2}>
                    {(selectedTemplate || selectedTemplateFromArgument) && (
                      <>
                        <TemplatePreviews
                          data={
                            selectedTemplate || selectedTemplateFromArgument
                          }
                        />
                      </>
                    )}
                  </Box> */}
                        {/* </Grid> */}
                      </Grid>
                    </Box>
                  </Box>

                  {/* RightSide */}
                  <Box
                    minWidth="300px"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      // justifyContent: 'center',
                      pt: { xs: '10px', md: '0px' },
                      ml: { md: '10px' },
                      maxHeight: '520px',
                      minHeight: '500px',
                      overflowY: 'auto'
                    }}
                  >
                    {mediaAvailablity === 'notAvailable' ? (
                      <Box pt={2}>
                        <Typography
                          sx={{
                            maxWidth: '290px',
                            px: '10px',
                            color: '#FF2F2F'
                          }}
                        >
                          The media used for this template has expired. Please
                          choose another media.
                        </Typography>
                      </Box>
                    ) : (
                      ''
                    )}
                    <Box pb={2}>
                      {(selectedTemplate || selectedTemplateFromArgument) && (
                        <>
                          {/* Preview starts from here */}
                          <TemplateBg
                            width={'300px'}
                            data={
                              selectedTemplate || selectedTemplateFromArgument
                            }
                            mediaAvailablity={true}
                            onMediaSelect={handleMediaSelect}
                          />
                        </>
                      )}
                    </Box>
                  </Box>
                </DialogContent>
              </Box>
            </motion.div>
          </Dialog>
        )}
      </AnimatePresence>
    </>
  );
}

export default CreateNewCampaign;
