import ApiCalls from 'src/utility/ApiCalls';
import AnalyticsApiCalls from 'src/utility/AnalyticsApiCalls';
import { addAction, removeAction } from '../features/wappSlice';
import BusinessApiCalls from 'src/utility/BusinessApiCalls';
import RazorPayApiCalls from 'src/utility/RazorPayApiCalls';
import AutomationApiCalls from 'src/utility/AutomationApiCalls';

export const getRegisteredBusiness = async (dispatch) => {
  try {
    const response = await ApiCalls.getRegisteredBusiness();

    const data = response?.data?.find((number) => number.status === 'active');

    if (response?.data.length !== 0) {
      dispatch(addAction({ payload: data, type: 'businessProfileData' }));
    }
  } catch (error) {
    console.log(error);
  }
};
export const getUnreadOrderCountFun = async (dispatch) => {
  try {
    const response = await AnalyticsApiCalls.getUnreadOrderCount();
    dispatch(addAction({ payload: response?.data, type: 'unReadOrder' }));
  } catch (error) {
    console.log(error);
  }
};
export const getUnreadChatCounts = async (dispatch) => {
  try {
    const response = await AnalyticsApiCalls.unreadChatCounts();

    // if (response?.data) {
    //

    //   dispatch(addAction({ payload: response.data, type: 'setUnreadChatCount' }));
    // }
    // if (Array.isArray(response?.data?.unarchived) && response?.data?.unarchived.length > 0) {
    //
    dispatch(
      addAction({
        payload: response?.data?.unarchived,
        type: 'setUnreadChatCount'
      })
    );

    dispatch(
      addAction({
        payload: response?.data?.archived,
        type: 'setUnreadArchivedChatCounts'
      })
    );
    // } else {
    //   //
    //   dispatch(addAction({ payload: [], type: 'setUnreadChatCount' }));
    //   dispatch(addAction({ payload: [], type: 'setUnreadArchivedChatCounts' }));
    // }
  } catch (error) {
    console.log(error);
  }
};

export const getUnreadNotification = async (dispatch) => {
  try {
    const response = await AnalyticsApiCalls.getUnreadNotificationCount();
    if (response?.data) {
      dispatch(
        addAction({ payload: response?.data, type: 'unreadNotification' })
      );
    }
  } catch (error) {
    console.log(error);
  }
};

export const getBusinessProfileData = async (dispatch) => {
  try {
    const response = await BusinessApiCalls.fetchBusinessProfileData();
    if (response?.data?.length > 0) {
      dispatch(addAction({ payload: response?.data[0], type: 'business' }));
    }
  } catch (error) {
    console.log(error);
  }
};

export const getUsedMessageQuota = async (dispatch) => {
  try {
    const response = await AnalyticsApiCalls.usedMessageQuota();
    if (response) {
      dispatch(
        addAction({
          payload: response?.data?.totalDistinctMessages,
          type: 'usedMessageQuota'
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
};

export const getFreeTierConversations = async (dispatch) => {
  try {
    const response = await AnalyticsApiCalls.freeTierConversations();
    if (response) {
      dispatch(
        addAction({
          payload: response?.data?.count,
          type: 'freeTierConversations'
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
};

// export const getUserPermissions = async (dispatch) => {
//   try {
//     const response = await ApiCalls.getPermissions();
//
//     if (response?.data.length > 0) {
//       dispatch(addAction({ payload: response?.data, type: 'permission' }));
//     }
//   } catch (error) {
//
//   }
// }

export const getSubscriptionExists = async (dispatch) => {
  try {
    const response = await RazorPayApiCalls.getSubscriptionExists();
    if (response?.data) {
      dispatch(
        addAction({ payload: response?.data, type: 'subscriptionsCounts' })
      );
    }
  } catch (error) {
    console.log(error);
  }
};

// fetch attributes for to show on build chatbot page
export const fetchChatbotAttributes = async (dispatch) => {
  try {
    const response = await AutomationApiCalls.listChatbotAttributes();

    if (response) {
      dispatch(
        addAction({
          payload: response?.data[0].attributes,
          type: 'addAutomationAttributes'
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
};

// fetch attributes for to show on build chatbot page
export const fetchFlowsDataForAutomation = async (dispatch) => {
  try {
    const response = await ApiCalls.listAllGlobalForms();
    const publishedFlows = response.data.flatMap((form) =>
      form.flows
        .filter((flow) => flow.flowStatus === 'Published')
        .map((flow) => ({
          flowName: form.flowName,
          flowId: flow.flowId,
          payloadKeys: form.payloadKeys // Add payloadKeys here
        }))
    );
    if (response) {
      dispatch(
        addAction({
          payload: publishedFlows,
          type: 'addPublishedFlowForms'
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
};

// fetch attributes for to show on build chatbot page
export const fetchTagsAction = async (dispatch) => {
  try {
    const response = await ApiCalls.listTags();
    if (response) {
      dispatch(
        addAction({
          payload: response?.data,
          type: 'fetchTags'
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
};

export const unreadCustomers = async (dispatch) => {
  try {
    const response = await ApiCalls.getUnreadCustomerCount();
    if (response) {
      dispatch(
        addAction({
          payload: response?.data,
          type: 'setUnreadCustomerCount'
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
};

// const response = await ApiCalls.listTags();
