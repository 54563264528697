import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  Menu,
  MenuItem,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material';
// import { makeStyles } from '@mui/styles';
import { AnimatePresence, motion } from 'framer-motion';

import AddIcon from '@mui/icons-material/Add';
import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import TemplateApiCalls from 'src/utility/TemplateApiCalls';
import Toast from 'src/utility/Toast';
import { useTheme } from '@emotion/react';
import EmojiPicker from 'emoji-picker-react';
import ApiCalls from 'src/utility/ApiCalls';
import { useNavigate } from 'react-router';
import styled from '@emotion/styled';
import StepConnector, {
  stepConnectorClasses
} from '@mui/material/StepConnector';
import Check from '@mui/icons-material/Check';
import PropTypes from 'prop-types';
import TemplatePreview from '../Components/TemplatePreviews';
// import noneIcon from '../../../assets/newTemplateIcons/none.svg';
// import textIcon from '../../../assets/newTemplateIcons/text.svg';
import imageIcon from '../../../assets/newTemplateIcons/image.svg';
import videoIcon from '../../../assets/newTemplateIcons/video.svg';
import documentIcon from '../../../assets/newTemplateIcons/document.svg';
import quickReplay from '../../../assets/newTemplateIcons/quickReply.svg';
import callToAction from '../../../assets/newTemplateIcons/callToAction.svg';
import infoIcon from '../../../assets/newTemplateIcons/infoIcon.svg';
import FlowFormIcon from '../../../assets/newTemplateIcons/FormIcon.svg';
// import CircularProgress from '@material-ui/core/CircularProgress';
import { isValidPhoneNumber } from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './phoneInputStyle.css';
import { useAuth } from 'src/Authentication/AuthContext';
import { getDecryptedData } from 'src/utility/LocalStorageManager';
import Select from 'react-select';
import { bgcolor } from '@mui/system';
import documentButton from '../../../assets/MediaIcons/documents.svg';
import imageButton from '../../../assets/MediaIcons/images.svg';
import videoButton from '../../../assets/MediaIcons/videos.svg';
import variableIcon from 'src/assets/ChatbotIcons/variableIcon.svg';
import boldIcon from 'src/assets/TemplateIcons/bold.svg';
import italicIcon from 'src/assets/TemplateIcons/italic.svg';
import strikeIcon from 'src/assets/TemplateIcons/strikeThrough.svg';
import smileIcon from 'src/assets/ChatbotIcons/smileIcon.svg';
import addIcon from 'src/assets/svgIcons/addIconWhite.svg';
import textIcon from 'src/assets/svgIcons/textIcon.svg';
import noneIcon from 'src/assets/svgIcons/noneIcon.svg';
import ChatBG from 'src/assets/TemplateIcons/chatbg.png';
import { CreateTabTemplate } from '../Manage/manageStyle';
import QuickReply from './components/QuickReply';
import CallToAction from './components/CallToAction';
import TemplateBg from 'src/atom/components/TemplateBg';
import AttributeMatch from './components/AttributeMatch';
import { useSelector } from 'react-redux';

const customStyles = {
  control: (provided) => ({
    ...provided,
    width: '100%',
    height: 45,
    borderRadius: 8,
    fontSize: 13,
    overflow: 'hidden',
    borderColor: '#ececec'
  }),
  option: (base, { isSelected }) => ({
    ...base,
    backgroundColor: isSelected ? '#d1e7dd' : 'transparent',
    color: isSelected ? '#0f5132' : '#000',
    '&:hover': {
      backgroundColor: isSelected ? '#bcd0c7' : '#D8FFEB'
    }
  }),
  menu: (provided) => ({
    ...provided,
    width: '100%',
    overflow: 'hidden'
  })
};

const apiKey = process.env.REACT_APP_API_URL;

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 1,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)'
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#27AB69',
      borderTopWidth: 4
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#27AB69',
      borderTopWidth: 4
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 2,
    borderRadius: 1
  }
}));

const QontoStepIconRoot = styled('div')(({ theme, active }) => ({
  color: active
    ? '#27AB69'
    : theme.palette.mode === 'dark'
    ? theme.palette.grey[700]
    : '#eaeaf0',
  display: 'flex',
  height: 5,
  alignItems: 'center',
  '& .QontoStepIcon-completedIcon': {
    color: '#27AB69',
    zIndex: 1,
    fontSize: 22
  },
  '& .QontoStepIcon-circle': {
    width: 14,
    height: 14,
    borderRadius: '50%',
    backgroundColor: 'currentColor'
  },
  '& .QontoStepIcon-circle-active': {
    width: 16,
    height: 16,
    borderRadius: '50%',
    backgroundColor: '#27AB69'
  }
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {active ? (
        <div className="QontoStepIcon-circle-active" />
      ) : completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool
};

//  Prev,next,send Button dsign
const SendPrevButtons = styled(Button)`
  && {
    width: 288px;
    height: 45px;
    font-size: 15px;
    font-weight: 600;
    border-radius: 5px;
    padding: 10px 16px;
    margin-top: 30px;
    background-color: ${(props) => (props.disabled ? '#FFFFFF' : '#27ab69')};
    color: ${(props) => (props.disabled ? '#27ab69' : '#fff')};
    border: 2px solid ${(props) => (props.disabled ? '#27ab69' : 'transparent')};
    &:hover {
      background-color: ${(props) => (props.disabled ? '#FFFFFF' : '#1e8c54')};
    }
  }
`;

//  text field styles
const CustomTextField = styled(TextField)(
  () => `
      & .MuiInputBase-root {
        height: 45px;
        display: flex;
        align-items: center;
      },
      & .MuiOutlinedInput-notchedOutline{
        border-color:#ececec;
      },
      border-radius: 10px;
      font-size: 13px;
    `
);

const customStylesReactSelect = {
  control: (provided, state) => ({
    ...provided,
    boxSizing: 'border-box',
    height: '45px', // Matches the height from your styled component
    borderRadius: '10px', // Matches the border-radius
    border: state.isFocused
      ? '2px solid #008000!important'
      : '1px solid #ececec', // Green when active, grey otherwise
    boxShadow: 'none', // Removes the blue focus border
    '&:hover': {
      border: '1px solid black' // Black border on hover
    }
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#a2a9b7' // Blue placeholder color
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999 // Ensure the dropdown is above other elements
  })
};

const CustomSelect = styled(Select)`
  & .MuiInputBase-root {
    height: 45px; /* Adjust height as needed */
    display: flex;
    align-items: center;
    border-radius: 10px; /* Adjust border-radius */
  }
`;

//  All headings
const Headings = styled(Typography)(
  () => `
        color: #111111;
        font-size: 14px; 
        font-weight: 500; 
        margin-bottom:8px;
        margin-top:10px;
      `
);
const MainHeadings = styled(Typography)(
  () => `
        color: #111111;
        font-size: 16px; 
        font-weight: 500; 
        margin-bottom:8px;
        margin-top:10px;
      `
);

const SecondPageHeadings = styled(Typography)(
  () => `
        color: #111111;
        font-size: 15px; 
        font-weight: 500; 
        margin-bottom:10px;
        margin-top:20px;
        // align-content:center;
      `
);

//  action buttons classification eg: URL/Mobile
const CallToActionButtonHeading = styled(Typography)(
  () => `
        color: #989898;
        font-size: 15px; 
        font-weight: 500; 
        background-color: #EFEFEF;    
        `
);

//  action buttons inner text
const InnerButtonTextField = styled(TextField)`
  & .MuiInputLabel-root {
    color: #c0c0c0;
    font-size: 14px;
    font-weight: 400;
  }
  & .MuiInput-underline:before {
    border-bottom: 1px solid #ebebeb;
  }
`;

//  header type selection button style
const StyledButton = styled.button`
  border-radius: 10px;
  background-color: ${(props) => (props.active ? '#BEFCC5' : 'transparent')};
  margin-right: 8px;
  padding: 6px 8px;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  &:hover {
    background-color: ${(props) => (props.active ? '#BEFCC5' : '#f0f0f0')};
  }
  & > img {
    // height:16px;
    margin-right: 8px;
    filter: ${(props) =>
      props.active
        ? 'invert(59%) sepia(86%) saturate(4327%) hue-rotate(78deg) brightness(92%) contrast(89%)'
        : 'none'};
  }
`;

//  header type selection button style
const CarouselButton = styled(Button)(({ theme }) => ({
  color: '#959595',
  margin: '0px 5px 0px 0px',
  padding: '4px 8px',
  // backgroundColor: '#BEFCC5',
  border: '1px solid #959595',
  fontWeight: 500,
  borderRadius: '10px',
  '&:hover': {
    backgroundColor: '#BEFCC5',
    color: '#27AB69'
  }
}));

const ButtonTypography = styled(Typography)`
  margin-right: 10px;
  font-size: 13px;
  font-weight: 500;
  text-transform: none;
  // color: ${(props) => (props.active ? '#27AB69' : '#989898')};
  color: ${(props) => (props.active === 'true' ? '#27AB69' : '#989898')};
`;

//  Add variable button style
const StyledText = styled(Typography)`
  margin-top: 5px;
  color: #27ab69;
  font-size: 12px;
  font-weight: 500;
  padding-left: 10px;
  height: auto;
  border-radius: 50px;
  width: auto;
  display: inline-block;
  text-align: center;
  cursor: pointer;
`;

const LeftSide = styled(Box)(({ theme }) => ({
  backgroundColor: '#ffffff',
  borderRadius: '10px',
  padding: '2vh 1.5vw',
  minHeight: `calc(100vh - 98px)`,
  boxShadow: '0px 3px 4.5px rgba(0, 0, 0, 0.1)'
}));

//  template preview side
const RightSide = styled(Box)(() => ({
  backgroundColor: '#ffffff',
  // borderRadius: '10px',
  width: '400px',
  position: 'sticky',
  top: '90px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  // padding: '15px 30px',
  boxShadow: '0px 3px 4.5px rgba(0, 0, 0, 0.1)'
}));

//  All error texts
const ErrorText = styled(Typography)`
  font-size: 13px;
  font-weight: 400;
  color: #ff2f2f;
`;

//  All button texts
const ButtonTextMAxCount = styled(Typography)`
  font-size: 11px;
  font-weight: 400;
  color: #a3a3a3;
`;

<Typography
  color="error"
  sx={{
    fontSize: '0.75rem',
    mt: '-5px',
    mb: '5px',
    textAlign: 'start'
  }}
>
  Please enter a valid URL
</Typography>;

const extractPlaceholders = (text) => {
  const regex = /{{(.*?)}}/g;
  const placeholders = [];
  let match;
  for (match = regex.exec(text); match !== null; match = regex.exec(text)) {
    placeholders.push(match[1]);
  }
  return placeholders;
};

const TemplateContentLib = ({ initialData,performCancel ,handleFullCustomisation}) => {
  // const classes = useStyles();
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(
    theme.breakpoints.up('sm') && theme.breakpoints.down('md')
  );

  //  Design and the steps for stepper function
  let steps = ['Basic Info', 'Content', 'Buttons'];

  const [currentTime, setCurrentTime] = useState('');
  const [buttonSection, setButtonSection] = useState('none');
  const [activeStep, setActiveStep] = useState(0);
  // first tab states
  const [templateName, setTemplateName] = useState('');
  const [category, setCategory] = useState('');
  const [language, setLanguage] = useState('');
  const [isTemplateNameEmpty, setIsTemplateNameEmpty] = useState(false);
  const [isCategoryEmpty, setIsCategoryEmpty] = useState(false);
  const [isLanguageEmpty, setIsLanguageEmpty] = useState(false);
  const [isTemplateNameValid, setIsTemplateNameValid] = useState(true);

  // Second tab states
  const [bodyContent, setBodyContent] = useState('');
  const [isBodyEmpty, setIsBodyEmpty] = useState(false);
  const [footerContent, setFooterContent] = useState('');
  const [contentData, setContentData] = useState({
    type: 'none',
    text: '',
    image: '',
    video: '',
    application: ''
  });
  console.log('contentData', contentData);
  // Thrid tab states
  // Call to action
  const [callToActionButtonTextOne, setCallToActionButtonTextOne] =
    useState('');
  const [callToActionButtonTextTwo, setCallToActionButtonTextTwo] =
    useState('');
  const [callToActionButtonTextThree, setCallToActionButtonTextThree] =
    useState('');
  const [callToActionButtonTextOneError, setCallToActionButtonTextOneError] =
    useState(false);
  const [callToActionButtonTextTwoError, setCallToActionButtonTextTwoError] =
    useState(false);
  const [
    callToActionButtonTextThreeError,
    setCallToActionButtonTextThreeError
  ] = useState(false);

  const [url, setUrl] = useState('');
  const [mobile, setMobile] = useState('');
  // const [mobileWithOutCountryCode, setMobileWithOutCountryCode] = useState('');

  const [mobileError, setMobileError] = useState(false);
  const [urlError, setUrlError] = useState(false);

  // quick replay
  const [buttonTextOne, setButtonTextOne] = useState('');
  const [buttonTextTwo, setButtonTextTwo] = useState('');
  const [buttonTextThree, setButtonTextThree] = useState('');

  //  Flow button
  const [buttonTextFlow, setButtonTextFlow] = useState('');
  const [selectedFlow, setSelectedFlow] = useState('');
  const [selectedFlowsList, setSelectedFlowsList] = useState([]);
  const [buttonTextFlowError, setButtonTextFlowError] = useState('');
  const [selectedFlowEmpty, setSelectedFlowEmpty] = useState(false);

  // states for saving variables from textheader and body
  const [bodyPlaceholders, setBodyPlaceholders] = useState([]);
  const [textPlaceholders, setTextPlaceholders] = useState([]);

  const arrayOfStrings = ['name', 'city', 'mobile'];
  // For Variable dropdown
  const [bodyanchorEl, setBodyAnchorEl] = useState(null);
  const [headeranchorEl, setHeaderAnchorEl] = useState(null);

  //  for Emogies
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const emojiButtonRef = useRef();
  const emojiPickerRef = useRef(null);

  // //Media selection
  const [openDialog, setOpenDialog] = useState(false);
  const [media, setMedia] = useState([]);
  const [selectedMediaId, setSelectedMediaId] = useState('');
  const [selectedMedia, setSelectedMedia] = useState('');
  const [updateUpload, setUpdateUpload] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  //  For carousel
  const [carouselHeaderData, setCarouselHeaderData] = useState([]);
  const [cards, setCards] = useState([]);
  const [activeCard, setActiveCard] = useState(0);
  const [openCarouselDialog, setOpenCarouselDialog] = useState(false);
  const [carouselMedia, setCarouselMedia] = useState([]);
  const [updateUploadCarousel, setUpdateUploadCarousel] = useState(false);

  const [selectedCarouselMedia, setSelectedCarouselMedia] = useState({});
  const [selectedCarouselMediaArray, setSelectedCarouselMediaArray] = useState(
    []
  );
  const [carouselMediaType, setCarouselMediaType] = useState('');

  // For allowing and hiding Premium feature
  const [isAllow, SetIsAllow] = useState(false);

  const [securityRecommendation, setSecurityRecommendation] = useState(false);
  const [expirationTime, setExpirationTime] = useState(5);
  const [copyCode, setCopyCode] = useState('Copy Code');

  //
  //

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        emojiPickerRef.current &&
        !emojiPickerRef.current.contains(event.target) &&
        emojiButtonRef.current &&
        !emojiButtonRef.current.contains(event.target)
      ) {
        setShowEmojiPicker(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [showEmojiPicker]);

  useEffect(() => {
    if (isAuthenticated) {
      // const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
      const userInfo = getDecryptedData('userInfo');
      if (userInfo.planName === 'Premium') {
        SetIsAllow(true);
      }
    }
  }, [isAuthenticated]);

  //  Set tabs according to category
  if (category && category == 'CAROUSEL') {
    steps = ['Basic Info', 'Cards'];
  }

  if (category && category == 'AUTHENTICATION') {
    steps = ['Basic Info', 'Buttons'];
  }

  //  To add first card by default

  // For emogies
  // const handleEmojiClick = (emojiData) => {
  //   const selectedEmoji = emojiData.emoji;
  //   setChosenEmoji(selectedEmoji);
  //   setBodyContent((prevMessage) => prevMessage + selectedEmoji);
  // };

  // handle emogies with the cursor posotion

  const handleHeaderMenuClose = () => {
    setHeaderAnchorEl(null);
  };

  const bodyTextFieldRef = useRef(null);
  const headerTextFieldRef = useRef(null);

  

  const handleMenuHeaderItemClick = (item) => {
    const variableWithBrackets = `{{${item}}}`;

    if (headerTextFieldRef.current) {
      const inputElement = headerTextFieldRef.current;
      const startPos = inputElement.selectionStart;
      const endPos = inputElement.selectionEnd;
      const currentValue = inputElement.value;
      const updatedText =
        currentValue.substring(0, startPos) +
        variableWithBrackets +
        currentValue.substring(endPos);

      setContentData((prevData) => ({
        ...prevData,
        text: updatedText
      }));
      handleHeaderMenuClose();
    }
  };

  const { businessProfileData } = useSelector((state) => state.wapp);


  const handleCategoryChange = (event) => {
    setIsCategoryEmpty(false);

    //  Delete all state when the category changing
    setCallToActionButtonTextOne('');
    setCallToActionButtonTextTwo('');
    setCallToActionButtonTextThree('');
    setButtonTextFlow('');
    setSelectedFlow('');
    setSelectedFlowsList([]);
    setMobile('');
    setUrl('');
    setButtonTextOne('');
    setButtonTextTwo('');
    setButtonTextThree('');
    setMultipleButtons([]);
    SetMultipleCallToActionUrl([]);
    setBodyContent('');
    setContentData({
      type: 'none',
      text: '',
      image: '',
      video: '',
      application: ''
    });
    setFooterContent('');
    setCards([]);

    const selectedCategory = event.value;
    setCategory(selectedCategory);

    // if (selectedCategory === 'marketing') {
    //   setCallToActionButtonTextThree('STOP');
    // } else {
    setCallToActionButtonTextThree('');
    // }
  };

  const handleContentSelection = (contentType) => {
    setContentData((prevData) => ({
      ...prevData,
      type: contentType,
      text: contentType === 'text' ? '' : prevData.text,
      image: contentType === 'image' ? null : prevData.image,
      video: contentType === 'video' ? null : prevData.video,
      application: contentType === 'application' ? null : prevData.application
    }));
  };

  useEffect(() => {
    setContentData((prevData) => {
      if (
        prevData.type !== contentData.type
      ) {
        return {
          type: contentData.type, // Update only if type has changed
          text: '',
          image: '',
          video: '',
          application: ''
        };
      }
      return prevData; // Return previous state if no change
    });
    // }
  }, [contentData.type]);

  const handleNext = () => {
    if (activeStep === 0) {
      if (templateName.trim() === '') {
        setIsTemplateNameEmpty(true);
      } else {
        setIsTemplateNameEmpty(false);
      }

      if (category.trim() === '') {
        setIsCategoryEmpty(true);
      } else {
        setIsCategoryEmpty(false);
      }

      if (language.trim() === '') {
        setIsLanguageEmpty(true);
      } else {
        setIsLanguageEmpty(false);
      }
    }

    if (category !== 'CAROUSEL') {
      if (activeStep === 1) {
        if (bodyContent.trim() === '') {
          setIsBodyEmpty(true);
        } else {
          setIsBodyEmpty(false);
        }
      }
    }

    // Check if all required fields are filled
    if (
      (activeStep === 0 &&
        templateName.trim() !== '' &&
        category.trim() !== '' &&
        language.trim() !== '') ||
      (activeStep === 1 && bodyContent.trim() !== '')
    ) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  //  For show curent time on preview
  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const formattedTime = now.toLocaleTimeString();
      setCurrentTime(formattedTime);
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  //  For extract variables from textHeaders and body
  useEffect(() => {
    const newBodyPlaceholders = extractPlaceholders(bodyContent);
    const newTextPlaceholders = extractPlaceholders(contentData.text);

    setBodyPlaceholders(newBodyPlaceholders);
    setTextPlaceholders(newTextPlaceholders);
  }, [bodyContent, contentData.text]);

  // useEffect(() => {
  //   if (category === 'MARKETING' && buttonTextFlow == '') {
  //     setCallToActionButtonTextThree('STOP');
  //   }
  //   if (category === 'MARKETING' && buttonSection == 'flow') {
  //     setCallToActionButtonTextThree('');
  //   }
  // }, [category, buttonTextFlow, buttonSection]);

  const replaceWithSequentialNumbers = (content) => {
    let counter = 1;
    return content?.replace(/{{\w+}}/g, () => `{{${counter++}}}`);
  };

  //  Usage
  const updatedBodyContent = replaceWithSequentialNumbers(bodyContent);
  const updatedHeaderContent = replaceWithSequentialNumbers(contentData?.text);

  //  URL validation
  function isValidUrl(url) {
    const regex =
      /^(https?:\/\/)([a-z0-9\.-]+)\.([a-z]{2,6})([\/\w \.-]*)*\/?$/i;
    return regex.test(url);
  }

  const handlePhoneChange = (value) => {
    setMobileError(false);

    if (value === undefined || value.replace(/\D/g, '').length === 0) {
      // If the input is empty or only non-digit characters are present, clear the state.
      setMobile('');
    } else {
      // Otherwise, update the state with the new value.
      setMobile(value);
    }
  };

  //  For to validate carousel
  // const validateCarousel = () => {
  //   const errors = cards.map((card, index) => {
  //     const cardErrors = {
  //       headerError: !card.headerContent,
  //       bodyError: !card.body.trim(),
  //       buttonsError: card.buttons.length === 0 || card.buttons.some(button => (button.type === 'quick_reply' && !button.text.trim())|| (button.type === 'url' && !button.url.trim()) || (button.type === 'phone_number' && !button.phone_number.trim()))
  //     };
  //     return cardErrors;
  //   });

  //   return errors;
  // };
  const validateCarousel = () => {
    let errorMessages = [];

    cards.forEach((card, index) => {
      let cardErrors = [];

      if (!card.headerContent) {
        cardErrors.push('header');
      }
      if (!card.body.trim()) {
        cardErrors.push('content');
      }
      if (
        card.buttons.length === 0 ||
        card.buttons.some(
          (button) =>
            (button.type === 'quick_reply' && !button.text.trim()) ||
            (button.type === 'url' && !button.url.trim()) ||
            (button.type === 'phone_number' && !button.phone_number.trim())
        )
      ) {
        cardErrors.push('buttons');
      }

      if (cardErrors.length > 0) {
        errorMessages.push(`Card ${index + 1}: ${cardErrors.join(', ')}`);
      }
    });

    return errorMessages;
  };
  const [multipleButtons, setMultipleButtons] = useState([]);
  const [multipleCallToActionUrl, SetMultipleCallToActionUrl] = useState([]);

  const handleFinalSubmit = async () => {
    setSubmitLoading(true);
    setMobileError(false);
    setUrlError(false);
    setCallToActionButtonTextOneError(false);
    setCallToActionButtonTextTwoError(false);
    setCallToActionButtonTextThreeError(false);

    setButtonTextFlowError('');

    if (bodyContent && bodyContent.trim() === '') {
      setIsBodyEmpty(true);
      setSubmitLoading(false);
      return;
    } else {
      setIsBodyEmpty(false);
    }

    if (category === 'CAROUSEL') {
      const carouselErrorMessages = validateCarousel();
      if (carouselErrorMessages.length > 0) {
        setSubmitLoading(false);
        Toast({
          type: 'error',
          message:
            'Please complete the following fields in your carousel cards:\n' +
            carouselErrorMessages.join('; ')
        });
        return;
      }
    }

    if (callToActionButtonTextOne && !isValidUrl(url)) {
      setUrlError(true);
      return;
    }

    if (url && !callToActionButtonTextOne) {
      setCallToActionButtonTextOneError(true);
      return;
    }

    const formattedMobile = mobile?.replace('+', ''); // Removes the '+' sign

    if (callToActionButtonTextTwo && !isValidPhoneNumber(mobile)) {
      setMobileError(true);
      return;
    }

    if (formattedMobile && !callToActionButtonTextTwo) {
      setCallToActionButtonTextTwoError(true);
      return;
    }

    if (buttonSection == 'flow' && buttonTextFlow == '') {
      setButtonTextFlowError('Please fill flow button text');
      return;
    }

    if (buttonSection == 'flow' && selectedFlow == '') {
      setSelectedFlowEmpty(true);
      return;
    }

    setSubmitLoading(true);
    let response;
    if (initialData) {
      response = await TemplateApiCalls.createLibraryTemplate(
        // console.log(

        templateName,
        updatedHeaderContent,
        updatedBodyContent,
        category,
        language,
        contentData,
        footerContent,
        buttonTextOne.trim(),
        buttonTextTwo.trim(),
        buttonTextThree,
        callToActionButtonTextOne.trim(),
        callToActionButtonTextTwo.trim(),
        callToActionButtonTextThree,
        url,
        formattedMobile,
        textPlaceholders,
        bodyPlaceholders,
        selectedMediaId,
        buttonTextFlow,
        selectedFlow,
        cards,
        carouselHeaderData,
        securityRecommendation,
        expirationTime,
        copyCode,
        initialData?._id,
        multipleButtons,
        multipleCallToActionUrl,
        initialData?.template_name,
        dynamicArray,
        businessProfileData?.phone_number_id
      );
    }
    if (response?.status === 200) {
      setSubmitLoading(false);
      if (response?.error) {
        if (response.data.error.error_user_msg) {
          Toast({
            type: 'error',
            message: response?.data?.error.error_user_msg
          });
        } else {
          Toast({
            type: 'error',
            message: response?.data?.error.message
          });
        }
      } else {
        Toast({ type: 'success', message: response?.message });
        // navigate('/templates');
        performCancel()
      }
    } else {
      setSubmitLoading(false);
      Toast({ type: 'error', message: response?.message });
    }
  };

  // useEffect(() => {
  //   const handleBodyClick = (event) => {
  //     // Check if the click occurred outside the EmojiPicker and Emoji button
  //     if (
  //       !emojiButtonRef.current?.contains(event.target) &&
  //       event.target.tagName === 'EmojiPicker' // Assuming EmojiPicker renders an IMG tag
  //     ) {
  //       setShowEmojiPicker(false);
  //     }
  //   };
  //   document.body.addEventListener('mousedown', handleBodyClick);
  //   // Cleanup function to remove the event listener when the component is unmounted
  //   return () => {
  //     document.body.removeEventListener('mousedown', handleBodyClick);
  //   };
  // }, []);

  // for media selecting
  const selectMedia = async () => {
    setOpenDialog(true);
    const response = await ApiCalls.listMedia();

    if (response?.status === 200) {
      // setMedia(response?.data);
      const recentMedia = response.data.filter((item) => !item.expired);
      setMedia(recentMedia);
    }
  };

  useEffect(() => {
    if (updateUpload) {
      selectMedia();
      setUpdateUpload(false);
    }
  }, [updateUpload]);

  //  For add media

  //  Data for template preview
  const constructTemplateHeader = () => {
    switch (contentData.type) {
      case 'text':
        if (contentData.text) {
          return { type: contentData.type, value: contentData.text };
        }
        break;
      case 'image':
      case 'video':
      case 'application':
        if (
          contentData[contentData.type] &&
          contentData[contentData.type].media_url
        ) {
          return {
            type: contentData.type,
            value: contentData[contentData.type].media_url
          };
        }
        break;
    }
  };

  // Function to generate the buttons array for preview
  const generateButtonsArray = () => {
    const buttons = [];

    if (buttonTextOne) {
      buttons.push({
        type: 'QUICK_REPLY',
        text: buttonTextOne
      });
    }

    if (buttonTextTwo) {
      buttons.push({
        type: 'QUICK_REPLY',
        text: buttonTextTwo
      });
    }

    if (buttonTextThree) {
      buttons.push({
        type: 'QUICK_REPLY',
        text: buttonTextThree
      });
    }

    if (callToActionButtonTextOne) {
      buttons.push({
        type: 'URL',
        text: callToActionButtonTextOne,
        url: url
      });
    }

    if (callToActionButtonTextTwo) {
      buttons.push({
        type: 'PHONE_NUMBER',
        text: callToActionButtonTextTwo,
        phone_number: mobile
      });
    }

    if (callToActionButtonTextThree) {
      buttons.push({
        type: 'QUICK_REPLY',
        text: callToActionButtonTextThree
      });
    }

    if (buttonTextFlow) {
      buttons.push({
        type: 'QUICK_REPLY',
        text: buttonTextFlow
      });
    }
    if (multipleCallToActionUrl.length > 0) {
      buttons.push(...multipleCallToActionUrl);
    }
    if (multipleButtons.length > 0) {
      buttons.push(...multipleButtons);
    }

    return buttons;
  };

  // constructing carousel object for preview
  const generateCarouselArray = () => {
    return cards.map((card, index) => ({
      carousel_header: {
        type: carouselHeaderData[index]?.type,
        id: carouselHeaderData[index]?.id,
        url: carouselHeaderData[index]?.URL
      },
      carousel_body: card.body,
      carousel_buttons: card.buttons.map((button) => ({
        type: button.type.toUpperCase(),
        text: button.text,
        ...(button.url && { url: button.url }),
        ...(button.phone_number && { phone_number: button.phone_number })
      }))
    }));
  };

  // Constructing the data object
  const data = {
    template_header: constructTemplateHeader(),
    template_body: bodyContent,
    template_footer: footerContent,
    template_buttons: generateButtonsArray(),
    carousel: generateCarouselArray()
  };

console.log("data",data)
  //  For card creation


  const getButtonType = (buttons) => {
    // Check if there's any QUICK_REPLY button
    const hasFlow = buttons?.some((button) => button.type === 'FLOW');
    const hasQuickReply = buttons?.some(
      (button) => button.type === 'QUICK_REPLY'
    );

    // Check if there's any PHONE_NUMBER or URL button
    const hasCallToAction = buttons?.some(
      (button) => button.type === 'PHONE_NUMBER' || button.type === 'URL'
    );

    // Return based on conditions

    if (hasFlow) {
      return 'flow';
    } else if (hasCallToAction) {
      return 'callToAction';
    } else if (hasQuickReply) {
      return 'quickReplay';
    } else {
      return 'none';
    }
  };
  useEffect(() => {
    if (initialData) {
      setCategory(initialData?.category);
      setLanguage(initialData?.language);
      setTemplateName(initialData?.template_name);
      setBodyContent(initialData?.template_body);
      setFooterContent(initialData?.template_footer);

      if (initialData?.template_header?.type === 'text') {
        setContentData({
          type: 'text',
          text: initialData?.template_header?.value,
          image: '',
          video: '',
          application: ''
        });
      }

      if (initialData?.template_buttons?.length > 0) {
        const step3State = getButtonType(initialData?.template_buttons);
        setButtonSection(step3State);

        if (step3State === 'callToAction') {
          const forUrl = initialData?.template_buttons?.filter(
            (item) => item.type === 'URL'
          );
          const forPhone = initialData?.template_buttons?.filter(
            (item) => item.type === 'PHONE_NUMBER'
          )[0];

          if (forPhone) {
            setCallToActionButtonTextTwo(forPhone?.text);
            setMobile(`+${forPhone?.phone_number}`);
          }
          if (forUrl) {
            SetMultipleCallToActionUrl(forUrl);
          }

          const forQuick = initialData?.template_buttons?.filter(
            (item) => item.type === 'QUICK_REPLY'
          );

          setMultipleButtons(forQuick);
        } else if (step3State === 'quickReplay') {
          const forQuick = initialData?.template_buttons?.filter(
            (item) => item.type === 'QUICK_REPLY'
          );

          setMultipleButtons(forQuick);
        }
      }
    }
  }, [initialData]);
  console.log('carouselHeaderData', carouselHeaderData);
  const [dynamicArray, setDynamicArray] = useState([]);
  useEffect(() => {
    if(bodyContent.length > 0){

      const matches = bodyContent?.match(/{{(\d+)}}/g) || [];
      
      if(matches){
        setDynamicArray(

          matches.map(match => {
            const key = match.replace(/{{|}}/g, ""); // Remove {{ }}
            return { key, value: "" }; // Assign mapped value or empty string
          })
        )
      }
  }
  }, [bodyContent]);

  // Convert matches to an object with empty values

  return (
    <Grid
      container
      justifyContent="center"
      spacing={isMobile ? 0 : isTablet ? 1 : 2}
      zIndex={999}
    >
      {/* Left section */}
      <Grid item xs={12} sm={8} justifyContent="flex-start">
        <LeftSide>
          <Box>
            <Box
              sx={{
                // overflowY: 'auto',
                // maxHeight: `calc(99vh - 260px)`
                maxHeight: 'fit-content'
              }}
            >
              <Box mt={1}>
                {
                  <Box>
                    <MainHeadings>Basic Info</MainHeadings>
                    <Grid container spacing={3} rowSpacing={1}>
                      {/* Template Name Section */}
                      <Grid item xs={12} md={6} sx={{ zIndex: '99999' }}>
                        <Headings>
                          Template Name{' '}
                          <Tooltip
                            title="Name can only have lowercase letters, numbers and underscores.
                              This template name can be anything that helps you identify it
                              when sending template messages. This will not be showing to
                              your customers, Eg: For marketing template about stock
                              clearance sale, you can name the template stock_clearance_sale."
                            arrow
                            placement="right"
                          >
                            <span
                              style={{
                                position: 'relative',
                                display: 'inline-block',
                                verticalAlign: 'middle' // This helps center the icon vertically
                              }}
                            >
                              <img
                                src={infoIcon}
                                alt="Action Required Icon"
                                style={{
                                  verticalAlign: 'middle',
                                  marginLeft: '5px',
                                  cursor: 'pointer'
                                }}
                              />
                            </span>
                          </Tooltip>
                        </Headings>

                        <CustomTextField
                          placeholder="Template Name"
                          value={templateName}
                          multiline
                          maxRows={5}
                          onChange={(event) => {
                            let inputValue = event.target.value;
                            inputValue = inputValue
                              .replace(/\s+/g, '_')
                              .toLowerCase();
                            const isValidInput = /^[a-z0-9_]*$/.test(
                              inputValue
                            );

                            if (inputValue.length <= 512 && isValidInput) {
                              setTemplateName(inputValue);
                              setIsTemplateNameValid(true);
                              setIsTemplateNameEmpty(false);
                            } else {
                              setIsTemplateNameValid(false);
                              setTimeout(
                                () => setIsTemplateNameValid(true),
                                1000
                              );
                            }
                          }}
                          required
                          helperText={
                            isTemplateNameEmpty ? (
                              <span style={{ color: 'red', fontWeight: 400 }}>
                                Template Name is required
                              </span>
                            ) : !isTemplateNameValid ? (
                              <ErrorText>
                                Name can only have lowercase letters, numbers,
                                and underscores.
                              </ErrorText>
                            ) : (
                              ``
                            )
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                style={{
                                  position: 'absolute',
                                  bottom: 10,
                                  right: 5
                                }}
                              >
                                <ButtonTextMAxCount>
                                  {templateName.length}/512
                                </ButtonTextMAxCount>
                              </InputAdornment>
                            )
                          }}
                          sx={{ width: '100%' }}
                        />
                      </Grid>

                      {/* Template Category Section */}
                    </Grid>
                  </Box>

                }
                
                <Box sx={{padding:"20px 0px"}}>

{bodyContent}
                </Box>
                {/* tabpanel 3 */}
                {category !== 'CAROUSEL' && (
                  <>
                    <MainHeadings>Buttons</MainHeadings>
                    <Typography pb={1}>
                      Create buttons that let the customer respond to your
                      message or take action
                    </Typography>

                    {/* No buttons tab */}
                    {buttonSection === 'none' && (
                      <Typography>No Buttons</Typography>
                    )}

                    {/* Call to action tab */}
                    {buttonSection === 'callToAction' && (
                      <>
                        {initialData?.template_buttons?.filter(
                          (item) => item.type === 'PHONE_NUMBER'
                        )[0] && (
                          <Box
                            bgcolor="#F6F6F6"
                            padding="10px"
                            borderRadius="10px"
                            marginBottom="10px"
                          >
                            {/* Nested Box with Input Fields and Button-like Structure */}
                            <CallToActionButtonHeading>
                              Mobile Number
                            </CallToActionButtonHeading>
                            <Box>
                              {/* Input Field 1 with Border Below */}
                              <InnerButtonTextField
                                placeholder="Button text"
                                variant="standard"
                                id="standard-basic"
                                fullWidth
                                value={callToActionButtonTextTwo}
                                onChange={(e) => {
                                  setCallToActionButtonTextTwoError(false);
                                  setCallToActionButtonTextTwo(e.target.value);
                                }}
                                InputProps={{
                                  readOnly: true,
                                  endAdornment: (
                                    <InputAdornment
                                      position="end"
                                      style={{
                                        position: 'absolute',
                                        bottom: 10,
                                        right: 5
                                      }}
                                    >
                                      <ButtonTextMAxCount>
                                        {callToActionButtonTextTwo?.length}/25
                                      </ButtonTextMAxCount>
                                    </InputAdornment>
                                  )
                                }}
                                error={Boolean(
                                  callToActionButtonTextTwo?.length > 24
                                )}
                                helperText={
                                  callToActionButtonTextTwo?.length > 24
                                    ? 'Maximum Limit Reached'
                                    : ''
                                }
                                style={{ marginBottom: '10px' }}
                              />
                              {callToActionButtonTextTwoError && (
                                <ErrorText
                                  sx={{
                                    mt: '-5px',
                                    mb: '5px'
                                  }}
                                >
                                  Please enter button text
                                </ErrorText>
                              )}

                              {/* Input Field 2 with Border Below */}

                              <PhoneInput
                                international
                                defaultCountry="IN"
                                placeholder="Enter phone number"
                                value={mobile}
                                // onChange={setMobile}
                                // onChange={() => {
                                //   // setMobile(value);
                                //   handlePhoneChange;
                                //   setMobileError(false);
                                // }}
                                onChange={handlePhoneChange}
                                className="phoneInput"
                                style={{ color: '#27ab69' }}
                              />
                              {mobileError && (
                                <ErrorText
                                  sx={{
                                    mt: '-5px',
                                    mb: '5px'
                                  }}
                                >
                                  Please enter a valid phone number
                                </ErrorText>
                              )}
                            </Box>
                          </Box>
                        )}
                      
                        <CallToAction
                          disable={true}
                          multipleCallToActionUrl={multipleCallToActionUrl}
                          SetMultipleCallToActionUrl={
                            SetMultipleCallToActionUrl
                          }
                        />
                        <QuickReply
                          disable={true}
                          setMultipleButtons={setMultipleButtons}
                          multipleButtons={multipleButtons}
                        />
                      </>
                    )}
  <AttributeMatch
                        dynamicArray={dynamicArray}
                        setDynamicArray={setDynamicArray}
                        />
                    {/* Quick replay tab */}
                    {/* {buttonSection === 'quickReplay' && (
                        <>
                          <Box
                            bgcolor="#F6F6F6"
                            padding="10px"
                            borderRadius="10px"
                            marginBottom="10px"
                          >
                            <Box>
                              <InnerButtonTextField
                                placeholder="Button text"
                                id="standard-basic"
                                variant="standard"
                                fullWidth
                                value={buttonTextOne}
                                onChange={(e) => setButtonTextOne(e.target.value)}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment
                                      position="end"
                                      style={{
                                        position: 'absolute',
                                        bottom: 10,
                                        right: 5
                                      }}
                                    >
                                      <ButtonTextMAxCount>
                                        {buttonTextOne?.length}/25
                                      </ButtonTextMAxCount>
                                    </InputAdornment>
                                  )
                                }}
                                error={Boolean(buttonTextOne?.length > 24)}
                                helperText={
                                  buttonTextOne?.length > 24
                                    ? 'Maximum Limit Reached'
                                    : ''
                                }
                                style={{ marginBottom: '10px' }}
                              />
                            </Box>
                          </Box>
                          <Box
                            bgcolor="#F6F6F6"
                            padding="10px"
                            borderRadius="10px"
                            marginBottom="10px"
                          >
                            <Box>
                              <InnerButtonTextField
                                placeholder="Button text"
                                variant="standard"
                                id="standard-basic"
                                fullWidth
                                value={buttonTextTwo}
                                onChange={(e) => setButtonTextTwo(e.target.value)}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment
                                      position="end"
                                      style={{
                                        position: 'absolute',
                                        bottom: 10,
                                        right: 5
                                      }}
                                    >
                                      <ButtonTextMAxCount>
                                        {buttonTextTwo?.length}/25
                                      </ButtonTextMAxCount>
                                    </InputAdornment>
                                  )
                                }}
                                error={Boolean(buttonTextTwo?.length > 24)}
                                helperText={
                                  buttonTextTwo?.length > 24
                                    ? 'Maximum Limit Reached'
                                    : ''
                                }
                                style={{ marginBottom: '10px' }}
                              />
                            </Box>
                          </Box>
                          <Box
                            bgcolor="#F6F6F6"
                            padding="10px"
                            borderRadius="10px"
                            marginBottom="10px"
                          >
                            <Box>
                              <InnerButtonTextField
                                placeholder="Button text"
                                variant="standard"
                                id="standard-basic"
                                fullWidth
                                value={buttonTextThree}
                                onChange={(e) =>
                                  setButtonTextThree(e.target.value)
                                }
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment
                                      position="end"
                                      style={{
                                        position: 'absolute',
                                        bottom: 10,
                                        right: 5
                                      }}
                                    >
                                      <ButtonTextMAxCount>
                                        {buttonTextThree?.length}/25
                                      </ButtonTextMAxCount>
                                    </InputAdornment>
                                  )
                                }}
                                error={Boolean(buttonTextThree?.length > 24)}
                                helperText={
                                  buttonTextThree?.length > 24
                                    ? 'Maximum Limit Reached'
                                    : ''
                                }
                                style={{ marginBottom: '10px' }}
                              />
                            </Box>
                          </Box>
                          {/* <Box bgcolor="#F6F6F6" padding="10px" borderRadius="10px">
                        <Box>
                          <InnerButtonTextField
                            placeholder="Button text"
                            variant="standard"
                            id="standard-basic"
                            fullWidth
                            value={buttonTextThree}
                            onChange={(e) => setButtonTextThree(e.target.value)}
                            style={{ marginBottom: '10px' }}
                          />
  
                          <ReplayButtons>{buttonTextThree}</ReplayButtons>
                        </Box>
                      </Box> 
                        </>
                      )} */}
                    {/* Flow tab */}

                    {buttonSection === 'flow' && (
                      <>
                        {/* first button */}
                        <Box
                          bgcolor="#F6F6F6"
                          padding="10px"
                          borderRadius="10px"
                          marginBottom="10px"
                        >
                          {/* Nested Box with Input Field and Button-like Structure */}
                          <Box>
                            {/* Input Field with Border Below */}
                            <InnerButtonTextField
                              placeholder="Button text"
                              id="standard-basic"
                              variant="standard"
                              fullWidth
                              value={buttonTextFlow}
                              onChange={(e) =>
                                setButtonTextFlow(e.target.value)
                              }
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    position="end"
                                    style={{
                                      position: 'absolute',
                                      bottom: 10,
                                      right: 5
                                    }}
                                  >
                                    <ButtonTextMAxCount>
                                      {buttonTextFlow?.length}/25
                                    </ButtonTextMAxCount>
                                  </InputAdornment>
                                )
                              }}
                              error={Boolean(buttonTextFlow?.length > 24)}
                              helperText={
                                buttonTextFlow?.length > 24
                                  ? 'Maximum Limit Reached'
                                  : ''
                              }
                              style={{ marginBottom: '10px' }}
                            />
                            {buttonTextFlowError && (
                              <ErrorText
                                sx={{
                                  mt: '-5px',
                                  mb: '5px'
                                }}
                              >
                                {buttonTextFlowError}
                              </ErrorText>
                            )}
                          </Box>
                        </Box>
                      </>
                    )}
                  </>
                )}

                {category === 'AUTHENTICATION' && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 2
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        gap: 1,
                        justifyItems: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '14px',
                          fontWeight: 600
                        }}
                      >
                        Security Recommedation
                      </Typography>
                      <Checkbox
                        value={securityRecommendation}
                        checked={securityRecommendation}
                        onChange={() =>
                          setSecurityRecommendation(!securityRecommendation)
                        }
                        type="checkbox"
                      />
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: '14px',
                          fontWeight: 600,
                          mb: 1
                        }}
                      >
                        Expiration Time
                      </Typography>
                      <TextField
                        value={expirationTime}
                        onChange={(e) => setExpirationTime(e.target.value)}
                        sx={{ width: '100%' }}
                      />
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: '14px',
                          fontWeight: 600,
                          mb: 1
                        }}
                      >
                        Text
                      </Typography>
                      <TextField
                        value={copyCode}
                        onChange={(e) => setCopyCode(e.target.value)}
                        sx={{ width: '100%' }}
                      />
                    </Box>
                  </Box>
                )}
              </Box>

              {/* Navigation buttons */}
              <Box my={1} display="flex" justifyContent="space-between">
              
                <SendPrevButtons
                  variant="contained"
                  color="primary"
                  disabled={submitLoading}
                  onClick={
                    // activeStep === steps.length - 1
                    handleFullCustomisation 
                  }
                >
             Full Customisation
                </SendPrevButtons>
                <SendPrevButtons
                  variant="contained"
                  color="primary"
                  disabled={submitLoading}
                  onClick={
                    // activeStep === steps.length - 1
                 handleFinalSubmit 
                  }
                >
                  {submitLoading ? (
                    // <CircularProgress size={24} color="inherit" />
                    <span>Loading...</span>
                  ) : // ) : activeStep === steps.length - 1 ? (
                  (
                    'Finish')
                 }
                </SendPrevButtons>
              </Box>
            </Box>
          </Box>
        </LeftSide>
      </Grid>

      {/* Right section */}
      {category !== 'AUTHENTICATION' ? (
        <Grid item xs={12} sm={4} sx={{ position: "relative", display: "flex", alignItems: "flex-start", justifyContent: 'center' }}>
          <TemplateBg top={"0px"} data={data} />
        </Grid>
      ) : (
        <Grid item xs={12} sm={4} justifyContent="center"></Grid>
      )}
    </Grid>
  );
};

export default TemplateContentLib;
