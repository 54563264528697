import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useParams } from 'react-router';
import CreateTemplateLib from './CreateTemplateLib';
import CustomDialogie from 'src/components/dialogue/CustomDialogie';

import NewTemplateCarosel from 'src/content/applications/NewTemplatesCarosel';

const EditTemplateLib = ({
  initialData,
  show,
  performCancel,
  custom,
  handleFullCustomisation
}) => {
  return (
    <div>
        <CustomDialogie
        title={"Create Template"}
          openTemplateDialog={show}
          performCancel={performCancel}
          width={'80vw'}
          >
          {custom ? (
          <NewTemplateCarosel custom={custom} initialData={initialData} top={"0px"}/>
        ) : (
          <CreateTemplateLib
          handleFullCustomisation={handleFullCustomisation}
          initialData={initialData}
          performCancel={performCancel}
        />
        )}
        </CustomDialogie>
   
    </div>
  );
};

export default EditTemplateLib;
