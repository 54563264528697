import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  // DialogTitle,
  Tooltip,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  Modal,
  Fade,
  useTheme, 
  MenuItem,
  Menu,
  InputBase
} from '@mui/material';
// import Tooltip from '@mui/material/Tooltip';
import syncIcon from 'src/assets/newTemplateIcons/sync.svg'
import uploadIcon from 'src/assets/downicon.png';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ShimmerLoader from 'src/utility/ShimmerLoader';
import styled from '@emotion/styled';
import trending from '../../../assets/TemplateIcons/myTemplates.svg';
import general from '../../../assets/TemplateIcons/general.svg';
import toprated from '../../../assets/TemplateIcons/topRated.svg';
import industry from '../../../assets/TemplateIcons/industry.svg';
import eCommerce from '../../../assets/TemplateIcons/eCommerce.svg';
import education from '../../../assets/TemplateIcons/education.svg';
import banking from '../../../assets/TemplateIcons/banking.svg';
import webinar from '../../../assets/TemplateIcons/webinar.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import { AnimatePresence, motion } from 'framer-motion';



import { useSelector } from 'react-redux';
import CommonCofirmation from '../Components/CommonConfirmation';
import { useNavigate } from 'react-router';

import SearchCustom from 'src/atom/button/search/SearchCustom';
import TemplateBox from './TemplateBox';
import { SignalWifiStatusbarNullRounded } from '@mui/icons-material';
import TemplateFilterSection from './TemplateFilterSection';
import Loading from 'src/utility/Loading';

const BodyWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  // margin-top: 5px;
  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: stretch; /* Optional: Adjust alignment for smaller screens */
  }
  @media screen and (min-width: 600px) and (max-width: 960px) {
    /* sm */
    flex-direction: column;
    align-items: stretch;
  }
  @media screen and (min-width: 960px) and (max-width: 1280px) {
    /* md */
    flex-direction: column;
  }
  @media screen and (min-width: 1280px) {
    flex-direction: column;
    align-items: stretch; /* Optional: Adjust alignment for smaller screens */
  }
`;

// const ListWrapper = styled(Box)`
//   display: flex;
//   width: 170px;
//   flex-direction: column;
//   @media screen and (max-width: 600px) {
//     flex-direction: column;
//     align-items: stretch; /* Optional: Adjust alignment for smaller screens */
//   }
// `;

const ListWrapper = styled(Box)`
  display: flex;
  width: 170px;

  @media screen and (max-width: 600px) {
    /* xs */
    flex-direction: row;
    width: auto;
    justify-content: center;
    mb: 20px;
  }

  @media screen and (min-width: 600px) and (max-width: 960px) {
    /* sm */
    flex-direction: row;
  }

  @media screen and (min-width: 960px) and (max-width: 1280px) {
    /* md */
    flex-direction: column;
  }

  @media screen and (min-width: 1280px) {
    /* lg */
    flex-direction: row;
  }
`;

const Lists = styled(Box)`
  width: 210px;
  margin-right: 10px;
  @media screen and (max-width: 600px) {
    /* xs */
    width: auto;
    justify-content: center;
  }
`;

// const SubList = styled(Box)`
//   width: 180px;
//   margin-right: 20px;
//   justifycontent: 'flex-end';
// `;
const SubList = styled(Box)`
  width: 180px;
  margin-left: auto; /* Move the sublist to the right side */
  justify-content: 'flex-end';
`;

const ListButtons = styled(Button)(
  ({ selected, width }) => `
  color: ${selected ? '#27AB69' : '#A3A3A3'};
  font-weight: 400;
  text-align: start;
  border-radius: 5px;
  width: ${width ? width : '170px'};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 45px;
  margin: 4px 0px;
  padding: 10px 10px;
  background-color: ${selected ? '#FFFFFF' : 'transparent'};
  &:hover {
    background-color: #F9F9F9;
    color: #27AB69;
    & img {
      filter: invert(59%) sepia(86%) saturate(4327%) hue-rotate(78deg) brightness(92%) contrast(89%);
    }
  }
  & img {
    filter: ${selected
      ? 'invert(59%) sepia(86%) saturate(4327%) hue-rotate(78deg) brightness(92%) contrast(89%)'
      : 'invert(50%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)'
    };
    width: 16px;
    height: 16px;
    margin-right: 5px;
    transition: filter 0.3s ease;
  }

  @media screen and (max-width: 600px) {
    justify-content: center; // Center the icon in xs screens
    max-width: 45px; // Use full width if needed
    & img {
      width: 18px;
      height: 18px;
    }
  }
`
);

const SubListButtons = styled(Button)(({ selected }) => ({
  color: selected ? '#27AB69' : '#A3A3A3',
  fontWeight: 400,
  textAlign: 'start',
  borderRadius: '5px',
  width: '150px',
  display: 'flex',
  alignItems: 'start',
  justifyContent: 'flex-start',
  height: '45px',
  margin: '5px 0px',
  padding: '10px 15px',
  backgroundColor: selected ? '#FFFFFF' : 'transparent',
  '&:hover': {
    backgroundColor: '#F9F9F9',
    color: '#27AB69',
    '& img': {
      filter:
        'invert(59%) sepia(86%) saturate(4327%) hue-rotate(78deg) brightness(92%) contrast(89%)'
    }
  },
  '& img': {
    filter: selected
      ? 'invert(59%) sepia(86%) saturate(4327%) hue-rotate(78deg) brightness(92%) contrast(89%)'
      : 'invert(50%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)',
    width: '24px',
    height: '24px',
    marginRight: '5px',
    transition: 'filter 0.3s ease' // Add transition for smoother effect
  }
}));

// added responsive
const ButtonWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 20px; // Reduce gap for smaller screens

  ${({ theme }) => theme.breakpoints.down('sm')} {
    justify-content: center; // Center buttons on small screens
    gap: 5px; // Even smaller gap on small screens
  }
`;

//  Added responsive


const TemplateContent = ({
  allTemplates,
  loading,
  approvedTemplates,
  pendingTemplates,
  rejectedTemplates,
  handleScroll,
  insideLib,
  hasNextPage,
  height,
  handleCreateTemplate,
  refetch

}) => {
  const [searchQuery, setSearchQuery] = useState('');

  const [selectedAllType, setSelectedAllType] = useState('myTemplates');
 
 


  const [category, setCategory] = useState('all');
  const [selectedExploreType, setSelectedExploreType] = useState('trending');
 

  const [selectedSublist, setSelectedSublist] = useState('eCommerce');
  const [isIndustryOpen, setIsIndustryOpen] = useState(false);

  const [deleted, setDeleted] = useState(false);
  const [selectedToIcon, setSelectedToIcon] = useState(null);

  //  For delete
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    open: false,
    templateName: '',
    loading: false
  });

  // To open dialog box for to view particu1ar template


 

  // To get a perticular template


  // To delete selected template

  //  For delete template


  // For open Dailogbox for broadcast


  const navigate = useNavigate();

  // Filter templates based on selectedAllType

  const filteredTemplates = useMemo(() => {
    // Start with all templates if selectedSection is 'all'

      let filtered = allTemplates;
  
      // Check if filtering by 'myTemplates'
      if (selectedAllType === 'myTemplates') {
       
      } else {
        // Filter templates based on selectedAllType
        filtered = filtered.filter((template) => {
          if (selectedAllType === 'approved') {
            return template.status === 'APPROVED';
          } else if (selectedAllType === 'pending') {
            return template.status === 'PENDING';
          } else if (selectedAllType === 'action') {
            return template.status === 'REJECTED' || template.status === 'PAUSED';
          }
          return true;
        });
      }
  
      // Add searchQuery filter to search by the 'name' key
      if (searchQuery) {
        filtered = filtered.filter((template) =>
          template?.template_name?.toLowerCase().includes(searchQuery.toLowerCase())
        );
      }

      if(category!=='all'){
        filtered = filtered.filter((template) => template?.category === category);
      }
  
      setDeleted(false);
      return filtered;
    
  
    // Default case: return allTemplates if selectedSection is neither 'all' nor 'explore'
    return allTemplates;
  }, [
    allTemplates,
    selectedAllType,
    selectedExploreType,
    selectedSublist,
    deleted,
    category,
    searchQuery // Add searchQuery to the dependencies
  ]);
  
  // const filteredTemplates =     allTemplates

  
 
  return (
    <>
      <Box display="flex" flexDirection="column">
        <BodyWrapper>
          {!insideLib&&

        <TemplateFilterSection 
        rejectedTemplates={rejectedTemplates}
        pendingTemplates={pendingTemplates}
        approvedTemplates={approvedTemplates}
        allTemplates={allTemplates}
        setSelectedAllType={setSelectedAllType}
        insideLib={insideLib}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        category={category}
        setCategory={setCategory}
        refetch={refetch}
        selectedAllType ={selectedAllType}
/>
      }
          {/* {selectedSection === 'explore' && (
            <ListWrapper>
              <Lists>
                <ListButtons
                  value="trending"
                  aria-label="trending"
                  onClick={() => setSelectedExploreType('trending')}
                  startIcon={<img src={trending} alt="trendingIcon" />}
                  selected={selectedExploreType === 'trending'}
                  style={{ fontSize: '13px' }}
                >
                  Trending
                </ListButtons>
              </Lists>
              <Lists>
                <ListButtons
                  value="general"
                  aria-label="general"
                  onClick={() => setSelectedExploreType('general')}
                  startIcon={<img src={general} alt="generalIcon" />}
                  selected={selectedExploreType === 'general'}
                  style={{ fontSize: '13px' }}
                >
                  General
                </ListButtons>
              </Lists>
              <Lists>
                <ListButtons
                  value="toprated"
                  aria-label="toprated"
                  onClick={() => setSelectedExploreType('toprated')}
                  startIcon={<img src={toprated} alt="topratedIcon" />}
                  selected={selectedExploreType === 'toprated'}
                  style={{ fontSize: '13px' }}
                >
                  Top Rated
                </ListButtons>
              </Lists>
              <Lists>
                <ListButtons
                  value="industry"
                  aria-label="industry"
                  onClick={() => {
                    setSelectedExploreType('industry');
                    setIsIndustryOpen(!isIndustryOpen); // Toggle open/close state
                  }}
                  startIcon={<img src={industry} alt="industryIcon" />}
                  selected={selectedExploreType === 'industry'}
                  style={{ fontSize: '13px' }}
                >
                  Industry
                
                  {isIndustryOpen ? (
                    <ExpandLessIcon style={{ marginLeft: 'auto' }} />
                  ) : (
                    <ExpandMoreIcon style={{ marginLeft: 'auto' }} />
                  )}
                </ListButtons>
                {selectedExploreType === 'industry' && isIndustryOpen && (
                  <>
                    <SubList>
                      <SubListButtons
                        value="sublist1"
                        aria-label="sublist1"
                        onClick={() => setSelectedSublist('eCommerce')}
                        startIcon={<img src={eCommerce} alt="eCommerceIcon" />}
                        selected={selectedSublist === 'eCommerce'}
                        style={{ fontSize: '13px' }}
                      >
                        ECommerce
                      </SubListButtons>
                    </SubList>
                    <SubList>
                      <SubListButtons
                        value="sublist2"
                        aria-label="sublist2"
                        onClick={() => setSelectedSublist('education')}
                        startIcon={<img src={education} alt="educationIcon" />}
                        selected={selectedSublist === 'education'}
                        style={{ fontSize: '13px' }}
                      >
                        Education
                      </SubListButtons>
                    </SubList>
                    <SubList>
                      <SubListButtons
                        value="sublist2"
                        aria-label="sublist2"
                        onClick={() => setSelectedSublist('banking')}
                        startIcon={<img src={banking} alt="bankingIcon" />}
                        selected={selectedSublist === 'banking'}
                        style={{ fontSize: '13px' }}
                      >
                        Banking
                      </SubListButtons>
                    </SubList>
                    <SubList>
                      <SubListButtons
                        value="sublist2"
                        aria-label="sublist2"
                        onClick={() => setSelectedSublist('webinar')}
                        startIcon={<img src={webinar} alt="webinarIcon" />}
                        selected={selectedSublist === 'webinar'}
                        style={{ fontSize: '13px' }}
                      >
                        Webinar
                      </SubListButtons>
                    </SubList>
                  </>
                )}
              </Lists>
            </ListWrapper>
          )} */}
          <AnimatePresence>
            <Grid
              container
              spacing={1}
              justifyContent="flex-start"
              alignItems="flex-start"
              onScroll={handleScroll}

              sx={{ height: height ? height : '73vh', overflowY: 'scroll' }}
            >
              {loading ? (
                // Render shimmer loaders while loading
                Array.from({ length: 8 }).map((_, index) => (
                  <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                    <ShimmerLoader height="250px" width="100%" />
                  </Grid>
                ))
              ) : filteredTemplates.length > 0 ? (
                   <TemplateBox handleCreateTemplate={handleCreateTemplate} filteredTemplates={filteredTemplates} deleted={deleted} setDeleted={setDeleted}  insideLib={insideLib}  setSelectedToIcon={setSelectedToIcon} selectedToIcon={selectedToIcon}/>
              ) : (
                <Grid
                  xs={12}
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <Typography
                    textAlign="center"
                    style={{
                      display: 'grid',
                      placeItems: 'center',
                      height: '50vh'
                    }}
                  >
                    No Templates are currently available.
                  </Typography>
                </Grid>
              )}
              {hasNextPage && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '10px',
                    width:"100%"
                  }}
                >
                  <Typography
                    variant="body2"
                    color="textSecondary"
                  >
                    <Loading />
                  </Typography>
                </Box>
              )}
            </Grid>
          </AnimatePresence>

        </BodyWrapper>

        {/* Dialog box starts here for view selected template */}
 

        {/* <Modal
          open={openTemplateDialog}
          onClose={handleTemplateDialogClose}
          aria-labelledby="full-screen-modal"
          aria-describedby="full-screen-modal-description"
          sx={{
            display: 'flex',
            alignItems: 'center',
            position: 'absolute',
            justifyContent: 'center',
            transition: 'opacity 0.3s ease-in-out'
          }}
        >
          <Fade in={openTemplateDialog} timeout={500}>
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 1)', // Optional: Semi-transparent background
                backdropFilter: 'blur(8px)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 999
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: '40px',
                  right: '25px',
                  display: 'flex',
                  gap: '20px',
                  color: 'lightgray'
                }}
              >
                <Box>
                  <img
                    src={Download}
                    onClick={'handleDownload'}
                    style={{ width: '25px', height: '25px', cursor: 'pointer' }}
                  />
                </Box>
                <Box onClick={'handleCloseZoom'}>
                  <img
                    src={Close}
                    style={{ width: '25px', height: '25px', cursor: 'pointer' }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  width: {
                    xs: '100%',
                    sm: '80%',
                    md: '60%',
                    lg: '60%'
                  },
                  height: { xs: '100%', sm: '80%', lg: '60%' },
                  display: 'flex',
                  justifyContent: 'center',
                  alignContent: 'center'
                }}
              >
                <TemplatePreview data={selectedViewTemplate} />
              </Box>
            </Box>
          </Fade>
        </Modal> */}

        {/* Delete Confirmation Dialog */}
        {/* <ConfirmDialog
          open={deleteConfirmation.open}
          onClose={() =>
            setDeleteConfirmation({
              open: false,
              onClose: false,
              templateName: '',
              loading: false
            })
          }
          onConfirm={handleConfirmDelete}
          loading={deleteConfirmation.loading}
          type="template"
        /> */}

        
      </Box>
  
    </>
  );
};

export default TemplateContent;
